import { Box, Button, Dialog, Stack, TextField, Toolbar, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { DataGrid, GridEnrichedColDef, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from "@mui/x-data-grid";
import { zhTWGrid } from "locale/dataGrid";
import { userRoleList } from "classes/user";
import { renderCellExpand } from "components/Tools/renderCellExpand";
import { endOfDay, format, isValid, startOfDay, addDays } from "date-fns";
import { useGetApiUserHistoryQuery } from "services/userApi";
import { LoginHistory } from "classes/user";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { zhTW } from "date-fns/locale";

export default function LoginHistoryPage() {
    //const today = startOfDay(new Date());
    const yesterday = startOfDay(addDays(new Date(), -1));
    const endOfToday = endOfDay(new Date());

    //搜尋欄相關變數
    const [startDate, setStartDate] = React.useState<Date | null>(yesterday);
    function handleStartDateChange(value: Date | null) {
        setStartDate(value);
    }
    const [endDate, setEndDate] = React.useState<Date | null>(endOfToday);
    function handleEndDateChange(value: Date | null) {
        setEndDate(value);
    }

    //搜尋結果用變數
    const [currentStartDate, setCurrentStartDate] = React.useState<Date>(yesterday);
    const [currentEndDate, setCurrentEndDate] = React.useState<Date>(endOfToday);

    //搜尋
    function handleSerachingLoginHistory() {
        setCurrentStartDate(startDate ?? yesterday);
        setCurrentEndDate(endDate ?? endOfToday);
    }
    //清空搜尋
    function handleClearSearch() {
        setStartDate(yesterday);
        setEndDate(endOfToday);
        setCurrentEndDate(endOfToday);
    }

    return (
        <Box>
            <LoginHistoryFilter
                startDate={startDate}
                onStartDateChange={handleStartDateChange}
                endDate={endDate}
                onEndDateChange={handleEndDateChange}
                onSearch={() => handleSerachingLoginHistory()}
                onClear={handleClearSearch}
            />
            <LoginHistoryTable
                startDate={currentStartDate}
                endDate={currentEndDate}
            />
        </Box>
    )
}

function LoginHistoryFilter(props: LoginHistoryFilterProps) {
    const { onSearch, onClear, startDate, onStartDateChange, endDate, onEndDateChange } = props;

    //開始時間
    function handleStartDateChange(value: Date | null) {
        onStartDateChange(value);
    };

    //結束時間
    function handleEndDateChange(value: Date | null) {
        onEndDateChange(value);
    };

    function handleSearch(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (startDate && endDate) onSearch();
    }

    function handleClearSearch() {
        onClear();
    }

    return (
        <Stack direction='row' spacing={2} height='fit-content' component="form" onSubmit={handleSearch} sx={{ marginBottom: '12px' }}>
            <Box display="inline-flex" alignItems='center' justifyContent='flex-start' flexWrap='wrap'>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
                    <DatePicker
                        value={startDate}
                        onChange={handleStartDateChange}
                        inputFormat="yyyy/MM/dd"
                        mask="____/__/__"
                        label='開始'
                        renderInput={({ InputProps, ...params }) =>
                            <TextField
                                color='secondary'
                                size='small'
                                InputProps={{ ...InputProps, sx: { width: '160px', marginRight: '12px' } }}
                                {...params}
                            />
                        }
                        maxDate={endDate ?? undefined}
                    />
                </LocalizationProvider>
                <Typography sx={{ marginRight: '12px' }}>
                    ~
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
                    <DatePicker
                        value={endDate}
                        onChange={handleEndDateChange}
                        inputFormat="yyyy/MM/dd"
                        mask="____/__/__"
                        label='結束'
                        renderInput={({ InputProps, ...params }) =>
                            <TextField
                                color='secondary'
                                size='small'
                                InputProps={{ ...InputProps, sx: { width: '160px', marginRight: '12px'} }}

                                {...params}
                            />
                        }
                        minDate={startDate ?? undefined}
                    />
                </LocalizationProvider>
            </Box>

            <Button
                variant='dark' type='submit' sx={{ width: '120px' }}
                disabled={!(isValid(startDate) && isValid(endDate))}
            >
                搜尋
            </Button>

            <Button variant='outlined' onClick={handleClearSearch} sx={{ width: '120px' }}>
                清除搜尋
            </Button>
        </Stack >
    );
}
interface LoginHistoryFilterProps {
    startDate: Date | null;
    onStartDateChange: (value: Date | null) => void;
    endDate: Date | null;
    onEndDateChange: (value: Date | null) => void;
    onSearch: () => void;
    onClear: () => void;
}

function LoginHistoryTable(props: LoginHistoryTableProps) {
    const { startDate, endDate } = props;

    const [pageSize, setPageSize] = React.useState<number>(10);

    const { dataList, isLoading } = useGetApiUserHistoryQuery({ startTime: startDate.toISOString(), endTime: endDate.toISOString() }, {
        selectFromResult: ({ data, ...other }) => ({ dataList: data?.data, ...other }),
        refetchOnMountOrArgChange: true
    });

    const columns: GridEnrichedColDef<GridDataRow>[] = [
        {
            field: 'userId',
            headerName: 'ID',
            width: 140,
            renderCell: renderCellExpand,
        },
        {
            field: 'account',
            headerName: '帳號',
            width: 140,
            renderCell: renderCellExpand,
        },
        {
            field: 'ipAdress',
            headerName: '登入IP',
            width: 140,
            renderCell: renderCellExpand,
        },
        {
            field: 'addedAtUtc',
            headerName: '建立時間',
            type: 'dateTime',
            width: 140,
            valueGetter: ({ value }: GridValueGetterParams<string>) => format(new Date(value as string), "yyyy/MM/dd HH:mm"),
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }
    
    return (
        <Box>
            <DataGrid
                rows={dataList as LoginHistory[] ?? []}
                columns={columns}
                getRowId={(row) => `${row.userId}: ${row.addedAtUtc}`}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 50, 100]}
                autoHeight
                disableColumnMenu
                disableColumnSelector
                components={{
                    Toolbar: CustomToolbar,
                }}
                localeText={zhTWGrid}
                loading={isLoading}
            />
        </Box>
    )
}
interface LoginHistoryTableProps {
    startDate: Date;
    endDate: Date;
}
interface GridDataRow extends LoginHistory {

}