import { Box, Button, ButtonProps, IconButton, styled, useTheme } from "@mui/material";
import * as React from "react";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

export const NoOutlineButton = styled(Button)({
    '&:focus': {
        outline: 'none',
    }
});

export const BorderButton = styled(Button)(({ theme }) => ({
    height: '44px',
    minWidth: '110px',
    padding: '8px 15px',
    color: "black",
    fontSize: "1.125rem",
    textTransform: 'capitalize',
    lineHeight: '1.5',
    borderRadius: 0,
    backgroundColor: 'white',
    boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.25)',
    justifyContent: 'start',
    flex: '0 1 auto',
    [theme.breakpoints.up("sm")]: {
        height: '48px',
        minWidth: '120px',
        padding: '11px 17px',
    },
    '&:focus': {
        outline: 'none',
    },
    '&:hover': {
        backgroundColor: 'white',
        boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 2px 3px rgba(0, 0, 0, 0.16)',
    },
    '&.Mui-disabled	': {
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
        color: '#9A9A9A',
    },
    '&.active': {
        fontWeight: "bold",
    },
    '& svg': {
        fontSize: "27px",
        marginRight: "10px",
        height: "27px"
    }
}));

export const FilledButton = styled(Button)({
    minWidth: '80px',
    padding: '8px 15px',
    fontSize: "1.125rem",
    textTransform: 'capitalize',
    lineHeight: '1.5',
    backgroundColor: '#5E5E5E',
    color: 'white',
    boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.25)',
    '&:focus': {
        outline: 'none',
    },
    '&:hover': {
        backgroundColor: '#6c757d',
        boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 2px 3px rgba(0, 0, 0, 0.16)',
    },
    '&.Mui-disabled	': {
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
        color: '#9A9A9A',
    }
});

export const ConfirmButton = (props: LoadingButtonProps) => {
    const { children, ...other } = props;
    return (<LoadingButton size="large" color="primary" variant="contained" {...other}>{children}</LoadingButton>);
}

export const CancelButton = (props: LoadingButtonProps) => {
    const { children, ...other } = props;
    return (<LoadingButton size="large" color="secondary" variant="contained" {...other}>{children}</LoadingButton>);
}

export function PaginationActionButton(props: PaginationActionButtonProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange, direction } = props;

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    return (
        <Box sx={{ flexShrink: 0 }}>
            {direction == 'left' &&
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
            }
            {direction == 'right' &&
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
            }
        </Box>
    );
}
interface PaginationActionButtonProps {
    direction: 'left' | 'right';
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}