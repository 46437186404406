import { Backdrop, Button, CircularProgress, Dialog, DialogActions } from "@mui/material";
import * as React from "react";
import { Tidbit } from "classes/tidbit";
import { useDeleteApiTidbitMutation } from "services/tidbitApi";

export interface TidbitDeleteDialogProps {
    open: boolean;
    data: Tidbit;
    onClose?: () => void;
    onDelete?: () => void;
}
export default function TidbitDeleteDialog(props: TidbitDeleteDialogProps) {
    const { open, data, onClose, onDelete } = props;

    const [deleteTidbit, { isLoading }] = useDeleteApiTidbitMutation();
    async function handleDelete() {
        try {
            let result = await deleteTidbit({ updateTidbitModel: data }).unwrap();
            if (!result.isSuccess)
                throw new Error("刪除資料失敗");
            if (onDelete) onDelete();
        }
        catch (e) {
            console.error(e);
        }
    }
    return (<>
        <Dialog open={open} onClose={onClose}>
            <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                <span style={{ fontWeight: 'bold' }}>
                    刪除 {data?.title}
                </span>
            </div>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button autoFocus onClick={handleDelete}>是</Button>
                <Button onClick={onClose}>否</Button>
            </DialogActions>
        </Dialog>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </>)
}