import { Clear, Search } from "@mui/icons-material";
import { SxProps } from '@mui/system';
import { IconButton, Input, InputProps, MenuItem, MenuList, Paper, Popover, Popper, styled, Theme } from "@mui/material";
import * as React from "react";

const SearchBarPaper = styled(Paper)(({ theme }) => ({
    position: "relative",
    //height: theme.spacing(6),
    display: "flex",
    justifyContent: "space-between",
    "& .searchContainer": {
        margin: "auto 16px",
        width: "100%",
    },
    "& .MuiIconButton-root": {
        color: theme.palette.action.active,
        transform: "scale(1, 1)",
        transition: theme.transitions.create(["transform", "color"], {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeInOut,
        }),
        "&.MuiIconButton-sizeMedium": {
            padding: "12px",
        },
        "&.MuiIconButton-sizeSmall": {
            padding: "8px",
        },
        "&.search": {
            position: "absolute",
            right: 0
            //marginRight: theme.spacing(-6),
        },
        "&.hidden": {
            transform: "scale(0, 0)",
            "& > svg": {
                opacity: 0,
            },
        }
    },
    "& > svg": {
        transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    "& .input": {
        width: "100%",
    },
}));

export const SearchBar = React.forwardRef<SearchBarRef, SearchBarProps>((props, ref) => {
    const { cancelOnEscape, className, closeIcon: closeIconProp, dataSource, disabled, onCancelSearch, onRequestSearch, searchIcon: searchIconProp, style, sx, ...inputProps } = props;
    const closeIcon = closeIconProp ?? <Clear />;
    const searchIcon = searchIconProp ?? <Search />;

    const inputRef = React.useRef<HTMLInputElement>();
    const [value, setValue] = React.useState<string>(inputProps.value as string ?? "");

    React.useEffect(() => {
        setValue(inputProps.value as string ?? "");
    }, [inputProps.value]);

    const handleFocus = React.useCallback(
        (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            if (inputProps.onFocus) {
                inputProps.onFocus(e);
            }
        },
        [inputProps.onFocus]
    );

    const handleBlur = React.useCallback(
        (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setValue((v) => v.trim());
            if (inputProps.onBlur) {
                inputProps.onBlur(e);
            }
            handleClose();
        },
        [inputProps.onBlur]
    );

    const handleInput = React.useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setValue(e.target.value);
            if (inputProps.onChange) {
                inputProps.onChange(e);
            }
        },
        [inputProps.onChange]
    );

    const handleCancel = React.useCallback(() => {
        setValue("");
        if (onCancelSearch) {
            onCancelSearch();
        }
    }, [onCancelSearch]);

    const handleRequestSearch = React.useCallback(() => {
        if (onRequestSearch) {
            onRequestSearch(value);
        }
    }, [onRequestSearch, value]);

    const handleKeyUp = React.useCallback(
        (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            if (e.charCode === 13 || e.key === "Enter") {
                handleRequestSearch();
            } else if (
                cancelOnEscape &&
                (e.charCode === 27 || e.key === "Escape")
            ) {
                handleCancel();
            }
            if (inputProps.onKeyUp) {
                inputProps.onKeyUp(e);
            }
        },
        [handleRequestSearch, cancelOnEscape, handleCancel, inputProps.onKeyUp]
    );

    React.useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current?.focus();
        },
        blur: () => {
            inputRef.current?.blur();
        },
    }));

    //AutoComplete
    const listboxRef = React.useRef<HTMLUListElement>(null);
    const [popupOpen, setPopupOpen] = React.useState<boolean>(false);
    const handleClose = () => setPopupOpen(false);

    const Paper = SearchBarPaper;
    return (
        <Paper className={className} style={style} sx={sx}>
            <div className="searchContainer">
                <Input
                    {...inputProps}
                    inputRef={inputRef}
                    onBlur={handleBlur}
                    value={value}
                    onChange={handleInput}
                    onKeyUp={handleKeyUp}
                    onFocus={handleFocus}
                    fullWidth
                    disableUnderline
                    disabled={disabled}
                />
                {popupOpen && inputRef.current &&
                    <Popper
                        anchorEl={inputRef.current}
                        disablePortal
                        open
                    >
                        <Paper>
                            <MenuList ref={listboxRef} onKeyDown={() => { }/*handleListKeyDown*/}>
                                {dataSource?.map((item) => (
                                    <MenuItem onClick={() => { }/*handleClose*/}>{item}</MenuItem>
                                ))}
                            </MenuList>
                        </Paper>
                    </Popper>
                }
            </div>
            <IconButton
                onClick={handleRequestSearch}
                className={value !== "" ? "search hidden" : "search"}
                disabled={disabled}
                size={inputProps.size}
            >
                {searchIcon}
            </IconButton>
            <IconButton
                onClick={handleCancel}
                className={value === "" ? "hidden" : ""}
                disabled={disabled}
                size={inputProps.size}
            >
                {closeIcon}
            </IconButton>
        </Paper>
    );
});

export interface SearchBarProps extends InputProps {
    cancelOnEscape?: boolean;
    className?: string;
    closeIcon?: React.ReactElement;
    dataSource?: string[];
    disabled?: boolean;
    onCancelSearch?: () => void;
    onRequestSearch?: (value: string) => void;
    searchIcon?: React.ReactElement;
    style?: React.CSSProperties;
    sx?: SxProps<Theme>;
}

export interface SearchBarRef {
    focus: () => void;
    blur: () => void;
}