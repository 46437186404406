import { Button, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import { zhTWGrid } from 'locale/dataGrid';
import { useDeleteUploadImageEditorMutation, useGetUploadImageEditorQuery } from 'services/uploadApi';

// 選擇圖片並回傳到 CKEditor
function getUrlParam(paramName: string) {
    var reParam = new RegExp('(?:[\?&]|&)' + paramName + '=([^&]+)', 'i');
    var match = window.location.search.match(reParam);

    return (match && match.length > 1) ? match[1] : null;
}
function selectImage(url: string) {
    var funcNum = getUrlParam('CKEditorFuncNum');
    console.log("upload/image/" + url)
    window.opener.CKEDITOR.tools.callFunction(funcNum, "upload/image/" + url);
    window.close();
}

export default function ImagesManager() {
    const location = useLocation();
    console.log(location)
    const { data, isLoading, isFetching, refetch } = useGetUploadImageEditorQuery();
    return (<>
        圖片管理{
            <ImgDataGrid dataList={data?.data?.map((x, index) =>
                ({ id: index, url: x })) ?? []} isLoading={isLoading || isFetching} refetch={refetch} />
        }

    </>)
}
function ImgDataGrid(props: { dataList: { url: string }[], isLoading: boolean, refetch: () => void }) {
    const { dataList, isLoading, refetch } = props;
    const [deleteCKeditorImag, { isLoading: isDeleteBoothCKeditorImagLoading }] = useDeleteUploadImageEditorMutation();
    function deleteImg(key: string) {
        deleteCKeditorImag({ deleteModel: { fileKey: key } }).unwrap().then(res => { if (res.isSuccess) refetch() })
    }
    const columns: GridColDef[] = [
        {
            field: 'url',
            headerName: 'manage.title.url',
            flex: 1,
            minWidth: 150,
            maxWidth: 250,
            renderCell: (params) => <img src={"/upload/image/" + params.value} style={{ width: "100%", height: "100%", objectFit: "contain" }} />,
        },
        {
            field: 'action',
            headerName: 'manage.title.action',
            flex: 1,
            minWidth: 180,
            maxWidth: 250,
            renderCell: ({ row }) => <Stack >
                <Button onClick={() => {
                    selectImage(row["url"])
                }}>選擇圖片</Button>
                <Button onClick={() => {
                    deleteImg(row["url"])
                }}>刪除圖片</Button>
            </Stack>,
        }
    ];
    return <>
        <DataGrid rows={dataList ?? []} columns={columns}
            loading={isLoading || isDeleteBoothCKeditorImagLoading}
            autoHeight
            rowHeight={250}
            initialState={{ pagination: { page: 0, pageSize: 10 } }}
            getRowId={(data) => data.id}
            disableColumnMenu
            disableColumnSelector
            components={{
                //    Toolbar: CustomToolbar,
            }}
            localeText={zhTWGrid}
        />
    </>
}