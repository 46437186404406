import { Close } from "@mui/icons-material";
import { Box, Button, ButtonBase, Card, CardActionArea, CardContent, CardMedia, Divider, IconButton, Paper, Skeleton, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import format from "date-fns/format";
import * as React from "react";
import { Tidbit } from "classes/tidbit";

export default function TidbitCard(props: TidbitCardProps) {
    const { data } = props;

    const theme = useTheme();

    function handleOpenLink(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        if (data?.url) window.open(data.url, '_blank');
    }

    return (
        <Card
            sx={{
                "&.MuiCard-root": {
                    border: "3px solid " + theme.palette.grey[800],
                    transition: ".3s",
                    '&:hover': { borderColor: '#3fa9f5' }
                    //borderRadius: "12px",
                },
                margin: "0 auto",
                width: '100%',
                maxWidth: '420px',
                height: 'fit-content',
                overflow: "unset",
                position: "relative",
                boxShadow: "4px 4px " + theme.palette.grey[300],
                background: "#FFF8E3"
            }}
        >
            <CardActionArea
                sx={{ padding: '12px', height: '100%' }}
                disabled={!data?.url}
                href={data?.url}
                onClick={handleOpenLink}
            >
                <Box>
                    {data ?
                        <Typography color="grey.400" textAlign={"right"} >{format(new Date(data.createTime), "yyyy/MM/dd")}</Typography> :
                        <Skeleton variant="text" width='100px' sx={{ fontSize: '1rem' }} />
                    }
                </Box>
                {data ?
                    <Box

                        position={"relative"}
                        sx={{
                            aspectRatio: "258/133",
                            '@supports not (aspect-ratio: 1/1)': {
                                paddingTop: "calc(133 / 258 * 100%)"
                            },
                        }}
                    >
                        <CardMedia
                            component="img"
                            width={"100%"}
                            height={"100%"}
                            sx={{
                                position: "absolute",
                                top: "0"

                            }}
                            image={data.imageUrl}
                        />
                    </Box>
                    :
                    <Skeleton variant="rectangular" height={225} />
                }
                <CardContent sx={{ pl: 0, pr: 0, py: 1 }}>
                    {data ?
                        <Typography color='background.black' gutterBottom variant="h6" component="div"
                            overflow='hidden' textOverflow='ellipsis' height='64px'
                            sx={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: '2'
                            }}>
                            {data.title}
                        </Typography> :
                        <Skeleton variant="text" sx={{ fontSize: '1.25rem', marginBottom: '0.35en' }} />
                    }

                </CardContent>
            </CardActionArea>
        </Card >
    )
}
interface TidbitCardProps {
    data: Tidbit;
}