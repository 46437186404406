import { CircularProgress, createTheme, CssBaseline, responsiveFontSizes, ThemeProvider, useMediaQuery } from '@mui/material';
import React from 'react';
import { RouterProvider, useLocation } from 'react-router-dom';
import { routerConfig } from 'routerConfig';
import mainTheme from './theme';

export default function App() {

    return (
        <ThemeProvider theme={mainTheme}>
            <CssBaseline />
            <RouterProvider router={routerConfig} fallbackElement={<Loader />} />
        </ThemeProvider>
    );
}

export const Loader = () => (
    <div>
        <CircularProgress />
    </div>
);