import { Add, Delete, Edit, Preview, Restore } from "@mui/icons-material";
import { Box, Button, Paper, Stack, styled, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridEnrichedColDef, GridRenderCellParams, GridRowParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from "@mui/x-data-grid";
import format from "date-fns/format";
import * as React from "react";
import { SubTitle } from "components/responsive-components";
import { zhTWGrid } from "locale/dataGrid";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { EventEditDialog, EventEditDialogProps } from "./EventEditDialog";
import { useGetApiEventQuery } from "services/eventApi";
import { OLEvent, EventType } from "classes/event";
import EventInfoPage from "./EventInfoPage";
import EventDeleteDialog from "./EventDeleteDialog";
import EventRestoreDialog from "./EventRestoreDialog";
import EventsDialog from "components/EventsDialog";
import { useUser } from "store";

interface EventManagePageProps {
    eventType: EventType;
}
export default function EventManagePage(props: EventManagePageProps) {
    const { eventType } = props;
    const user = useUser();

    //獲取活動場次資料
    const { data: eventList, isLoading, refetch } = useGetApiEventQuery({ type: eventType }, { refetchOnMountOrArgChange: true });

    //開啟活動檢視頁
    const [open, setOpen] = React.useState<boolean>(false);
    const [currentInfo, setCurrentInfo] = React.useState<OLEvent>();
    function handleOpenPage(info: OLEvent) {
        setCurrentInfo(info);
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };

    //假資料
    const fakeImg: string = "./images/展覽.jpg";
    const fakeEvent: OLEvent = { id: "", title: "活動場次名稱", imageUrl: fakeImg, content: "", createTime: "", updateTime: "" };
    const randomNewest = () => {
        let copy = { ...fakeEvent };
        copy.id = "Event-fake01";
        copy.content = "<p>活動時間：2022/13/32</p><p>報名時間：2022/1/1~2022/12/25</p><p>要發認為的道常，我沒有你啊該會，破的反。本來就以前，那張所以才的照片天早也是，的肉還沒種天氣覺得要，格覺得還今，歌的天所有喜歡的表情您在其他，一兩早要注意。放聲音次也話可。的時己的心傷上去本來就心中，什到下家好也可能才會實沒有，聽到不是這的眼，米所以的過確定人看不懂，就是要，多味道安推薦讓你我自己？</p><a href=\"#\">超連結</a>";
        copy.createTime = new Date().toISOString();
        copy.updateTime = new Date().toISOString();
        return copy;
    }
    const randomOldest = () => {
        let copy = { ...fakeEvent };
        copy.id = "Event-fake02";
        copy.content = "<p>內容內容內容內容內容內容<br/>內容內容內容內容內容內容內容內容內容內容內容內容<br/>內容內容內容內容內容內容內容內容內容內容內容內容<br/>內容內容內容內容內容內容內容內容內容<br/>內容內容內容內容內容內容內容內容內容</p><a href=\"#\">超連結</a>";
        copy.createTime = new Date(1990, 1, 1).toISOString();
        copy.updateTime = new Date(1990, 1, 1).toISOString();
        return copy;
    }

    //控制編輯&新增彈框
    const [openEdit, setOpenEdit] = React.useState<boolean>(false);
    const [openInsert, setOpenInsert] = React.useState<boolean>(false);
    const [editInfo, setEditInfo] = React.useState<OLEvent>();
    function handleOpenEdit(info: OLEvent) {
        setEditInfo(info);
        setOpenEdit(true);
    }
    function handleOpenInsert() {
        setOpenInsert(true);
    }

    const handleSave = () => {
        setOpenEdit(false);
        setOpenInsert(false);
        refetch();
    };

    //控制刪除&還原彈框
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [openRestore, setOpenRestore] = React.useState<boolean>(false);
    const [targetInfo, setTargetInfo] = React.useState<OLEvent>();
    const [deletedInfo, setDeletedInfo] = React.useState<OLEvent>();
    function handleOpenDelete(info: OLEvent) {
        setTargetInfo(info);
        setOpenDelete(true);
    };
    const handleDelete = () => {
        setOpenDelete(false);
        let info = targetInfo;
        setDeletedInfo(info);
        setTargetInfo(undefined);
        refetch();
    };
    const handleRestore = () => {
        setOpenRestore(false);
        setDeletedInfo(undefined);
        refetch();
    };
    enum EventTypeTitle {
        "「開箱實驗室」活動場次列表" = 0,
        //"「跨域分享會」活動場次列表" = 1,
        "「專場導覽」活動場次列表" = 2
    }
    if (!user?.userId) return null;
    return (<>
        <SubTitle
            icon='circle'
            text={EventTypeTitle[eventType]}
        />
        <Stack direction="row" spacing={1} mb={2} >
            <Button color="secondary" onClick={handleOpenInsert} startIcon={<Add />}>新增場次</Button>
            {deletedInfo &&
                <Button color="secondary" onClick={() => setOpenRestore(true)} startIcon={<Restore />}>
                    復原
                </Button>
            }
        </Stack>
        <Paper variant="outlined">
            <EventList
                dataList={(eventList?.data as OLEvent[]) ?? []}
                loading={isLoading}
                handleOpenPage={handleOpenPage}
                handleOpenEdit={handleOpenEdit}
                handleOpenDelete={handleOpenDelete}
            />
        </Paper>
        <EventsDialog open={open} type={eventType} events={currentInfo ? [randomNewest(), currentInfo, randomOldest()] : []} onClose={handleClose} />
        <EditDialog open={openEdit} type={eventType} initData={editInfo} onClose={() => setOpenEdit(false)} onSave={handleSave} />
        <InsertDialog open={openInsert} type={eventType} onClose={() => setOpenInsert(false)} onSave={handleSave} />
        {targetInfo &&
            <DeleteDialog open={openDelete} type={eventType} data={targetInfo} onClose={() => { setOpenDelete(false); setTargetInfo(undefined); }} onDelete={handleDelete} />
        }
        {deletedInfo &&
            <RestoreDialog open={openRestore} type={eventType} data={deletedInfo} onClose={() => { setOpenRestore(false); }} onRestore={handleRestore} />
        }
    </>)
}
const EditDialog = styled(EventEditDialog)({});
const InsertDialog = styled(EventEditDialog)({});
const DeleteDialog = styled(EventDeleteDialog)({});
const RestoreDialog = styled(EventRestoreDialog)({});

interface GridDataRow extends OLEvent { }
interface EventListProps {
    dataList: GridDataRow[];
    loading?: boolean;
    handleOpenPage: (info: OLEvent) => void;
    handleOpenEdit: (info: OLEvent) => void;
    handleOpenDelete: (info: OLEvent) => void;
}
function EventList(props: EventListProps) {
    const { dataList, loading, handleOpenPage, handleOpenEdit, handleOpenDelete } = props;

    const columns: GridEnrichedColDef<GridDataRow>[] = [
        {
            field: 'id',
            headerName: '#',
            filterable: false,
            width: 70,
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
        },
        {
            field: 'imageUrl',
            headerName: '圖片',
            width: 95,
            renderCell: ({ value }: GridRenderCellParams<string>) => {
                if (value && value != "")
                    return <img width="72.5px" height="50px" src={value} style={{ objectFit: "cover" }} />
                else
                    return "無"
            },
        },
        {
            field: 'title',
            headerName: '標題',
            flex: 1,
            minWidth: 150,
            //valueGetter: ({ row: { chName } }: GridValueGetterParams<string, GridDataRow>) => chName,
        },
        {
            field: 'createTime',
            headerName: '新增時間',
            type: 'dateTime',
            width: 180,
            valueGetter: ({ value }: GridValueGetterParams<string>) => value ? format(new Date(value), "yyyy/MM/dd HH:mm:ss") : "",
        },
        {
            field: 'actions',
            type: 'actions',
            width: 120,
            getActions: ({ row }: GridRowParams<GridDataRow>) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title={"預覽"}>
                            <Preview />
                        </Tooltip>
                    }
                    onClick={() => handleOpenPage(row)}
                    label={"預覽"}
                />,
                <GridActionsCellItem
                    icon={
                        <Tooltip title={"編輯"}>
                            <Edit />
                        </Tooltip>
                    }
                    onClick={() => handleOpenEdit(row)}
                    label={"編輯"}
                />,
                <GridActionsCellItem
                    icon={<Delete color="error" />}
                    onClick={() => handleOpenDelete(row)}
                    label={"刪除"}
                    sx={{
                        color: theme => theme.palette.error.main
                    }}
                    showInMenu
                />,
            ]
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <DataGrid rows={dataList} columns={columns}
            pageSize={10}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={zhTWGrid}
            loading={loading}
        />
    )
}