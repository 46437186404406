import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUploadImageEditorByKey: build.query<
      GetUploadImageEditorByKeyApiResponse,
      GetUploadImageEditorByKeyApiArg
    >({
      query: (queryArg) => ({ url: `/upload/image/editor/${queryArg.key}` }),
    }),
    getUploadImageEditor: build.query<
      GetUploadImageEditorApiResponse,
      GetUploadImageEditorApiArg
    >({
      query: () => ({ url: `/upload/image/editor` }),
    }),
    postUploadImageEditor: build.mutation<
      PostUploadImageEditorApiResponse,
      PostUploadImageEditorApiArg
    >({
      query: (queryArg) => ({
        url: `/upload/image/editor`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    deleteUploadImageEditor: build.mutation<
      DeleteUploadImageEditorApiResponse,
      DeleteUploadImageEditorApiArg
    >({
      query: (queryArg) => ({
        url: `/upload/image/editor`,
        method: "DELETE",
        body: queryArg.deleteModel,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetUploadImageEditorByKeyApiResponse = unknown;
export type GetUploadImageEditorByKeyApiArg = {
  key: string;
};
export type GetUploadImageEditorApiResponse =
  /** status 200 Success */ StringListResultModel;
export type GetUploadImageEditorApiArg = void;
export type PostUploadImageEditorApiResponse =
  /** status 200 Success */ UploadImage;
export type PostUploadImageEditorApiArg = {
  body: {
    upload?: Blob;
    ckCsrfToken?: string;
  };
};
export type DeleteUploadImageEditorApiResponse =
  /** status 200 Success */ DeleteObjectResponseResultModel;
export type DeleteUploadImageEditorApiArg = {
  deleteModel: DeleteModel;
};
export type StringListResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: string[] | null;
};
export type UploadImage = {
  uploaded?: number;
  fileName?: string | null;
  url?: string | null;
};
export type CoreChecksumAlgorithm = 0 | 1 | 2 | 3 | 4;
export type ChecksumValidationStatus = 0 | 1 | 2 | 3;
export type ResponseMetadata = {
  requestId?: string | null;
  metadata?: {
    [key: string]: string;
  } | null;
  checksumAlgorithm?: CoreChecksumAlgorithm;
  checksumValidationStatus?: ChecksumValidationStatus;
};
export type HttpStatusCode =
  | 100
  | 101
  | 102
  | 103
  | 200
  | 201
  | 202
  | 203
  | 204
  | 205
  | 206
  | 207
  | 208
  | 226
  | 300
  | 301
  | 302
  | 303
  | 304
  | 305
  | 306
  | 307
  | 308
  | 400
  | 401
  | 402
  | 403
  | 404
  | 405
  | 406
  | 407
  | 408
  | 409
  | 410
  | 411
  | 412
  | 413
  | 414
  | 415
  | 416
  | 417
  | 421
  | 422
  | 423
  | 424
  | 426
  | 428
  | 429
  | 431
  | 451
  | 500
  | 501
  | 502
  | 503
  | 504
  | 505
  | 506
  | 507
  | 508
  | 510
  | 511;
export type RequestCharged = {
  value?: string | null;
};
export type DeleteObjectResponse = {
  responseMetadata?: ResponseMetadata;
  contentLength?: number;
  httpStatusCode?: HttpStatusCode;
  deleteMarker?: string | null;
  versionId?: string | null;
  requestCharged?: RequestCharged;
};
export type DeleteObjectResponseResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: DeleteObjectResponse;
};
export type DeleteModel = {
  fileKey?: string | null;
};
export const {
  useGetUploadImageEditorByKeyQuery,
  useLazyGetUploadImageEditorByKeyQuery,
  useGetUploadImageEditorQuery,
  useLazyGetUploadImageEditorQuery,
  usePostUploadImageEditorMutation,
  useDeleteUploadImageEditorMutation,
} = injectedRtkApi;
