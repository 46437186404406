import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { EditorSection } from "components/Home/EditorSection";
import * as React from "react";
import { useInView } from "react-intersection-observer";

const gear = "./images/齒輪.png";

export default function AboutSection() {
    //嵌入影片顯示
    const url = "https://www.youtube.com/embed/QKHgz73rhv0";
    const [width, setWidth] = React.useState(261);
    const [height, setHeight] = React.useState(250);
    const videoContainerRef = React.useRef();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    React.useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            setWidth(event[0].contentBoxSize[0].inlineSize);
            setHeight(event[0].contentBoxSize[0].blockSize);
        });

        if (videoContainerRef?.current) {
            resizeObserver.observe(videoContainerRef.current);
        }
    }, [videoContainerRef]);

    //側邊Title文字控制
    const { ref, inView } = useInView({
        threshold: 0.6
    });
    return (<>
        <Box
            boxShadow={"0px 0px 18px 16px rgba(255, 255, 255, 0.85)"}
            bgcolor={"var(--85-white, rgba(255, 255, 255, 0.85))"}
        >

            <Box
                m={"0 auto"}
                borderRadius={"5px"}
                width="100%"
                px={{ xs: "45px", md: "90px" }}
                pt={{ xs: "30px", md: "60px" }}

                alignItems="center"
                gap="40px"
                maxWidth="960px"

            // mb="70px"

            >
                <Box display={"flex"} justifyContent={isMobile ? "center" : "space-between"} width={"100%"} flexWrap={"wrap"}>
                    <Box
                        order={isMobile ? 1 : 0}
                        color="#fff"
                        sx={{
                            "width": "260px",
                            "padding": "32px 30px",
                            "flexDirection": "column",
                            "alignItems": "flex-start",
                            "gap": "45px",
                            "borderRadius": "3px",
                            "background": "var(--85-black, rgba(0, 0, 0, 0.85))",
                        }}>
                        <Box
                            display={"flex"}
                            flex="auto 1"
                            width="100%"
                            alignItems={"center"}
                            gap="10px"
                            mb={1}
                        >
                            <Box width={"max-content"}>展覽日期</Box>
                            <Box
                                borderBottom={"var(--Divider, rgba(192, 192, 192, 0.50)) 2px solid"}
                                flexGrow={"1"}
                                height={"2px"}
                            ></Box>
                        </Box>
                        <Box >
                            <Box>2024</Box>
                            <Typography variant="h4" lineHeight={"1.1"}>1.11－1.15</Typography>

                            <Box>(1.13選舉日休館一日)</Box>
                        </Box>
                        <Box
                            display={"flex"}
                            flex="auto 1"
                            width="100%"
                            alignItems={"center"}
                            gap={1}
                            mt="30px"
                            mb={1}
                        >
                            <Box width={"max-content"}>展覽地點</Box>
                            <Box
                                borderBottom={"var(--Divider, rgba(192, 192, 192, 0.50)) 2px solid"}
                                flexGrow={"1"}
                                height={"2px"}
                            ></Box>
                        </Box>
                        <Typography color="var(--Primary, #FD8489)" fontWeight={"700"} sx={{ leadingTrim: "both" }} fontFamily={"Noto Sans TC"}>浩然圖書館大廳</Typography>
                    </Box>
                    <Box>
                        <img src="./images/未來肉身.svg" style={{ width: "100%" }} />
                    </Box>
                </Box>
            </Box >
            <Box
                padding={{ xs: "40px 45px 80px", md: "40px calc(90px + 1rem) 80px" }}
            >
                <EditorSection name="ExhibitIntro" />
            </Box>
        </Box>
    </>)
}