import { Box, Button, styled, Typography } from "@mui/material";
import * as React from "react";
import EventManagePage from "./EventManagePage";
import { EventType } from "classes/event";

interface EventsIndexProps {
    eventType?: EventType;
}
export default function EventsIndex(props: EventsIndexProps) {
    const { eventType } = props;
    const [code, setCode] = React.useState<number>(eventType ?? -1);
    React.useEffect(() => {
        setCode(eventType ?? -1);
    }, [eventType])

    const buttonList = [
        { nameKey: "manage.title.events.openLabs", code: EventType.openlabs },
        //  { nameKey: "manage.title.events.interdisciplinaryTalk", code: EventType.talk },
        { nameKey: "manage.title.events.seminars", code: EventType.seminar }
    ];
    function handleClick(code: number) {
        setCode(code);
    }

    if (code == EventType.openlabs/* || code == EventType.talk*/ || code == EventType.seminar)
        return (
            <EventManagePage eventType={code} />
        )
    else return (<>
        <Box display="flex" flexWrap='wrap'>
            {buttonList.map((item) => (
                <BigButton key={item.nameKey} onClick={() => handleClick(item.code)}>
                    <Typography>{item.nameKey}</Typography>
                </BigButton>))}
        </Box>
    </>)
}

const BigButton = styled(Button)(({ theme }) => ({

}));