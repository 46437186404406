import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TitleSection from "./TitleSection";
import AboutSection from "./AboutSection";
import EventsSection from "./EventsSection";
import ExhibitsSection from "./ExhibitsSection";
import PressSection from "./PressSection";
import IntroductionVideoSection from "./IntroductionVideoSection";
import TeamSection from "./TeamSection";

const BG = "./images/主視覺圖層2.png";
const BG_mobile = "./images/直式圖層2.png";
const BG2 = "./images/背景底圖.png";
const BG2_mobile = "./images/直式底圖.png";

export default function Home() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    //跳轉
    const handleClickLink = (link: string) => {
        navigate(link);
    }

    return (<>
        <Box sx={{
            backgroundImage: isMobile ? `url('${BG_mobile}'),url('${BG2_mobile}')` : `url('${BG}'),url('${BG2}')`,
            backgroundRepeatX: "no-repeat",
            backgroundSize: { xs: "100%,contain", md: "100%,100%", xl: "100%,100%" },
            backgroundPosition: { xl: "0 0,top center" },
            "@supports (-webkit-touch-callout : none)": {
                backgroundAttachment: "scroll",
                backgroundSize: "auto"
            },
            "@supports not (-webkit-touch-callout : none)": {
                backgroundAttachment: "fixed"
            }
        }}>
            <TitleSection />
            <Box maxWidth={"960px"} m="0 auto" p={1.25}>
                <IntroductionVideoSection />
                <AboutSection />
                <ExhibitsSection />
                <TeamSection />
                {/*<WordCloudSection />*/}
                <EventsSection />
                <PressSection />
            </Box>
        </Box>
    </>)
}