import { yupResolver } from "@hookform/resolvers/yup";
import { Close, Publish } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Paper, styled, Typography } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ExhibitType } from "classes/exhibit";
import { important, TextField, Upload } from "components/responsive-components";
import { UpdateExhibitModel, usePostApiExhibitMutation, usePatchApiExhibitMutation } from "services/exhibitApi";
import { useUser } from "store";
import { usePostUploadImageEditorMutation } from "services/uploadApi";

type formData = {
    id: string;
    serialNum: string;
    domain: string;
    courseTitle: string;
    teachers: string;
    workTitle: string;
    workEnTitle: string;
    workIntro: string;
    authors: string;
    imageUrl: string;
    videoUrl: string;
}

export interface ExhibitEditDialogProps {
    open: boolean;
    type: ExhibitType;
    initData?: formData;
    onClose?: () => void;
    onSave?: () => void;
}
export function ExhibitEditDialog(props: ExhibitEditDialogProps) {
    const { open, type, initData, onClose, onSave } = props;

    const schema = yup.object().shape({
        serialNum: yup.string().trim(),
        domain: yup.string().trim().required('必填'),
        courseTitle: yup.string().trim().required('必填'),
        teachers: yup.string().trim(),
        workTitle: yup.string().trim().required('必填'),
        workEnTitle: yup.string().trim(),
        workIntro: yup.string().trim(),
        authors: yup.string().trim(),
        videoUrl: yup.string().trim().url('必須為連結'),
    });
    const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm<formData>({
        mode: "onChange", resolver: yupResolver(schema),
        defaultValues: initData ?? {
            id: "",
            serialNum: "",
            domain: "",
            courseTitle: "",
            teachers: "",
            workTitle: "",
            workEnTitle: "",
            workIntro: "",
            authors: "",
            imageUrl: "",
            videoUrl: ""
        }
    });
    React.useEffect(() => {
        reset(initData ?? {
            id: "",
            serialNum: "",
            domain: "",
            courseTitle: "",
            teachers: "",
            workTitle: "",
            workEnTitle: "",
            workIntro: "",
            authors: "",
            imageUrl: "",
            videoUrl: ""
        });
        setImgFile(undefined);
    }, [initData, open])

    const imageUrlValue = watch("imageUrl");
    const handleImageUrlChange = (url: string) => setValue("imageUrl", url);
    const [clearImage, setClearImage] = React.useState([]);

    //圖檔暫存
    const [imgFile, setImgFile] = React.useState<File>();
    function handleRemoveImage() {
        setImgFile(undefined);
        handleImageUrlChange('');
    }

    async function onSubmit(data: formData) {
        setIsLoading(true);
        try {
            if (imgFile != null) {
                let uri = await uploadImage();
                if (!uri) throw new Error("上傳圖片失敗");
                handleImageUrlChange(uri);
                await updateData({ ...data, imageUrl: uri });
            }
            else await updateData(data);

            if (onSave) onSave();

        } catch (error) {
            let message = 'Unknown Error'
            if (error instanceof Error) message = error.message
            setErrorMessage(message);
            setOpenError(true);

            console.error(message);
        } finally {
            setIsLoading(false);
        }
    }

    //圖片上傳
    const [uploadUserImage] = usePostUploadImageEditorMutation();
    const userId = useUser().userId;
    async function uploadImage() {
        if (!imgFile) return;

        try {
            const formData = new FormData();
            formData.append("upload", imgFile);
            formData.append("ckCsrfToken", userId);

            let result = await uploadUserImage({ body: formData as any }).unwrap();
            return result.url;
        }
        catch {
            throw new Error("上傳圖片失敗");
        }
    }

    //新增或編輯資料
    const [addExhibit] = usePostApiExhibitMutation();
    const [editExhibit] = usePatchApiExhibitMutation();
    async function updateData(data: UpdateExhibitModel) {
        try {
            let result = null;
            if (!!initData)
                result = await editExhibit({ type: type, updateExhibitModel: data }).unwrap();
            else result = await addExhibit({ type: type, updateExhibitModel: data }).unwrap();
            if (!result.isSuccess)
                throw new Error(result.message ?? "上傳資料失敗");
        }
        catch {
            throw new Error("上傳資料失敗");
        }
    }

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [openAsk, setOpenAsk] = React.useState<boolean>(false);
    const [openError, setOpenError] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();

    const handleCancel = () => {
        setOpenAsk(true);
    };

    const handleClose = () => {
        if (onClose) onClose();
    }

    enum ExhibitsAddTitle {
        "新增「未來肉身」" = 0,
        "新增「明日視界」" = 1,
        "新增「智慧生活」" = 2
    }

    enum ExhibitsEditTitle {
        "編輯「未來肉身」" = 0,
        "編輯「明日視界」" = 1,
        "編輯「智慧生活」" = 2
    }
    return (<>
        <Dialog open={open} onClose={handleCancel} maxWidth={"md"} fullWidth disableEnforceFocus>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" fontWeight="bold" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                            {!!initData ? ExhibitsAddTitle[type] : ExhibitsEditTitle[type]}
                        </Typography>
                        <Button onClick={handleCancel} endIcon={<Close />} sx={{ flexShrink: 0 }}>關閉</Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">作品編號</Typography>
                    <TextField
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        inputRef={register('serialNum').ref}
                        inputProps={{ maxLength: 200 }}
                        placeholder={"作品編號"}
                        error={!!errors.serialNum}
                        helperText={errors.serialNum ? errors.serialNum.message : ' '}
                        {...register("serialNum")}
                        disabled={isLoading}
                    />

                    <Typography variant="subtitle1">領域{important}</Typography>
                    <TextField
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        inputRef={register('domain').ref}
                        inputProps={{ maxLength: 200 }}
                        placeholder={"領域"}
                        error={!!errors.domain}
                        helperText={errors.domain ? errors.domain.message : ' '}
                        {...register("domain")}
                        disabled={isLoading}
                    />

                    <Typography variant="subtitle1">課程名稱{important}</Typography>
                    <TextField
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        inputRef={register('courseTitle').ref}
                        inputProps={{ maxLength: 200 }}
                        placeholder={"課程名稱"}
                        error={!!errors.courseTitle}
                        helperText={errors.courseTitle ? errors.courseTitle.message : ' '}
                        {...register("courseTitle")}
                        disabled={isLoading}
                    />

                    <Typography variant="subtitle1">師資</Typography>
                    <TextField
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        inputRef={register('teachers').ref}
                        inputProps={{ maxLength: 200 }}
                        placeholder={"師資"}
                        error={!!errors.teachers}
                        helperText={errors.teachers ? errors.teachers.message : ' '}
                        {...register("teachers")}
                        disabled={isLoading}
                    />

                    <Typography variant="subtitle1">作品名稱{important}</Typography>
                    <TextField
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        inputRef={register('workTitle').ref}
                        inputProps={{ maxLength: 200 }}
                        placeholder={"作品名稱"}
                        error={!!errors.workTitle}
                        helperText={errors.workTitle ? errors.workTitle.message : ' '}
                        {...register("workTitle")}
                        disabled={isLoading}
                    />

                    <Typography variant="subtitle1">作品英文名稱</Typography>
                    <TextField
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        inputRef={register('workEnTitle').ref}
                        inputProps={{ maxLength: 200 }}
                        placeholder={"作品英文名稱"}
                        error={!!errors.workEnTitle}
                        helperText={errors.workEnTitle ? errors.workEnTitle.message : ' '}
                        {...register("workEnTitle")}
                        disabled={isLoading}
                    />

                    <Typography variant="subtitle1">作品介紹</Typography>
                    <TextField
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        inputRef={register('workIntro').ref}
                        multiline
                        minRows={3}
                        placeholder={"作品介紹"}
                        error={!!errors.workIntro}
                        helperText={errors.workIntro ? errors.workIntro.message : ' '}
                        {...register("workIntro")}
                        disabled={isLoading}
                    />

                    <Typography variant="subtitle1">作者</Typography>
                    <TextField
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        inputRef={register('authors').ref}
                        inputProps={{ maxLength: 200 }}
                        multiline
                        minRows={3}
                        placeholder={"作者"}
                        error={!!errors.authors}
                        helperText={errors.authors ? errors.authors.message : ' '}
                        {...register("authors")}
                        disabled={isLoading}
                    />

                    <Typography variant="subtitle1">附圖 (建議大小 350x280)</Typography>
                    <Box width="100%" maxWidth="350px">
                        <Box position="relative" width="100%" paddingTop={(4 / 5 * 100) + "%"} mb={3}>
                            <Box position="absolute" top={0} right={0} bottom={0} left={0}>
                                <Upload
                                    value={imageUrlValue} onChange={(file) => { setImgFile(file) }}
                                    width="100%" height="100%"
                                    containerStyle={{ width: "100%", height: "100%" }}
                                >
                                    <div className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: "1px solid rgba(0, 0, 0, 0.23)",
                                            borderRadius: "4px",
                                            color: "darkgray",
                                            cursor: "pointer",
                                            width: "100%",
                                            height: "100%",
                                        }}>
                                        <Publish />
                                        點擊選擇圖片或拖曳圖片到這裡
                                    </div>
                                </Upload>
                            </Box>
                        </Box>
                        <Button variant='contained' onClick={handleRemoveImage} sx={{ mb: 6 }}>移除圖片</Button>
                    </Box>

                    <Typography variant="subtitle1">影片連結 ※會取代圖片位置</Typography>
                    <TextField
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        inputRef={register('videoUrl').ref}
                        placeholder={"影片連結 ※會取代圖片位置"}
                        error={!!errors.videoUrl}
                        helperText={errors.videoUrl ? errors.videoUrl.message : ' '}
                        {...register("videoUrl")}
                        disabled={isLoading}
                    />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <LoadingButton type="submit" variant="contained" loading={isLoading}>
                        送出！
                    </LoadingButton>
                </DialogActions>
            </Box>
            {/*關閉前詢問*/}
            <Dialog open={openAsk}>
                <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                    <span style={{ fontWeight: 'bold' }}>
                        是否捨棄編輯？
                    </span>
                </div>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button autoFocus onClick={() => { setOpenAsk(false); handleClose(); }}>是</Button>
                    <Button onClick={() => { setOpenAsk(false); }}>否</Button>
                </DialogActions>
            </Dialog>
            {/*報錯*/}
            <Dialog open={openError} onClose={() => setOpenError(false)}>
                <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                    <Typography color="error.main">
                        {errorMessage}
                    </Typography>
                </div>
            </Dialog>
        </Dialog>
        {isLoading &&
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        }
    </>);
}