import { faAddressCard, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { logoPath } from "config";
import { Outlet } from "react-router-dom";
import { Footer } from './Footer';
import { NavBar } from './NavBar';
import CustomCursor from 'components/CustomCursor';

export const Layout = () => {
    return (<>
        <CustomCursor>
            <NavBar logo={<Logo />} name={<LogoText />} />
            <div style={{ minHeight: window.innerHeight - 80 - 135 }}>
                <Outlet />
            </div>
            <Footer />
        </CustomCursor>
    </>);
};

const Logo = () => {
    return (
        <img src={logoPath} style={{ height: '36px', maxWidth: '100%' }} />
    )
}
const LogoText = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <Typography style={{ padding: isMobile ? 0 : 8, fontSize: isMobile ? "10px" : "inherit", maxWidth: isMobile ? "calc(100vw - 60px)" : "29vw" }}>2024 OpenLabs</Typography>
    )
}