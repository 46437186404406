import * as React from 'react';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, OutlinedInput, Radio, RadioGroup } from '@mui/material';
import { Box, Stack, Toolbar, Typography } from '@mui/material';
import { Exhibit, ExhibitType } from 'classes/exhibit';
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { PlayCircleFilled } from '@mui/icons-material';
import { read, utils, writeFile } from 'xlsx';
import { UpdateExhibitModel, usePostApiExhibitAddlistMutation } from 'services/exhibitApi';
import { SubTitle } from 'components/responsive-components';
import { ExhibitsTitle } from './ExhibitManagePage';

interface ImportData {
    serialNum: string;
    domain: string;
    courseTitle: string;
    teachers: string;
    workTitle: string;
    workEnTitle: string;
    workIntro: string;
    authors: string;
}
class ImportDataClass implements ImportData {
    constructor(
        public serialNum = '',
        public domain = '',
        public courseTitle = '',
        public teachers = '',
        public workTitle = '',
        public workEnTitle = '',
        public workIntro = '',
        public authors = '',
    ) { }
}
export default function NewExhibitList() {
    const [exhibitType, setExhibitType] = React.useState(ExhibitType.technology);
    const handleExhibitTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExhibitType(Number((event.target as HTMLInputElement).value));
    };

    const [fileAccept, setFileAccept] = React.useState('.csv');
    const handleFileTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileAccept((event.target as HTMLInputElement).value);
    };

    const [excelData, setExcelData] = React.useState<ImportData[]>([]);
    const onImport = (file: React.ChangeEvent<HTMLInputElement>) => {
        //讀檔
        if (fileAccept === ".csv") {
            onImportExcel(file).then((object) => { setExcelData(object) }).catch(() => {
                console.error("上傳失敗");
            })
        } else if (fileAccept === ".xlsx, .xls") {
            onImportExcel(file).then((object) => { setExcelData(object) }).catch(() => {
                console.error("上傳失敗");
            })
        }
    }

    const [postNewExhibits] = usePostApiExhibitAddlistMutation();
    const onSubmit = async () => {
        setLoading(true);
        let newExhibits: UpdateExhibitModel[] = [];

        for (let i = 0; i < excelData.length; i++) {
            let newExhibit: UpdateExhibitModel = {
                id: '',
                serialNum: excelData[i].serialNum.toString(),
                domain: excelData[i].domain.toString(),
                courseTitle: excelData[i].courseTitle.toString(),
                teachers: excelData[i].teachers.toString(),
                workTitle: excelData[i].workTitle.toString(),
                workEnTitle: excelData[i].workEnTitle.toString(),
                workIntro: excelData[i].workIntro.toString(),
                authors: excelData[i].authors.toString(),
                videoUrl: '',
                imageUrl: '',
            };
            newExhibits.push(newExhibit);
        }
        await postNewExhibits({ type: exhibitType, body: newExhibits }).unwrap().then((result) => {
            if (result.isSuccess) {
                setGenerateList(newExhibits);
            }
            setLoading(false);
        });

    };

    const [generateList, setGenerateList] = React.useState<UpdateExhibitModel[]>([]);
    const [loading, setLoading] = React.useState(false);

    return (
        <div>
            <SubTitle
                icon='circle'
                text={ExhibitsTitle[3]}
            />
            <Typography ml={3}>
                使用匯入功能時，請先確保header欄位名稱包含以下：
            </Typography>
            <Typography ml={3} fontWeight="bold" gutterBottom>
                {Object.keys(new ImportDataClass()).join(", ")}
            </Typography>
            <Typography ml={3} color='error.light'>
                ※注意！匯入時只會匯入表單第一頁之內容
            </Typography>
            <Box sx={{ '& .MuiTextField-root': { minWidth: 320, width: '50%' } }}>
                <Stack alignItems="start" spacing={1} p={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">匯入類型</FormLabel>
                        <RadioGroup row value={exhibitType} onChange={handleExhibitTypeChange}>
                            <FormControlLabel value={ExhibitType.technology} control={<Radio />} label="未來肉身" />
                            <FormControlLabel value={ExhibitType.culture} control={<Radio />} label="明日視界" />
                            <FormControlLabel value={ExhibitType.health} control={<Radio />} label="智慧生活" />
                        </RadioGroup>
                    </FormControl>

                    <FormControl component="fieldset">
                        <FormLabel component="legend">上傳檔案格式</FormLabel>
                        <RadioGroup row value={fileAccept} onChange={handleFileTypeChange}>
                            <FormControlLabel value=".csv" control={<Radio />} label=".csv" />
                            <FormControlLabel value=".xlsx, .xls" control={<Radio />} label=".xlsx, .xls" />
                        </RadioGroup>
                    </FormControl>

                    {fileAccept != "other" &&
                        <input type='file' accept={fileAccept} onChange={onImport} />
                    }
                    <div className="d-flex justify-content-center w-100">
                        <LoadingButton size='large' color="primary" variant="contained" onClick={onSubmit}
                            loading={loading} loadingPosition="start" startIcon={<PlayCircleFilled />}>
                            生成
                        </LoadingButton>
                    </div>
                    {generateList.map((data) => (
                        <div key={data.workTitle}>
                            <Typography paragraph>
                                {`${data.courseTitle} ${data.workTitle} ${data.workEnTitle}`}
                            </Typography>
                        </div>
                    ))}
                </Stack>
            </Box>
        </div>
    )
};

const onImportExcel = (file: React.ChangeEvent<HTMLInputElement>) => new Promise<ImportData[]>(function (resolve, reject) {
    // 獲取上傳的檔案物件
    const { files } = file.target;
    if (!files) { reject('檔案型別不正確！'); return; };

    // 通過FileReader物件讀取檔案
    const fileReader = new FileReader();
    fileReader.onload = event => {
        try {
            if (!event?.target) throw new Error("上傳失敗");

            const { result } = event.target;
            // 以二進位制流方式讀取得到整份excel表格物件
            const workbook = read(result, { type: 'binary' });
            // 儲存獲取到的資料
            let data: any[] = [];
            // 遍歷每張工作表進行讀取（這裡預設只讀取第一張表）
            for (const sheet in workbook.Sheets) {
                // esline-disable-next-line
                if (workbook.Sheets.hasOwnProperty(sheet)) {
                    // 利用 sheet_to_json 方法將 excel 轉成 json 資料
                    data = data.concat();
                    break; // 如果只取第一張表，就取消註釋這行
                }
            }
            // 最終獲取到並且格式化後的 json 資料
            resolve(data);
        } catch (e) {
            // 這裡可以丟擲檔案型別錯誤不正確的相關提示
            reject('檔案型別不正確！');
        }
    };
    // 以二進位制方式開啟檔案
    fileReader.readAsBinaryString(files[0]);
})