import { Box, IconButton, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useInView } from "react-intersection-observer";
import TidbitCard from "../../components/TidbitCard";
import { useLazyGetApiTidbitQuery } from "services/tidbitApi";
import { Tidbit } from "classes/tidbit";

export default function PressSection() {
    const theme = useTheme();

    //側邊Title文字控制
    const { ref, inView } = useInView({
        threshold: 0.5
    });

    //初始化
    const [tidbitGroups, setTidbitGroups] = React.useState<Tidbit[]>([]);
    const [getTidbits] = useLazyGetApiTidbitQuery();
    React.useLayoutEffect(() => { init(); }, []);
    async function init() {
        try {
            let result = await getTidbits().unwrap();
            if (result.isSuccess) {
                setTidbitGroups([...(result?.data ?? [])] as Tidbit[]);
            }
        }
        catch {
            console.error("發生問題");
        }
    }

    //分頁功能
    const checkIsLg = useMediaQuery(theme.breakpoints.up("lg"));
    const checkIsMd = useMediaQuery(theme.breakpoints.between("md", "lg"));

    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(6);

    let showList = tidbitGroups?.slice((page - 1) * pageSize, page * pageSize);
    let emptyRows = pageSize - showList.length;
    let totalCount = tidbitGroups?.length ?? 0;
    let currentPage = page ?? 1;
    let totalPages = Math.ceil(totalCount / pageSize) || 1;
    let hasNext = currentPage < totalPages;
    let hasPrevious = currentPage > 1;

    const handleChangePage = (to: number) => {
        let toPage = to;
        if (toPage > totalPages) toPage = totalPages;
        if (toPage < 1) toPage = 1;
        setPage(toPage);
    }

    const CheckWindowSize = () => {
        let newPageSize = 6;
        if (checkIsLg) {
            newPageSize = 6;
        }
        else if (checkIsMd) {
            newPageSize = 4;
        }
        else {
            newPageSize = 2;
        }

        setPageSize(newPageSize);

        let total = Math.ceil(totalCount / newPageSize) || 1;
        if (page > total) setPage(total);
    };
    React.useEffect(() => {
        CheckWindowSize();
    }, [checkIsLg, checkIsMd]);

    return (<>
        <Box id="press" ref={ref} minHeight="100vh" py={{ xs: 2, md: 4 }} >
            {tidbitGroups.length > 0 ? <>
                <Box
                    borderRadius={"5px"}
                    bgcolor={"var(--85-white, rgba(255, 255, 255, 0.85))"}
                    boxShadow={"0px 0px 18px 1px rgba(255, 255, 255, 0.85)"}
                    textAlign={"center"}
                    p={{ sm: "40px 80px 100px 80px", md: "40px 80px 100px 80px", lg: "40px 29px 60px 29px" }}
                    mb={9}
                >
                    <Box mb={5} fontSize={0}>
                        <img src="./images/網頁區塊標題-媒體花絮.svg" />
                    </Box>
                    <Box
                        position={"relative"}
                    >
                        <Box display={"grid"} gridTemplateColumns={{ sm: "1fr", md: "repeat(2,1fr)", lg: "repeat(3,1fr)" }} gap={2}>
                            {showList.map((tidbit, index) => (
                                <TidbitCard data={tidbit} key={`${index}: ${tidbit.id}`} />
                            ))}
                        </Box>
                        <ArrowButton onClick={() => handleChangePage(page - 1)} //disabled={!hasPrevious}
                            sx={{ position: 'absolute', left: { xs: 55, md: -77, lg: -86 } }}>
                            <img src="./images/arrow.svg" className="left_arrow arrow" />
                        </ArrowButton>
                        <ArrowButton onClick={() => handleChangePage(page + 1)} //disabled={!hasNext}
                            sx={{ position: 'absolute', right: { xs: 55, md: -77, lg: -86 }, }}>
                            <img src="./images/arrow.svg" className="right_arrow arrow" />
                        </ArrowButton>
                    </Box>
                </Box>
            </>
                :
                <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" margin='auto'>
                    <Typography variant="h1" color="grey.800" fontWeight="bold" sx={{
                        textShadow: "-6px 8px 0px #ffffff, 6px -4px 0px #7bf5ff"
                    }}>Coming Soon</Typography>
                </Box>
            }
        </Box>
        {/*
        <HideableSideTitle display={{ xs: "none", md: "block" }} show={inView} text="PRESS" />
         */}
    </>)
}

const ArrowButton = styled(IconButton)(({ theme }) => ({
    textDecoration: "none",
    transition: theme.transitions.create(["transform", "box-shadow"]),
    transform: "translate(0px, 0px)",
    color: theme.palette.primary.main,
    zIndex: 10,
    background: "#FD8489",
    border: "4px solid #000",
    padding: "12px",
    aspectRatio: "1",
    '@supports not (aspect-ratio: 1)': {
        with: "70px",
        height: "70px",
    },
    [theme.breakpoints.up("md")]: {
        //  top: 0,
        bottom: "calc(50% - 35px)",
        margin: 'auto 0'
    },
    [theme.breakpoints.down("md")]: {
        bottom: -80,
    },
    "&.Mui-disabled": {
        backgroundColor: "#FD8489",
        opacity: .6
    },
    "& .arrow": {
        transition: "300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;"
    },
    "& .left_arrow": {
        transform: "translateX(-3%)"
    },
    "& .right_arrow": {
        transform: "translateX(3%) scale(-1)"
    },
    "&:hover": {
        background: "#FD8489",
        "& .left_arrow": {
            transform: "translateX(-12%)"
        },
        "& .right_arrow": {
            transform: "translateX(12%) scale(-1)"
        },
    },
}));