import * as React from "react";
import { Button, ClickAwayListener, Collapse, Grow, List, ListItemButton, ListItemText, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { NavBarLinkItem, NavBarMenuItem } from "./NavBar";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export const PopOutButton = (props: { item: NavBarMenuItem, onClickItem: (item: NavBarLinkItem, event: React.MouseEvent) => void }) => {
    const { item, onClickItem } = props;

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleClick = (item: NavBarLinkItem, event: React.MouseEvent) => {
        setOpen(false);
        onClickItem(item, event);
    };

    return (<>
        <Button
            ref={anchorRef} aria-haspopup="true"
            disabled={item.disabled} startIcon={item.icon}
            onClick={handleToggle} sx={{ color: "inherit", ...item.sx }}
        >
            {item.name}<ExpandMore />
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open}>
                                {item.itemList.map((item) => (
                                    <MenuItem
                                        onClick={(event) => handleClick(item, event)}
                                        key={item.name}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>)
};
export const ExpandedButton = (props: { item: NavBarMenuItem, onClickItem: (item: NavBarLinkItem, event: any) => void }) => {
    const { item, onClickItem } = props;

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = () => {
        setExpanded(!expanded);
    };
    const handleClick = (item: NavBarLinkItem, event: any) => {
        setExpanded(false);
        onClickItem(item, event);
    };

    return (<>
        <ListItemButton onClick={handleChange} disabled={item.disabled} sx={{ ...item.sx }}>
            <ListItemText>
                {item.name}
            </ListItemText>
            {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {item.itemList.map((item, index) => (
                    <ListItemButton key={index.toString()} href={item.link} disabled={item.disabled} onClick={(event) => handleClick(item, event)} sx={{ pl: 4, ...item.sx }}>
                        <ListItemText>
                            {item.name}
                        </ListItemText>
                    </ListItemButton>
                ))}
            </List>
        </Collapse>
    </>)
};