import { Box, Typography } from "@mui/material";
import { WindowButton } from "components/Home";
import ReactPlayer from "react-player";
export default function IntroductionVideoSection() {

    return (<>
        <Box id="about" pt={6}>
            <Box
                sx={{
                    width: "100%",
                    "borderRadius": "3px",
                    "border": "5px solid #000",
                    "background": "var(--Primary, #FD8489)",
                    "boxShadow": "-5px 5px 0px 0px #7FB7E2",

                }}
                //  maxWidth={"670px"}
                //   height={"440px"}

                margin={"0 auto"}
                position={"relative"}
                mb={"70px"}
            >
                <Box
                    p={2}
                    width={"100%"}
                    height={"100%"}
                >
                    <Typography
                        variant="h5"
                        fontSize={{ lg: "26px" }}
                    >
                        影片導覽
                    </Typography>
                    <WindowButton />
                    <Box mt={2} sx={{
                        position: "relative",
                        aspectRatio: "560/315",
                        '@supports not (aspect-ratio: 1/1)': {
                            paddingTop: "calc(315 / 560 * 100%)",
                        },
                    }}>
                        <Box position={"absolute"} top={"0"} height={"100%"} width={"100%"}>
                            <ReactPlayer url={'https://www.youtube.com/embed/kmdtwgsc-YY'} width="100%" height="100%" />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>

    </>)
}