import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enhancedApi as loginApi, OutputUser } from 'services/loginApi';

const initialState: OutputUser = {
    userId: null,
    role: 0,
    account: null
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<OutputUser>) => ({
            ...action.payload
        }),
        updateUser: (state, action: PayloadAction<Partial<OutputUser>>) => ({
            ...state, ...action.payload
        }),
        logout: (state) => ({
            ...initialState
        }),
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            loginApi.endpoints.getApiLoginState.matchFulfilled,
            (state, { payload: { isSuccess, data } }) => (
                isSuccess ? data : initialState)
        ).addMatcher(
            loginApi.endpoints.getApiLoginLogout.matchFulfilled,
            () => initialState
        )
    },
})

export const { login, updateUser, logout } = userSlice.actions

export default userSlice.reducer