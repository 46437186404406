import * as React from "react";
import { Box, Button, SxProps, Theme, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useUser } from "store";
import { useLocation, useNavigate } from "react-router-dom";
import { PopOutButton } from "./NavBarButton";
import { NavSideBar } from "./NavSideBar";
import { scroller } from "react-scroll";

const BarImg = "./images/bar.png";
const Bar2Img = "./images/bar2.png";

const navItemList: NavBarItem[] = [
    { name: 'About', link: '/#about' },
    { name: 'Exhibits', link: '/#exhibits' },
    { name: 'Events', link: '/#events' },
    { name: 'Press', link: '/#press' },
];
export const NavBar = (props: NavBarProps) => {
    const user = useUser();
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const toggleClose = () => setIsOpen(false);

    //控制左上角顯示
    const [showTop, setShowTop] = React.useState<boolean>(false);
    const pathName = useLocation().pathname;
    React.useEffect(() => {
        let matches = [/\/(\?.+)?$/];
        let path = pathName.toLowerCase();
        if (matches.some(match => path.match(match))) {
            setShowTop(true);
        }
        else {
            setShowTop(false);
        }
    }, [pathName]);

    const handleClickLink = (link: string, event: React.MouseEvent) => {
        event.preventDefault();
        if (link) {
            navigate(link);
        }
        toggleClose();
    }
    const hash = useLocation().hash;
    const handleClickItem = (item: NavBarLinkItem, event: React.MouseEvent) => {
        event.preventDefault();
        if (item.link) {
            if (item.isOuterLink) {
                window.open(item.link, '_blank');
            }
            else {
                //有hash未變動的scroll處理
                if (hash == item.link.replace("/", "")) {
                    scroller.scrollTo(item.link.split('#')[1], {
                        //smooth: true,
                        offset: -30,
                    });
                }
                else navigate(item.link);
            }
        }
        toggleClose();
    };

    return (<>

        {showTop && <>
            {isMobile ?
                <Box sx={{
                    display: "flex",
                    position: "fixed",
                    background: `url('${Bar2Img}')`,
                    backgroundPosition: "top -8px left -12px",
                    backgroundSize: "calc(100% + 16px) calc(100% + 8px)",
                    padding: "0 8px 10px 0",
                    zIndex: theme.zIndex.appBar,
                    "& a": {
                        color: "inherit", textDecoration: "none", "&:hover": { color: "inherit", textDecoration: "none" }
                    }
                }}>
                    <Toolbar>
                        {/*LOGO*/}
                        <Typography variant="h6" noWrap component="a" href="https://ict.nycu.edu.tw/" target='_blank'
                            sx={{ mr: 4 }}>
                            {props.logo}
                        </Typography>
                    </Toolbar>
                </Box>
                :
                <Box sx={{
                    display: "flex",
                    position: "fixed",
                    background: `url('${BarImg}')`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "right",
                    //  backgroundPosition: "top -6px left -10px",
                    // backgroundSize: "calc(100% + 16px) calc(100% + 8px)",
                    paddingRight: "48px",
                    zIndex: theme.zIndex.appBar,
                    "& a": {
                        color: "inherit", textDecoration: "none", "&:hover": { color: "inherit", textDecoration: "none" }
                    },
                    color: "#4D4D4D"
                }}>
                    <Toolbar sx={{
                        minHeight: { sm: "48px" },

                    }}>
                        {/*LOGO*/}
                        <Typography variant="h6" noWrap component="a"
                            href="https://ict.nycu.edu.tw/" target='_blank'
                            sx={{ display: { xs: 'none', md: 'flex' }, mr: 4 }}>
                            {props.logo}
                        </Typography>

                        {/*Pages*/}

                        <Box>
                            {navItemList.map((item, index) => {
                                if (isMenuItem(item)) return (
                                    <PopOutButton
                                        key={item.name} item={item}
                                        onClickItem={handleClickItem}
                                    />
                                );
                                else return (
                                    <Button key={item.name} href={item.link} disabled={item.disabled} onClick={(event) => handleClickItem(item, event)} sx={{ color: "inherit" }}>
                                        {item.name}
                                    </Button>
                                );
                            })}
                        </Box>
                    </Toolbar>
                </Box>
            }
        </>}
        <NavSideBar navItems={navItemList} />
    </>)
}
export interface NavBarProps {
    logo?: React.ReactElement;
    name?: React.ReactElement;
}

export type NavBarMenuItem = {
    name: string;
    icon?: React.ReactNode;
    itemList: NavBarLinkItem[];
    sx?: SxProps<Theme>;
    t?: boolean; //預設為false，是否要翻譯
    disabled?: boolean;
}
export type NavBarLinkItem = {
    name: string; //顯示的文字
    link: string;
    icon?: React.ReactNode;
    onClick?: (event: React.MouseEvent | React.TouchEvent) => void;
    sx?: SxProps<Theme>;
    t?: boolean; //預設為false，是否要翻譯
    disabled?: boolean;
    isOuterLink?: boolean;
}
export type NavBarItem = NavBarMenuItem | NavBarLinkItem;
export function isMenuItem(object: NavBarItem): object is NavBarMenuItem {
    return 'itemList' in object;
}