import * as React from "react";
import { Box, Button, Drawer, IconButton, IconButtonProps, Stack, styled, SvgIcon, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useUser } from "../../store";
import { useNavigate, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import { isMenuItem, NavBarItem, NavBarLinkItem } from "./NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUserCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { PopOutButton } from "./NavBarButton";
import { PointRules } from "../PointRules";

const BG = "./images/fin_Background.jpg";
const drawerWidth: number = 480;

interface NavSideBarProps {
    navItems: NavBarItem[];
}
export function NavSideBar(props: NavSideBarProps) {
    const { navItems } = props;
    const user = useUser();
    const history = useNavigate();
    const pathName = useLocation().pathname;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const toggleClose = () => setIsOpen(false);

    //偵測是否為首頁
    const [isHomePage, setIsHomePage] = React.useState<boolean>(false);
    React.useEffect(() => {
        let matches = [/\/(\?.+)?$/];
        let path = pathName.toLowerCase();
        if (matches.some(match => path.match(match))) {
            setIsHomePage(true);
        }
    }, [pathName]);

    const handleClickItem = (item: NavBarLinkItem, event: React.MouseEvent) => {
        event.preventDefault();
        if (item.link) {
            //首頁觸發scroll
            if (isHomePage) {
                scroller.scrollTo(item.link.split('#')[1], null);
            }
            else {
                history(item.link);
            }
        }
        if (item.onClick) {
            item.onClick(event);
        }
        toggleClose();
    };

    const [openRules, setOpenRules] = React.useState<boolean>(false);
    function handleRuleDialog(open: boolean) {
        setOpenRules(open);
    }

    return (<>
        <Stack display="flex" direction='row' position="fixed" top={8} right={12} zIndex={theme.zIndex.appBar} spacing={1}>
            {isMobile &&
                <SideBarButton size={isMobile ? "small" : "medium"} onClick={toggle} sx={{ padding: '8px' }}>
                    <SvgIcon><FontAwesomeIcon icon={faBars} /></SvgIcon>
                </SideBarButton>
            }
        </Stack>
        <Drawer anchor="right" open={isOpen} onClose={toggleClose}>
            <Stack
                width={isMobile ? "100vw" : drawerWidth}
                height="100%"
                maxHeight='100vh'
                overflow='hidden'
                bgcolor="primary.main"
            >
                <Box display="flex" justifyContent="flex-end" px={1.5} py={1} sx={{/* background: `url('${BG}')`,*/ backgroundSize: "1200px auto", backgroundPosition: "600px 0" }}>
                    <SideBarButton size={isMobile ? "small" : "medium"} onClick={toggle}>
                        {isMobile ?
                            <SvgIcon><FontAwesomeIcon icon={faTimes} /></SvgIcon> :
                            <SvgIcon sx={{ fontSize: 40 }}><FontAwesomeIcon icon={faTimes} /></SvgIcon>
                        }
                    </SideBarButton>
                </Box>
                <Stack bgcolor="primary.main" sx={{ paddingBottom: isMobile ? '12px' : '48px' }}>
                    {isMobile &&
                        <Stack flex="1 1" justifyContent="center" alignItems="center" spacing={2}>
                            {navItems.map((item) => {
                                if (isMenuItem(item)) return (
                                    <PopOutButton
                                        key={item.name} item={item}
                                        onClickItem={handleClickItem}
                                    />
                                );
                                else return (
                                    <Button key={item.name} href={item.link} disabled={item.disabled} onClick={(event) => handleClickItem(item, event)} sx={{ color: "white", fontSize: "2rem", "&:hover": { color: "grey.200" } }}>
                                        {item.name}
                                    </Button>
                                );
                            })}
                        </Stack>
                    }
                </Stack>
            </Stack>
        </Drawer>
        <PointRules open={openRules} onClose={() => handleRuleDialog(false)} />
    </>);
}
const SideBarButton = styled((props: IconButtonProps) =>
    <Box borderRadius="50%" bgcolor="background.default" boxShadow={(theme) => theme.shadows[3]}>
        <IconButton {...props} />
    </Box>
)({});