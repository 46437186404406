import { yupResolver } from "@hookform/resolvers/yup";
import { Close, Publish } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Paper, styled, Typography } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextEditor from "components/Tools/Editor/CKEditor";
import { EventType } from "classes/event";
import { important, TextField, Upload } from "components/responsive-components";
import { UpdateEventModel, usePatchApiEventMutation, usePostApiEventMutation } from "services/eventApi";
import { useUser } from "store";
import { usePostUploadImageEditorMutation } from "services/uploadApi";

type formData = {
    id: string;
    title: string;
    content: string;
    imageUrl: string;
}

export interface EventEditDialogProps {
    open: boolean;
    type: EventType;
    initData?: formData;
    onClose?: () => void;
    onSave?: () => void;
}
export function EventEditDialog(props: EventEditDialogProps) {
    const { open, type, initData, onClose, onSave } = props;

    const schema = yup.object().shape({
        title: yup.string().trim().required("必填"),
    });
    const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm<formData>({
        mode: "onChange", resolver: yupResolver(schema),
        defaultValues: initData ?? {
            id: "", title: "",
            content: "<p>請在此輸入</p>",
            imageUrl: ""
        }
    });
    React.useEffect(() => {
        reset(initData ?? {
            id: "", title: "",
            content: "<p>請在此輸入</p>",
            imageUrl: ""
        });
        setImgFile(undefined);
    }, [initData, open])

    const imageUrlValue = watch("imageUrl");
    const handleImageUrlChange = (url: string) => setValue("imageUrl", url);
    const [clearImage, setClearImage] = React.useState([]);

    const contentValue = watch("content");
    const handleContentChange = (content: string) => setValue("content", content);

    //圖檔暫存
    const [imgFile, setImgFile] = React.useState<File>();
    function handleRemoveImage() {
        setImgFile(undefined);
        handleImageUrlChange('');
    }

    async function onSubmit(data: formData) {
        setIsLoading(true);
        try {
            if (imgFile != null) {
                let uri = await uploadImage();
                if (!uri) throw new Error("上傳圖片失敗");
                handleImageUrlChange(uri);
                await updateData({ ...data, imageUrl: uri });
            }
            else await updateData(data);

            if (onSave) onSave();

        } catch (error) {
            let message = 'Unknown Error'
            if (error instanceof Error) message = error.message
            setErrorMessage(message);
            setOpenError(true);

            console.error(message);
        } finally {
            setIsLoading(false);
        }
    }

    //圖片上傳
    const [uploadUserImage] = usePostUploadImageEditorMutation();
    const userId = useUser().userId;
    async function uploadImage() {
        if (!imgFile) return;

        try {
            const formData = new FormData();
            formData.append("upload", imgFile);
            formData.append("ckCsrfToken", userId);

            let result = await uploadUserImage({ body: formData as any }).unwrap();
            return result.url;
        }
        catch {
            throw new Error("上傳圖片失敗");
        }
    }

    //新增或編輯資料
    const [addEvent] = usePostApiEventMutation();
    const [editEvent] = usePatchApiEventMutation();
    async function updateData(data: UpdateEventModel) {
        try {
            let result = null;
            if (!!initData)
                result = await editEvent({ type: type, updateEventModel: data }).unwrap();
            else result = await addEvent({ type: type, updateEventModel: data }).unwrap();
            if (!result.isSuccess)
                throw new Error(result.message ?? "上傳資料失敗");
        }
        catch {
            throw new Error("上傳資料失敗");
        }
    }

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [openAsk, setOpenAsk] = React.useState<boolean>(false);
    const [openError, setOpenError] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();

    const handleCancel = () => {
        setOpenAsk(true);
    };

    const handleClose = () => {
        if (onClose) onClose();
    }

    enum EventsAddTitle {
        "新增「開箱實驗室」場次" = 0,
        // "新增「跨域分享會」場次" = 1,
        "新增「專場導覽」場次" = 2
    }

    enum EventsEditTitle {
        "編輯「開箱實驗室」場次" = 0,
        //  "編輯「跨域分享會」場次" = 1,
        "編輯「專場導覽」場次" = 2
    }
    return (<>
        <Dialog open={open} onClose={handleCancel} maxWidth={"md"} fullWidth disableEnforceFocus>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" fontWeight="bold" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                            {!!initData ? EventsEditTitle[type] : EventsAddTitle[type]}
                        </Typography>
                        <Button onClick={handleCancel} endIcon={<Close />} sx={{ flexShrink: 0 }}>關閉</Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">標題{important}</Typography>
                    <TextField
                        hiddenLabel
                        variant="outlined"
                        fullWidth
                        inputRef={register('title').ref}
                        inputProps={{ maxLength: 50 }}
                        placeholder={"標題"}
                        error={!!errors.title}
                        helperText={errors.title ? errors.title.message : ' '}
                        {...register("title")}
                        disabled={isLoading}
                    />
                    <Typography variant="subtitle1">{"附圖 (建議大小 350x280)"}{important}</Typography>
                    <Box width="100%" maxWidth="350px">
                        <Box position="relative" width="100%" paddingTop={(4 / 5 * 100) + "%"} mb={3}>
                            <Box position="absolute" top={0} right={0} bottom={0} left={0}>
                                <Upload
                                    value={imageUrlValue} onChange={(file) => { setImgFile(file) }}
                                    width="100%" height="100%"
                                    containerStyle={{ width: "100%", height: "100%" }}
                                >
                                    <div className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: "1px solid rgba(0, 0, 0, 0.23)",
                                            borderRadius: "4px",
                                            color: "darkgray",
                                            cursor: "pointer",
                                            width: "100%",
                                            height: "100%",
                                        }}>
                                        <Publish />
                                        點擊選擇圖片或拖曳圖片到這裡
                                    </div>
                                </Upload>
                            </Box>
                        </Box>
                        <Button variant='contained' onClick={handleRemoveImage} sx={{ mb: 6 }}>移除圖片</Button>
                    </Box>
                    <Typography variant="subtitle1">場次內容{important}</Typography>
                    <TextEditor data={contentValue} onChange={handleContentChange} />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <LoadingButton type="submit" variant="contained" loading={isLoading}>
                        送出！
                    </LoadingButton>
                </DialogActions>
            </Box>
            {/*關閉前詢問*/}
            <Dialog open={openAsk}>
                <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                    <span style={{ fontWeight: 'bold' }}>
                        是否捨棄編輯？
                    </span>
                </div>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button autoFocus onClick={() => { setOpenAsk(false); handleClose(); }}>是</Button>
                    <Button onClick={() => { setOpenAsk(false); }}>否</Button>
                </DialogActions>
            </Dialog>
            {/*報錯*/}
            <Dialog open={openError} onClose={() => setOpenError(false)}>
                <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                    <Typography color="error.main">
                        {errorMessage}
                    </Typography>
                </div>
            </Dialog>
        </Dialog>
        {isLoading &&
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        }
    </>);
}