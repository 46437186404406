import AuthLayout from "components/AuthLayout/AuthLayout";
import ErrorPage from "pages/ErrorPage/404Page";
import Home from "pages/Home/Home";
import { Layout } from "components/Layout";
import Root from "Root";
import { createBrowserRouter, Navigate } from "react-router-dom";
import AdminLayout from "components/Admin/AdminLayout";
import { getIsLoginLoader, logoutLoader } from "./loader";
import ImagesManager from "components/Tools/Editor/ImagesManager";
import Login from "pages/Login/Login";
import EventsIndex from "pages/Manage/Events/EventsIndex";
import { EventType } from "classes/event";
import ExhibitIndex from "pages/Manage/Works/Exhibit/ExhibitIndex";
import { ExhibitType } from "classes/exhibit";
import ProjectIndex from "pages/Manage/Works/Project/ProjectIndex";
import TidbitManagePage from "pages/Manage/Tidbits/TidbitManagePage";
import LoginHistoryPage from "pages/Manage/LoginHistoryPage";
import { ExhibitIntroEditor } from "pages/Manage/Works/Exhibit/ExhibitIntro";
import { TextEditorSection } from "components/TextEditorSection";
import ExhibitsPage from "pages/ExhibitsPage/ExhibitsPage";

export const routerConfig = createBrowserRouter([
    {
        element: <Root />,
        children: [
            {
                path: "/",
                element: <Layout />,
                children: [
                    { index: true, element: <Home /> },
                    { path: "ExhibitsPage", element: <ExhibitsPage /> },
                    { path: "*", element: <ErrorPage /> },
                ]
            },
            {
                path: "/manage",
                element: <AuthLayout adminOnly />,
                errorElement: <ErrorPage />,
                loader: getIsLoginLoader,
                children: [
                    {
                        path: "imagesManager",
                        loader: getIsLoginLoader,
                        errorElement: <ErrorPage />,
                        element: <ImagesManager />
                    },
                    {
                        element: <AdminLayout />,
                        children: [
                            { index: true, element: <></> },
                            { path: "events/openLabs", element: <EventsIndex eventType={EventType.openlabs} /> },
                            // { path: "events/interdisciplinaryTalk", element: <EventsIndex eventType={EventType.talk} /> },
                            { path: "events/seminars", element: <EventsIndex eventType={EventType.seminar} /> },
                            { path: "exhibits/technology", element: <ExhibitIndex exhibitType={ExhibitType.technology} /> },
                            { path: "exhibits/culture", element: <ExhibitIndex exhibitType={ExhibitType.culture} /> },
                            { path: "exhibits/health", element: <ExhibitIndex exhibitType={ExhibitType.health} /> },
                            { path: "exhibits/import", element: <ExhibitIndex exhibitType={-100} /> },
                            { path: "exhibits/introEditor", element: <TextEditorSection name={"ExhibitIntro"} /> },
                            { path: "team/introEditor", element: <TextEditorSection name={"TeamIntro"} /> },
                            { path: "events/introEditor", element: <TextEditorSection name={"EventIntro"} /> },
                            { path: "projects", element: <ProjectIndex type="manage" /> },
                            { path: "projects/import", element: <ProjectIndex type="new" /> },
                            { path: "tidbits", element: <TidbitManagePage /> },
                            { path: "signInLog", element: <LoginHistoryPage /> },
                            { path: "imagesManager", element: <ImagesManager /> },
                        ]
                    },
                ],
            },
            { path: "login", element: <Login /> },
            { path: "logout", loader: logoutLoader, element: <Navigate to="/" /> },
        ]
    }
]);