import { Box, Button, ButtonBase, Card, CardContent, Dialog, DialogContent, DialogTitle, Menu, Paper, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { WindowButton } from "components/ExhibitsDialog";
import { EditorSection } from "components/Home/EditorSection";
import { useState } from "react";
import ReactPlayer from "react-player";
import { Element, Link as ScrollLink, LinkProps as ScrollLinkProps } from 'react-scroll'

export default function TeamSection() {
    const [open, setOpen] = useState<boolean>(false);
    const [currentTeam, setCurrentTeam] = useState<Team>();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const teamList: Team[] = [
        {
            title: "生醫工程與健康科學小組",
            convener: "生物醫學工程學系 劉承揚 教授",
            assistant: "黃辰昕、陳冠華",
            intro: <>
                <Typography>
                    生物醫學與工程的結合，用以促進人類健康福祉，是目前國內外高科技發展的主流，各種醫療器材的研發，在醫療院所或日常生活中，可幫助疾病的診斷，監控健康的狀態，將大大改善人們的生活品質，促進人們的健康，延長人們的壽命。
                </Typography>
                <Typography>
                    本小組統整校內生醫工程相關的設備與空間，作為資源共享和共同創作之醫材原型開發平台，由校內教師和技師，搭配業界專家來講授醫材開發之相關軟硬體機器、材料、建模和創新設計等內容，讓學生們能整合相關專業知識，由工程技術思考如何解決臨床問題，而能實際製造出醫材原型，成為跨領域之優秀專業人才。
                </Typography>
            </>
        },
        {
            title: "機器人小組",
            convener: "電機工程學系 蕭得聖 教授",
            assistant: "蔡侑霖、張政友",
            intro: <>
                <Typography>
                    機器人結合感測、資訊、網路、控制技術及人工智慧技術，著重理論與實務之結合，同時也強調跨領域整合，是當前教育重要之一環。本小組開授課程包含電機、資工、機械、光電及科法等科系等領域之課程。多年來除既有之基礎專業課程以外，電機系亦配合創創工坊開授機器人學、機器人科技、自走式機器人及智慧機器人實驗等機器人專屬課程。
                </Typography>
                <Typography>
                    課程之特色在於透過實作與創意專題讓學生熟悉機器人專業知識並具備機器人實作能力。在實驗設備建置上，與國內重要機器人廠商合作，在Co-Working Space架構下充實機器人軟硬體設備，包含嵌入式系統，六軸機械臂，機器人控制器，影像及VR/AR設備，移動式機器人等，提供學生創意學習與專題實作之實驗室，培養學生解決實際問題之能力。
                </Typography>
            </>
        },
        {
            title: "新媒體創作小組",
            convener: "建築研究所 侯君昊 副教授",
            assistant: "熊盼盼、陳姿吟",
            intro: <>
                <Typography>
                    過去本校在專業創作領域是以獨立研究所（應用藝術、建築、音樂、傳播等）教學為主，提供給大學部的創作課程較少。近年全球高等教育愈來愈重視藝術與創作素養的全人教育，並鼓勵跨領域合作。
                </Typography>
                <Typography>
                    新媒體創作小組課程結合傳播研究所、建築研究所與人文社會學院課程，提供創作基礎、工藝、科技、影音、互動、整合等六種類型之課程，每門課程間彼此相輔相成譜出多元全貌。
                </Typography>
            </>
        },
        {
            title: "VR/AR小組",
            convener: "資訊工程學系 莊榮宏 教授",
            assistant: "徐培欽、洪煥璋",
            intro: <>
                <Typography>
                    隨著虛擬實境(Virtual Reality,簡稱VR)與擴增實境(Augmented Reality,簡稱AR)相關設備之功能提升且開始量產平價化，虛擬與擴增實境被視為全球近期最受注目與發展潛力的新媒體平台。VR與AR的應用具有高互動性，且於日常生活中應用廣泛，在系統設計上須考量使用者經驗、視知覺及設計美感等。這些條件使得VR與AR應用的開發複雜度遠高於傳統桌面及手機應用。
                </Typography>
                <Typography>
                    本課程主要由資訊學院、人文社會學院及客家文化學院教授支援授課，結合技術、設計、傳播、視覺、藝術等領域，培養學生在下一代媒體——「虛擬實境與擴增實境」的發展能兼顧技術、設計與使用者經驗。
                </Typography>
            </>
        },
        {
            title: "Drone小組",
            convener: "機械工程學系 程登湖 副教授",
            assistant: "詹竣智、許晏誠",
            intro: <>
                <Typography>
                    本課程將介紹無人機的基本飛行原理與其數學運動模型、基本控制原理，以及機身上的各式感應器，如IMU、ultrasonic sensor、camera等。透過對相關背景知識的了解，希望學員可以熟悉無人機的飛行原理與其運動行為的優缺點和限制，並發揮創意，激發出更多的實務應用案例。
                </Typography>
                <Typography>
                    本組課程有電機學院、資訊學院、機械學院等教授參與，預計學員完成一個學程後，能自組一台無人機，並具有完成解決機電整合問題的能力，可執行無人機的飛行與起降控制。
                </Typography>
            </>
        },
        {
            title: "數位製造小組",
            convener: "太空系統工程研究所 魏世昕助理教授",
            assistant: "張宏益、李坤修",
            intro: <>
                <Typography>
                    數位製造課程結合3D列印及精密加工技術，並規劃一系列基礎與進階實作課程，由校內師資、助教、搭配業界講師來講授3D列印及精密加工相關硬體機台、材料、建模軟體、創意設計等內容，讓學生了解相關背景知識及其應用潛力，並培養實作能力。最後以總整課程來深化學習成果，透過從應用創意發想、設計構思、建模列印、到成品檢驗的完整流程，讓學生們從做中學，享受動手做的樂趣，培養解決實際問題的能力，並深植跨域整合創作的基因。
                </Typography>
            </>
        },
        {
            title: "物聯網小組",
            convener: "資訊工程學系 范倫達 教授",
            assistant: "陳家斌、陳宥景",
            intro: <>
                <Typography>
                    物聯網小組教師涵蓋領域跨及資工、電機、生命科學、建築等領域，課程主題涉及智慧生活（穿戴式裝置、智慧手環、智慧手錶）、智慧城市（綠能）、智慧交通（大眾運輸、車載通訊、自動車）、智慧醫療（居家照護）、智慧物流（快遞）、智慧農業（溫室或農場之溫溼度環境）、智慧安全（安全保全監控系統）、智慧電網（智慧電表與電力輸送）、智慧建築（智能綠建築）等。
                </Typography>
            </>
        },
    ];
    return (<Box mb={15} >
        < Box sx={{
            "borderRadius": "5px",
            "background": "var(--85-white, rgba(255, 255, 255, 0.85))",
            // "boxShadow": "0px 0px 18px 16px rgba(255, 255, 255, 0.85)",
            textAlign: "center"
        }}
            maxWidth={"800px"}
            m="0 auto"
            boxShadow={"rgba(255, 255, 255, 0.85) 0px 0px 18px 16px"}
            py={7.5}
            px={2}
            mb={9}
        >
            <Box mb={5} fontSize={0}>
                <img src="./images/參展領域小組介紹.svg" style={{ maxWidth: "100%" }} />
            </Box>
            <Box
                padding={{ xs: "0 10px", md: "0 20px" }}
            >
                <EditorSection name="TeamIntro" />
            </Box>
        </Box >
        <Box>
            <Box display={{ xs: "flex", md: "flex", lg: "flex" }} m={"0 auto"} /*maxWidth={"650px"} gridTemplateColumns={{ xs: "1fr", lg: "repeat(2, 1fr)" }}*/ flexWrap="wrap" justifyContent="center" rowGap={isMobile ? 16 : 5} columnGap={5}>
                {teamList.map((x, index) => <TeamButton key={index} data={index + 1} onClick={() => { setCurrentTeam(x); setOpen(true) }} />)}
            </Box>
        </Box>
        {currentTeam && <TeamDialog open={open} onClose={() => setOpen(false)} team={currentTeam as Team} />}
    </Box >)
}

interface Team {
    title: string;
    convener: string;
    assistant: string;
    intro: JSX.Element;
    videoUrl?: string;
    imageUrl?: string
}


interface TeamButtonProps {
    onClick: () => void;
    data: number;
}

const TeamButton = styled(({ onClick, data, ...otherProps }: TeamButtonProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Button
            className="eventButton image"
            onClick={onClick}
            disableRipple
            disableTouchRipple
            sx={{
                width: "20%",
                minWidth: "200px",
                color: "white",
                fontSize: "0",
                padding: "0",
                "& .trans": {
                    fill: "red!impoertant"
                },
                "& .hover": { opacity: 0, position: "absolute", right: "0", bottom: "0" },
                "&:hover": {
                    ".text": {
                        opacity: "1"
                    },

                    "& .hover": { opacity: 1, transition: ".5s" },
                    "& .default": { opacity: 0, transition: ".5s" },
                    "& .filter": { opacity: 1, transition: ".5s" },
                },
                "&:hover .boxShadow": {
                    //   borderColor: "#FD8489",
                    transform: "translate(-3px, -5px)",
                    boxShadow: "-5px 5px 0px 0px #FD8489",
                    [theme.breakpoints.down("sm")]: {
                        transform: "translate(-2px, -4px)",
                        boxShadow: "-5px 5px 0px 0px " + "#FD8489",
                    },
                },
                position: "relative",
                // aspectRatio: "1",
                // '@supports not (aspect-ratio: 1/1)': {
                //     paddingTop: "100%",
                // },
            }}
        >
            <Box
                className="boxShadow"
                position={"relative"}
                sx={{
                    background: `center / cover url('./images/領域小組縮圖-${data}@2x.png')`,
                    width: "100%",
                    paddingTop: "100%",
                    boxShadow: "-5px 5px 0px 0px rgba(0, 0, 0, 0.85)",
                    borderRadius: "50%",
                }}
            >

                <Box
                    width={"100%"}
                    height={"100%"}
                    className="filter"
                    position={"absolute"}
                    top={"0"}
                    sx={{
                        background: "rgba(0, 0, 0, 0.15)",
                        opacity: "0",
                        borderRadius: "50%",
                        border: "3px solid #F3E0C8"
                    }}
                ></Box>
                <Box
                    sx={{
                        transition: ".5s",
                        transform: "translate(-50%,-50%)",
                        opacity: isMobile ? "1" : "0"
                    }}
                    position={"absolute"}
                    left={"50%"}
                    top={isMobile ? "120%" : "50%"}
                    className="text"
                    width={data == 3 ? "90%" : data == 6 ? "80%" : "70%"}
                >
                    <img style={{
                        maxWidth: "100%"
                    }} src={`./images/領域小組標題${data}.png`} /*className="default"*/ />
                </Box>
            </Box>
        </Button>
    )
})(({ theme }) => ({
    "&.eventButton.image": {
        backgroundSize: "100%",
        "@supports not (aspect-ratio: 1.5)": {
            minHeight: "240px"
        },
        position: "relative",
        transition: theme.transitions.create(["transform", "box-shadow", "border-color"]),
        transform: "translate(-7px, -9px)",

        borderRadius: "50%",
        //  border: "3px solid #000",
        [theme.breakpoints.between("md", "lg")]: {
            maxWidth: "300px",
        },

    }
}));


const BG = "./images/fin_Background.jpg";

interface TeamDialogProps {
    team: Team;
    open: boolean;
    onClose: () => void;
}
export function TeamDialog(props: TeamDialogProps) {
    const { open, onClose, team } = props;

    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    //手機版選單
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(menuAnchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const scrollerProps = {
        containerId: "team-scroller",
        spy: true,
        smooth: true,
        duration: 500,
        offset: 0,
        activeClass: 'active',
    }

    return (<>
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen={isMobile}
            sx={{ cursor: "none" }}
            PaperProps={{
                sx: {
                    borderRadius: "5px",
                    border: "4px solid #000",
                    background: "var(--primary-gradient-95, linear-gradient(180deg, #FFF8E3 0%, rgba(255, 255, 255, 0.00) 11.56%), rgba(253, 132, 137, 0.95))",
                    backgroundSize: "3200px auto",
                    backgroundPosition: "center",
                    minHeight: "calc(100vh - 64px)",

                }
            }}>
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" fontWeight="bold" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                        {team.title}
                    </Typography>
                    <WindowButton onClose={onClose} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <StyledCard variant="outlined">
                    <CardContent sx={{ padding: { xs: '12px 15px', lg: '24px 32px' } }}>
                        {team.videoUrl &&
                            <Box position="relative" pt="56.25%" width='100%' sx={{ marginBottom: '12px' }}>
                                <Box position="absolute" bgcolor="background.default" style={{ inset: 0 }}>
                                    <ReactPlayer url={team.videoUrl ?? 'https://www.youtube.com/embed/kmdtwgsc-YY'} width="100%" height="100%" />
                                </Box>
                            </Box>
                        }

                        {(team.imageUrl && !team.videoUrl) &&
                            <img src={team.imageUrl} style={{ maxHeight: "80vh", maxWidth: "100%", marginBottom: '12px' }} />
                        }

                        <Box padding={{ xs: '12px 0px', lg: '6px 24px' }}>

                            <Stack spacing={1}>
                                <Typography variant="h5" gutterBottom fontWeight={"bold"}>
                                    召集人:{team.convener}
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    助教：{team.assistant}
                                </Typography>
                                {team.intro}
                            </Stack>
                        </Box>
                        {/*
                                    <Box position="absolute" bottom="4px" right="16px" display="inline-flex">
                                        <Typography color="grey.400">{t("teams.other.createTime") + format(new Date(team.createTime), "yyyy/MM/dd")}</Typography>
                                    </Box>
                                    */}
                    </CardContent>
                </StyledCard>
            </DialogContent>
        </Dialog >
    </>)
}

const StyledCard = styled(Card)(({ theme, variant }) => ({
    "&.MuiCard-root": variant === "outlined" && {
        border: "3px solid " + theme.palette.grey[800],
        borderRadius: "12px",
    },
    overflow: "unset",
    position: "relative"
}));