import { Add, Delete, Edit, Preview, Restore } from "@mui/icons-material";
import { Box, Button, Link, Paper, Stack, styled, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridEnrichedColDef, GridRenderCellParams, GridRowParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from "@mui/x-data-grid";
import format from "date-fns/format";
import * as React from "react";
import { SubTitle } from "components/responsive-components";
import { zhTWGrid } from "locale/dataGrid";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { TidbitEditDialog, TidbitEditDialogProps } from "./TidbitEditDialog";
import { useGetApiTidbitQuery } from "services/tidbitApi";
import { Tidbit } from "classes/tidbit";
import TidbitDeleteDialog from "./TidbitDeleteDialog";
import TidbitRestoreDialog from "./TidbitRestoreDialog";
import { useUser } from "store";

interface TidbitManagePageProps {

}
export default function TidbitManagePage(props: TidbitManagePageProps) {
    const { } = props;
    const user = useUser();

    //獲取花絮資料
    const { data: tidbitList, isLoading, refetch } = useGetApiTidbitQuery(undefined, { refetchOnMountOrArgChange: true });

    //控制編輯&新增彈框
    const [openEdit, setOpenEdit] = React.useState<boolean>(false);
    const [openInsert, setOpenInsert] = React.useState<boolean>(false);
    const [editInfo, setEditInfo] = React.useState<Tidbit>();
    function handleOpenEdit(info: Tidbit) {
        setEditInfo(info);
        setOpenEdit(true);
    }
    function handleOpenInsert() {
        setOpenInsert(true);
    }

    const handleSave = () => {
        setOpenEdit(false);
        setOpenInsert(false);
        refetch();
    };

    //控制刪除&還原彈框
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [openRestore, setOpenRestore] = React.useState<boolean>(false);
    const [targetInfo, setTargetInfo] = React.useState<Tidbit>();
    const [deletedInfo, setDeletedInfo] = React.useState<Tidbit>();
    function handleOpenDelete(info: Tidbit) {
        setTargetInfo(info);
        setOpenDelete(true);
    };
    const handleDelete = () => {
        setOpenDelete(false);
        let info = targetInfo;
        setDeletedInfo(info);
        setTargetInfo(undefined);
        refetch();
    };
    const handleRestore = () => {
        setOpenRestore(false);
        setDeletedInfo(undefined);
        refetch();
    };

    if (!user?.userId) return null;
    return (<>
        <SubTitle
            icon='circle'
            text={'花絮'}
        />
        <Stack direction="row" spacing={1} mb={2} >
            <Button color="secondary" onClick={handleOpenInsert} startIcon={<Add />}>新增花絮</Button>
            {deletedInfo &&
                <Button color="secondary" onClick={() => setOpenRestore(true)} startIcon={<Restore />}>
                    還原
                </Button>
            }
        </Stack>
        <Paper variant="outlined">
            <TidbitList
                dataList={tidbitList?.data as Tidbit[] ?? []}
                loading={isLoading}
                handleOpenEdit={handleOpenEdit}
                handleOpenDelete={handleOpenDelete}
            />
        </Paper>
        {/*<TidbitsDialog open={open} tidbits={currentInfo ? [randomNewest(), currentInfo, randomOldest()]: []} onClose={handleClose} />*/}
        <EditDialog open={openEdit} initData={editInfo} onClose={() => setOpenEdit(false)} onSave={handleSave} />
        <InsertDialog open={openInsert} onClose={() => setOpenInsert(false)} onSave={handleSave} />
        {targetInfo &&
            <DeleteDialog open={openDelete} data={targetInfo} onClose={() => { setOpenDelete(false); setTargetInfo(undefined); }} onDelete={handleDelete} />
        }
        {deletedInfo &&
            <RestoreDialog open={openRestore} data={deletedInfo} onClose={() => { setOpenRestore(false); }} onRestore={handleRestore} />
        }
    </>)
}
const EditDialog = styled(TidbitEditDialog)({});
const InsertDialog = styled(TidbitEditDialog)({});
const DeleteDialog = styled(TidbitDeleteDialog)({});
const RestoreDialog = styled(TidbitRestoreDialog)({});

interface GridDataRow extends Tidbit { }
interface TidbitListProps {
    dataList: GridDataRow[];
    loading?: boolean;
    handleOpenEdit: (info: Tidbit) => void;
    handleOpenDelete: (info: Tidbit) => void;
}
function TidbitList(props: TidbitListProps) {
    const { dataList, loading, handleOpenEdit, handleOpenDelete } = props;

    const columns: GridEnrichedColDef<GridDataRow>[] = [
        {
            field: 'id',
            headerName: '#',
            filterable: false,
            width: 70,
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
        },
        {
            field: 'imageUrl',
            headerName: '圖片',
            width: 95,
            renderCell: ({ value }: GridRenderCellParams<string>) => {
                if (value && value != "")
                    return <img width="72.5px" height="50px" src={value} style={{ objectFit: "cover" }} />
                else
                    return "無"
            },
        },
        {
            field: 'title',
            headerName: '標題',
            flex: 1,
            minWidth: 150,
            //valueGetter: ({ row: { chName } }: GridValueGetterParams<string, GridDataRow>) => chName,
        },
        {
            field: 'url',
            headerName: '連結',
            minWidth: 220,
            renderCell: ({ value }: GridRenderCellParams<string>) => {
                if (value && value != "")
                    return <Link href={value} target='_blank'>{value}</Link>
                else
                    return "無"
            },
        },
        {
            field: 'createTime',
            headerName: '新增時間',
            type: 'dateTime',
            width: 180,
            valueGetter: ({ value }: GridValueGetterParams<string>) => value ? format(new Date(value), "yyyy/MM/dd HH:mm:ss") : "",
        },
        {
            field: 'actions',
            type: 'actions',
            width: 120,
            getActions: ({ row }: GridRowParams<GridDataRow>) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title={"編輯"}>
                            <Edit />
                        </Tooltip>
                    }
                    onClick={() => handleOpenEdit(row)}
                    label={"編輯"}
                />,
                <GridActionsCellItem
                    icon={<Delete color="error" />}
                    onClick={() => handleOpenDelete(row)}
                    label={"刪除"}
                    sx={{
                        color: theme => theme.palette.error.main
                    }}
                    showInMenu
                />,
            ]
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <DataGrid rows={dataList} columns={columns}
            pageSize={10}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={zhTWGrid}
            loading={loading}
        />
    )
}