import { Box } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";


export default function CustomCursor(props: { children: ReactNode; }) {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isHover, setIsHover] = useState(true);
    const handleMouseMove = (event: MouseEvent) => {
        let target = event.target as HTMLElement
        if (target?.nodeName == "IFRAME") setIsHover(false)
        else setIsHover(true)
        setPosition({ x: event.clientX, y: event.clientY });
    };

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove)
        return () => {
            window.removeEventListener("mousemove", handleMouseMove)
        };
    }, [])

    return (<>
        <Box
            position={"relative"}
            sx={{
                "& *": {
                    cursor: "none!important",
                }
            }}
        >
            <Box
            >
                {props.children}
            </Box>
            <Box
                className="cursor"
                sx={{
                    pointerEvents: "none",
                    visibility: isHover ? "visibile" : "hidden",
                    position: 'fixed',
                    top: (position.y - 35) + 'px',
                    left: (position.x - 35) + 'px',
                    width: '75px', // Adjust the width of the image as needed
                    height: '75px', // Adjust the height of the image as needed
                    zIndex: "9999",
                    display: 'none',
                    '@media (hover: hover)': {
                        display: 'block',
                    },
                }}
            >
                <img
                    style={{ width: "100%", pointerEvents: "none", }}
                    src="./images/閃亮游標hover.png"
                    alt="Following Image"

                />
            </Box>

        </Box >
    </>)
}