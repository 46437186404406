import { enhancedApi as loginApi } from 'services/loginApi';
import { store } from 'store';

export async function getIsLoginLoader() {
    const promise = store.dispatch(loginApi.endpoints.getApiLoginState.initiate());
    try {
        const response = await promise.unwrap();
        return response;
    } catch (e) {
        return null;
    } finally {
        promise.unsubscribe()
    }
}

export async function logoutLoader() {
    const promise = store.dispatch(loginApi.endpoints.getApiLoginLogout.initiate());
    try {
        const response = await promise.unwrap();
        return response;
    } catch (e) {
        return null;
    } finally {
        promise.unsubscribe()
    }
}