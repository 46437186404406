import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { animateScroll, scroller } from 'react-scroll';
import { useUserAndPageTracking } from './components/GoogleAnalytics';
import "./Root.css"

export default function Root() {
    //初始化GA4
    //useUserAndPageTracking();
    //控制頁面切換時的位置
    const pathName = useLocation().pathname;
    const hash = useLocation().hash;
    const [init, setInit] = React.useState(false);
    React.useEffect(() => {
        //初始化根據hash scroll位置 延遲0.5秒避免scroll位置錯誤
        if (!init) {
            if (hash) {
                setTimeout(() => {
                    scroller.scrollTo(hash.split('#')[1], {
                        //smooth: true,
                        offset: -30,
                    });
                }, 500)
            }
            setInit(true)
            return;
        }

        //已初始化根據hash滾動
        if (hash)
            scroller.scrollTo(hash.split('#')[1], {
                //smooth: true,
                offset: -30,
            });
        else
            window.scrollTo({ top: 0 })
    }, [pathName, hash])
    return <Outlet />;
}