import { Box, Button, useMediaQuery, useTheme } from "@mui/material";

export interface WindowButtonProps {
    onMinimize?: () => void
    onFullscreen?: () => void
    onClose?: () => void
}

export function WindowButton(props: WindowButtonProps) {
    const { onMinimize, onFullscreen, onClose } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const buttonStyle = {
        minWidth: "0",
        p: "5px",
        alignItems: "flex-end"
    }
    return (
        <Box display={"flex"} gap={1} position={"absolute"} top={isMobile ? "7px" : "10px"} right={"1rem"} sx={{ transform: isMobile ? "scale(0.5)" : "scale(0.7)" }}>
            <Button sx={buttonStyle} onClick={() => { if (onMinimize) onMinimize() }} disabled>
                <img src="./images/Minimize.svg" />
            </Button>
            <Button sx={buttonStyle} onClick={() => { if (onFullscreen) onFullscreen() }} disabled>
                <img src="./images/Fullscreen.svg" />
            </Button>
            <Button sx={buttonStyle} onClick={() => { if (onClose) onClose() }} disabled>
                <img src="./images/Close.svg" />
            </Button>
        </Box >
    )
}