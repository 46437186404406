import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetApiTextEditorByNameQuery } from "services/textEditorApi";

export interface EditorSectionProps {
    name: string
}

export function EditorSection(props: EditorSectionProps) {
    const { name } = props;
    const [html, setHtml] = useState("");
    const { isLoading, data, isSuccess } = useGetApiTextEditorByNameQuery({ name: name })
    useEffect(() => {
        if (data?.data?.content) setHtml(data?.data.content)
    }, [data])
    return (<>
        {isLoading ? <CircularProgress /> :
            <Box sx={{
                "&>div": {
                    textAlign: "initial"
                }
            }}>
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </Box>
        }
    </>)
}