import App from 'App';
import Loader from 'components/Loader';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from 'reportWebVitals';
import { store } from 'store';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Suspense fallback={<Loader />}>
        <Provider store={store}>
            <App />
        </Provider >
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
