import { NonNullableKeys } from "components/Utils";
import { OutputEvent } from "services/eventApi";


export type OLEvent = NonNullableKeys<OutputEvent>;

export enum EventType {
    openlabs = 0,
    // talk,
    seminar
}