import { Close } from "@mui/icons-material";
import { Backdrop, Box, Button, ButtonBase, Card, CardContent, CardMedia, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Menu, MenuItem, Paper, Select, selectClasses, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import format from "date-fns/format";
import * as React from "react";
import { Project } from "classes/project";
import { scroller, Element, Link as ScrollLink, LinkProps as ScrollLinkProps } from 'react-scroll'
import ReactPlayer from "react-player";
import Linkify from 'react-linkify';

const BG = "./images/fin_Background.jpg";

interface ProjectsDialogProps {
    courseTitle: string;
    projects: Project[];
    open: boolean;
    onClose: () => void;
}
export default function ProjectsDialog(props: ProjectsDialogProps) {
    const { courseTitle, projects, open, onClose } = props;
    let notNull = courseTitle !== null && !!projects;

    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    //手機版選單
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(menuAnchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const scrollerProps = {
        containerId: "project-scroller",
        spy: true,
        smooth: true,
        duration: 500,
        offset: 0,
        activeClass: 'active',
    }

    return (<>
        <Dialog open={open && notNull} onClose={onClose} maxWidth="lg" fullWidth fullScreen={isMobile}
            PaperProps={{ sx: { background: `linear-gradient(180deg, rgb(250 239 226 / 50%) 10%, transparent 60%), url('${BG}')`, backgroundSize: "3200px auto", backgroundPosition: "center", minHeight: "calc(100vh - 64px)" } }}>
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" fontWeight="bold" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                        {`#${courseTitle}`}
                    </Typography>
                    <IconButton onClick={onClose} sx={{ mr: -1 }}><Close /></IconButton>
                </Box>
                {isTablet &&
                    <MenuButton
                        aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        onClick={handleMenuClick}
                    >
                        {"專題選單 >>"}
                    </MenuButton>
                }
                <StyledMenu
                    anchorEl={menuAnchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 60,
                        horizontal: 'center'
                    }}
                >
                    <Stack spacing={2}>
                        {[...projects].map((project) => (
                            <Box key={project.id} sx={{
                                padding: '2px',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.selected,
                                    color: theme.palette.primary.main,
                                    cursor: 'pointer'
                                },
                                [`& a.active`]: {
                                    color: theme.palette.primary.main,
                                    [`& .MuiTypography-root`]: {
                                        //fontWeight: 'bold',
                                    },
                                }
                            }}>
                                <ScrollLink {...scrollerProps} to={project.id} onClick={handleMenuClose}>
                                    <Typography variant='subtitle1' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        {project.workTitle != '' ? project.workTitle : project.workEnTitle}
                                    </Typography>
                                </ScrollLink>
                            </Box>
                        ))}
                    </Stack>
                </StyledMenu>
            </DialogTitle>
            <DialogContent sx={{ display: "flex" }}>
                {!isTablet &&
                    <ScrollerArea>
                        <Stack spacing={2}>
                            {[...projects].map((project) => (
                                <Box key={project.id} sx={{
                                    padding: '2px',
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.selected,
                                        color: theme.palette.primary.main,
                                        cursor: 'pointer'
                                    },
                                    [`& a.active`]: {
                                        color: theme.palette.primary.main,
                                        [`& .MuiTypography-root`]: {
                                            //fontWeight: 'bold',
                                        },
                                    }
                                }}>
                                    <ScrollLink {...scrollerProps} to={project.id}>
                                        <Typography variant='subtitle1' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                            {project.workTitle != '' ? project.workTitle : project.workEnTitle}
                                        </Typography>
                                    </ScrollLink>
                                </Box>
                            ))}
                        </Stack>
                    </ScrollerArea>
                }
                <Stack id='project-scroller' flex="1 1" overflow="auto" spacing={2} padding='0px 12px'>
                    {[...projects].map((project) => (
                        <Element name={project.id} key={project.id}>
                            <ProjectCard variant="outlined">
                                <CardContent sx={{ padding: { xs: '12px 15px', lg: '24px 32px' } }}>
                                    {(project.videoUrl) &&
                                        <Box position="relative" pt="56.25%" width='100%' sx={{ marginBottom: '12px' }}>
                                            <Box position="absolute" bgcolor="background.default" style={{ inset: 0 }}>
                                                <ReactPlayer url={project.videoUrl ?? 'https://www.youtube.com/embed/kmdtwgsc-YY'} width="100%" height="100%" />
                                            </Box>
                                        </Box>
                                    }

                                    {(project.imageUrl && !project.videoUrl) &&
                                        <img src={project.imageUrl} style={{ maxHeight: "80vh", maxWidth: "100%", marginBottom: '12px' }} />
                                    }

                                    <Box sx={{
                                        color: theme.palette.primary.contrastText,
                                        backgroundColor: theme.palette.primary.dark,
                                        borderRadius: '6px',
                                        padding: { xs: '6px', lg: '6px 24px' }
                                    }}>
                                        <Typography variant={isMobile ? 'h6' : "h4"}>
                                            {project.workTitle != '' ? project.workTitle : project.workEnTitle}
                                        </Typography>
                                    </Box>

                                    <Box padding={{ xs: '12px 0px', lg: '6px 24px' }}>
                                        {(project.workTitle && project.workEnTitle) &&
                                            <Typography variant="h6" gutterBottom>
                                                {project.workEnTitle}
                                            </Typography>
                                        }
                                        {project.serialNum &&
                                            <Typography variant="subtitle2" gutterBottom>
                                                作品編號：{project.serialNum}
                                            </Typography>
                                        }
                                        {project.courseTitle &&
                                            <Typography variant="subtitle2" gutterBottom>
                                                課程名稱：{project.courseTitle}
                                            </Typography>
                                        }
                                        {project.teachers &&
                                            <Typography variant="subtitle2" gutterBottom>
                                                師資：{project.teachers}
                                            </Typography>
                                        }
                                        {project.authors &&
                                            <Linkify>
                                                <Box display='flex' >
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        作者：
                                                    </Typography>
                                                    <Typography variant="subtitle2" gutterBottom whiteSpace='break-spaces'>
                                                        {project.authors}
                                                    </Typography>
                                                </Box>
                                            </Linkify>
                                        }
                                        {project.workIntro &&
                                            <Linkify>
                                                <Typography variant="subtitle2" gutterBottom sx={{ whiteSpace: 'break-spaces' }}>
                                                    {project.workIntro}
                                                </Typography>
                                            </Linkify>
                                        }
                                    </Box>
                                    {/*
                                    <Box position="absolute" bottom="4px" right="16px" display="inline-flex">
                                        <Typography color="grey.400">{t("projects.other.createTime") + format(new Date(project.createTime), "yyyy/MM/dd")}</Typography>
                                    </Box>
                                    */}
                                </CardContent>
                            </ProjectCard>
                        </Element>
                    ))}
                </Stack>
            </DialogContent>
        </Dialog>
    </>)
}

const ProjectCard = styled(Card)(({ theme, variant }) => ({
    "&.MuiCard-root": variant === "outlined" && {
        border: "3px solid " + theme.palette.grey[800],
        borderRadius: "12px",
    },
    overflow: "unset",
    position: "relative"
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
    maxHeight: '80vh',
    '& .MuiPaper-root': {
        padding: "12px",
        border: "3px solid " + theme.palette.grey[800],
        borderRadius: "12px",
        boxShadow: "-3px 3px " + theme.palette.grey[800],
    },
}));

const MenuButton = styled(ButtonBase)(({ theme }) => ({
    width: '100%',
    color: theme.palette.grey[800],
    textDecoration: "none",
    padding: "6px",
    border: "3px solid " + theme.palette.grey[800],
    borderRadius: "12px",
    boxShadow: "-3px 3px " + theme.palette.grey[800],
    background: theme.palette.background.paper,
}));

const ScrollerArea = styled(Paper)(({ theme }) => ({
    padding: '24px',
    maxWidth: 254,
    overflow: 'auto',
    marginRight: '24px',

    textDecoration: "none",
    //padding: "13px 55px",
    border: "3px solid " + theme.palette.grey[800],
    borderRadius: "12px",
    boxShadow: "-3px 3px " + theme.palette.grey[800],
}));