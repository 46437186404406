import { Box, Stack, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

interface ErrorPageProps {
    fullHeight?: boolean;
}
export default function ErrorPage(props: ErrorPageProps) {
    const { fullHeight } = props;
    return (
        <Box display='flex' sx={{ height: fullHeight ? '100vh' : 'calc(100vh - 56px - 100px)' }}>
            <Stack alignItems='center' spacing={2} m='auto'>
                <Typography variant='h2'>
                    <ErrorOutline fontSize="inherit" />
                </Typography>
                <Typography variant='h5' align='center' fontWeight="bold">
                    錯誤，無法顯示此頁面。
                </Typography>
            </Stack>
        </Box>
    )
}