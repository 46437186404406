import { Box, Button, styled, Typography } from "@mui/material";
import * as React from "react";
import NewExhibitList from "./NewExhibitList";
import ExhibitManagePage from "./ExhibitManagePage";
import { ExhibitType } from "classes/exhibit";

interface ExhibitIndexProps {
    exhibitType?: ExhibitType | -100;
}
export default function ExhibitIndex(props: ExhibitIndexProps) {
    const { exhibitType } = props;
    const [code, setCode] = React.useState<number>(exhibitType ?? -1);
    React.useEffect(() => {
        setCode(exhibitType ?? -1)
    }, [exhibitType])

    const buttonList = [
        { nameKey: "manage.title.exhibits.technology", code: ExhibitType.technology },
        { nameKey: "manage.title.exhibits.culture", code: ExhibitType.culture },
        { nameKey: "manage.title.exhibits.health", code: ExhibitType.health },
        { nameKey: "manage.title.exhibits.newList", code: -100 }
    ];
    function handleClick(code: number) {
        setCode(code);
    }

    if (code == ExhibitType.technology || code == ExhibitType.culture || code == ExhibitType.health)
        return (
            <ExhibitManagePage exhibitType={code} />
        )
    else if (code == -100)
        return (
            <NewExhibitList />
        )
    else return (<>
        <Box display="flex" flexWrap='wrap'>
            {buttonList.map((item) => (
                <BigButton key={item.nameKey} onClick={() => handleClick(item.code)}>
                    <Typography>{item.nameKey}</Typography>
                </BigButton>))}
        </Box>
    </>)
}

const BigButton = styled(Button)(({ theme }) => ({

}));