import { Backdrop, Button, CircularProgress, Dialog, DialogActions } from "@mui/material";
import * as React from "react";
import { OLEvent, EventType } from "classes/event";
import { usePostApiEventRestoreByEventIdMutation } from "services/eventApi";

export interface EventRestoreDialogProps {
    open: boolean;
    type: EventType;
    data: OLEvent;
    onClose?: () => void;
    onRestore?: () => void;
}
export default function EventRestoreDialog(props: EventRestoreDialogProps) {
    const { open, type, data, onClose, onRestore } = props;

    const [restoreEvent, { isLoading }] = usePostApiEventRestoreByEventIdMutation();
    async function handleRestore() {
        try {
            let result = await restoreEvent({ eventId: data.id }).unwrap();
            if (!result.isSuccess)
                throw new Error("還原資料失敗");
            if (onRestore) onRestore();
        }
        catch (e) {
            console.error(e);
        }
    }
    return (<>
        <Dialog open={open} onClose={onClose}>
            <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                <span style={{ fontWeight: 'bold' }}>
                    還原
                </span>
            </div>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button autoFocus onClick={handleRestore}>是</Button>
                <Button onClick={onClose}>否</Button>
            </DialogActions>
        </Dialog>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </>)
}