import { Box, Button, styled, Typography } from "@mui/material";
import * as React from "react";
import NewProjectList from "./NewProjectList";
import ProjectManagePage from "./ProjectManagePage";

interface ProjectIndexProps {
    type?: "manage" | "new";
}
export default function ProjectIndex(props: ProjectIndexProps) {
    const { type } = props;
    const [code, setCode] = React.useState<string>(type ?? "");
    React.useEffect(() => {
        setCode(type ?? "");
    }, [type])

    const buttonList = [
        { nameKey: "資料管理", code: "manage" },
        { nameKey: "匯入資料", code: "new" }
    ];
    function handleClick(code: string) {
        setCode(code);
    }

    if (code == "manage")
        return (
            <ProjectManagePage />
        )
    else if (code == "new")
        return (
            <NewProjectList />
        )
    else return (<>
        <Box display="flex" flexWrap='wrap'>
            {buttonList.map((item) => (
                <BigButton key={item.nameKey} onClick={() => handleClick(item.code)}>
                    <Typography>{item.nameKey}</Typography>
                </BigButton>))}
        </Box>
    </>)
}

const BigButton = styled(Button)(({ theme }) => ({

}));