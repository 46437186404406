import { Add, Delete, Edit, Preview, Restore } from "@mui/icons-material";
import { Box, Button, Paper, Stack, styled, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridEnrichedColDef, GridRenderCellParams, GridRowParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from "@mui/x-data-grid";
import format from "date-fns/format";
import * as React from "react";
import { SubTitle } from "components/responsive-components";
import { zhTWGrid } from "locale/dataGrid";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ExhibitEditDialog, ExhibitEditDialogProps } from "./ExhibitEditDialog";
import { useGetApiExhibitQuery } from "services/exhibitApi";
import { Exhibit, ExhibitType } from "classes/exhibit";
import ExhibitDeleteDialog from "./ExhibitDeleteDialog";
import ExhibitRestoreDialog from "./ExhibitRestoreDialog";
import { useUser } from "store";

interface ExhibitManagePageProps {
    exhibitType: ExhibitType;
}

export enum ExhibitsTitle {
    "「科技智慧永續」列表" = 0,
    "「文化文明永續」列表" = 1,
    "「生命健康永續」列表" = 2,
    "匯入「展覽作品」資料" = 3
}

export default function ExhibitManagePage(props: ExhibitManagePageProps) {
    const { exhibitType } = props;
    const user = useUser();

    //獲取活動場次資料
    const { data: exhibitList, isFetching: isLoading, refetch } = useGetApiExhibitQuery({ type: exhibitType }, { refetchOnMountOrArgChange: true });

    //控制編輯&新增彈框
    const [openEdit, setOpenEdit] = React.useState<boolean>(false);
    const [openInsert, setOpenInsert] = React.useState<boolean>(false);
    const [editInfo, setEditInfo] = React.useState<Exhibit>();
    function handleOpenEdit(info: Exhibit) {
        setEditInfo(info);
        setOpenEdit(true);
    }
    function handleOpenInsert() {
        setOpenInsert(true);
    }

    const handleSave = () => {
        setOpenEdit(false);
        setOpenInsert(false);
        refetch();
    };

    //控制刪除&還原彈框
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [openRestore, setOpenRestore] = React.useState<boolean>(false);
    const [targetInfo, setTargetInfo] = React.useState<Exhibit>();
    const [deletedInfo, setDeletedInfo] = React.useState<Exhibit>();
    function handleOpenDelete(info: Exhibit) {
        setTargetInfo(info);
        setOpenDelete(true);
    };
    const handleDelete = () => {
        setOpenDelete(false);
        let info = targetInfo;
        setDeletedInfo(info);
        setTargetInfo(undefined);
        refetch();
    };
    const handleRestore = () => {
        setOpenRestore(false);
        setDeletedInfo(undefined);
        refetch();
    };


    if (!user?.userId) return null;
    return (<>
        <SubTitle
            icon='circle'
            text={ExhibitsTitle[exhibitType]}
        />
        <Stack direction="row" spacing={1} mb={2} >
            <Button color="secondary" onClick={handleOpenInsert} startIcon={<Add />}>新增</Button>
            {deletedInfo &&
                <Button color="secondary" onClick={() => setOpenRestore(true)} startIcon={<Restore />}>
                    復原
                </Button>
            }
        </Stack>
        <Paper variant="outlined">
            <ExhibitList
                dataList={exhibitList?.data as Exhibit[] ?? []}
                loading={isLoading}
                handleOpenEdit={handleOpenEdit}
                handleOpenDelete={handleOpenDelete}
            />
        </Paper>
        <EditDialog open={openEdit} type={exhibitType} initData={editInfo} onClose={() => setOpenEdit(false)} onSave={handleSave} />
        <InsertDialog open={openInsert} type={exhibitType} onClose={() => setOpenInsert(false)} onSave={handleSave} />
        {targetInfo &&
            <DeleteDialog open={openDelete} type={exhibitType} data={targetInfo} onClose={() => { setOpenDelete(false); setTargetInfo(undefined); }} onDelete={handleDelete} />
        }
        {deletedInfo &&
            <RestoreDialog open={openRestore} type={exhibitType} data={deletedInfo} onClose={() => { setOpenRestore(false); }} onRestore={handleRestore} />
        }
    </>)
}
const EditDialog = styled(ExhibitEditDialog)({});
const InsertDialog = styled(ExhibitEditDialog)({});
const DeleteDialog = styled(ExhibitDeleteDialog)({});
const RestoreDialog = styled(ExhibitRestoreDialog)({});

interface GridDataRow extends Exhibit { }
interface ExhibitListProps {
    dataList: GridDataRow[];
    loading?: boolean;
    handleOpenEdit: (info: Exhibit) => void;
    handleOpenDelete: (info: Exhibit) => void;
}
function ExhibitList(props: ExhibitListProps) {
    const { dataList, loading, handleOpenEdit, handleOpenDelete } = props;

    const columns: GridEnrichedColDef<GridDataRow>[] = [
        /*{
            field: 'id',
            headerName: t('manage.exhibits.options.id'),
            filterable: false,
            width: 70,
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
        },*/
        {
            field: 'serialNum',
            headerName: '作品編號',
            width: 120,
        },
        {
            field: 'imageUrl',
            headerName: '圖片',
            width: 95,
            renderCell: ({ value }: GridRenderCellParams<string>) => {
                if (value && value != "")
                    return <img width="72.5px" height="50px" src={value} style={{ objectFit: "cover" }} />
                else
                    return "無"
            },
        },
        {
            field: 'courseTitle',
            headerName: '課程名稱',
            flex: 1,
            minWidth: 150,
            //valueGetter: ({ row: { chName } }: GridValueGetterParams<string, GridDataRow>) => chName,
        },
        {
            field: 'workTitle',
            headerName: '作品名稱',
            flex: 1,
            minWidth: 150,
            //valueGetter: ({ row: { chName } }: GridValueGetterParams<string, GridDataRow>) => chName,
        },
        {
            field: 'createTime',
            headerName: '新增時間',
            type: 'dateTime',
            width: 180,
            valueGetter: ({ value }: GridValueGetterParams<string>) => value ? format(new Date(value), "yyyy/MM/dd HH:mm:ss") : "",
        },
        {
            field: 'actions',
            type: 'actions',
            width: 120,
            getActions: ({ row }: GridRowParams<GridDataRow>) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title={"編輯"}>
                            <Edit />
                        </Tooltip>
                    }
                    onClick={() => handleOpenEdit(row)}
                    label={"編輯"}
                />,
                <GridActionsCellItem
                    icon={<Delete color="error" />}
                    onClick={() => handleOpenDelete(row)}
                    label={"刪除"}
                    sx={{
                        color: theme => theme.palette.error.main
                    }}
                    showInMenu
                />,
            ]
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <DataGrid rows={dataList} columns={columns}
            pageSize={10}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={zhTWGrid}
            loading={loading}
        />
    )
}