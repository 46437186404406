import * as React from 'react';
import { Box, BoxProps, Button, Container, Divider, Fade, IconButton, InputAdornment, OutlinedInput, outlinedInputClasses, Paper, Popper, Stack, styled, TextField, Typography } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { HelpOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { NoOutlineButton } from 'components/responsive-components/Button';
import { useDispatchWithType } from 'store';
import { OutputUser, usePostApiLoginLoginMutation } from 'services/loginApi';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { login as loginUser } from 'store/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const HomeIcon = "images/logo2.png";
const BG = "images/fin_Background.jpg";
export const Window = styled(({ children, ...otherProps }: BoxProps) => (
    <Box position="fixed" top={0} right={0} bottom={0} left={0}>
        <Box position="fixed" sx={{ inset: 0, background: `url("${BG}")`, opacity: 0.4 }} />
        <Box height="100%" minHeight="80vh" display="flex" justifyContent="center" alignItems="center">
            <Box position="relative" maxWidth="444px" maxHeight="calc(100% - 64px)" borderRadius={1} m={4} boxShadow={24} {...otherProps}>
                {children}
            </Box>
        </Box>
    </Box>
))({});


type formData = {
    account: string;
    password: string;
}
export default function Login() {
    const navigate = useNavigate();
    const { search } = useLocation();
    let query = new URLSearchParams(search);

    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const { register, handleSubmit } = useForm<formData>();
    const [errorMessage, setErrorMessage] = React.useState<string>("");

    const [login, { isLoading }] = usePostApiLoginLoginMutation();
    const dispatch = useDispatchWithType();
    async function onLoginSubmit(data: formData) {
        setErrorMessage("");
        if (data.account == "") {
            setErrorMessage("帳號不得為空");
            return;
        }
        if (data.password == "") {
            setErrorMessage("密碼不得為空");
            return;
        }
        try {
            let result = await login({ loginModel: { ...data } }).unwrap();
            if (result.isSuccess) {
                dispatch(loginUser(result?.data as OutputUser));
                navigate(query.get('redirect') ?? "/");
            }
            else {
                setErrorMessage(result?.message ?? "");
            }
        }
        catch (error) {
            setErrorMessage("Unconnect");
            console.error("發生錯誤", error)
        }
    }

    function handleClickPortalButton() {
        if (query.get('redirect')) {
            window.location.href = `${window.location.origin}/nycuauth?redirectPath=${query.get('redirect')}`;
        }
        else {
            window.location.href = `${window.location.origin}/nycuauth`;
        }
    }

    return (
        <Window bgcolor="#ffffffa1">
            <Container sx={{ flex: "1 0", marginBlock: { md: "auto" }, paddingBlock: { xs: 5, md: 6, lg: 10 } }}>
                    <Stack spacing={{ xs: 4, md: 4.5, lg: 5 }}>
                        <Typography variant="h4" color="primary" fontWeight="bold" align="center" sx={{ wordBreak: "keep-all", lineHeight: "inherit" }}>
                            2024 NYCU Open Labs
                        </Typography>
                        <Stack spacing={1.25} alignItems="center" px={2.25}
                            component="form" onSubmit={handleSubmit(onLoginSubmit)}
                        >
                            <Typography variant="subtitle1" color="text.secondary" align="center">
                                帳號密碼登入
                            </Typography>
                            <Box display="grid" gap={1} gridTemplateColumns="36px 1fr" width="100%" maxWidth={{ xs: "100%", sm: "280px" }}>
                                <Typography mt="5px">
                                    帳號
                                </Typography>
                                <OutlinedInput
                                    disabled={isLoading}
                                    {...register("account")}
                                    sx={{ [`& .${outlinedInputClasses.input}`]: { padding: "6px 10px" } }} />
                            </Box>
                            <Box display="grid" gap={1} gridTemplateColumns="36px 1fr" width="100%" maxWidth={{ xs: "100%", sm: "280px" }}>
                                <Typography mt="5px">
                                    密碼
                                </Typography>
                                <OutlinedInput
                                    disabled={isLoading}
                                    {...register("password")}
                                    type={showPassword ? "text" : "password"}
                                    endAdornment={
                                        <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                                            <IconButton onClick={handleClickShowPassword}>
                                                {showPassword ?
                                                    <FontAwesomeIcon icon={faEyeSlash} color="inherit" fixedWidth />
                                                    :
                                                    <FontAwesomeIcon icon={faEye} color="inherit" fixedWidth />
                                                }
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    sx={{
                                        [`&.${outlinedInputClasses.root}`]: { paddingRight: 0 },
                                        [`& .${outlinedInputClasses.input}`]: { padding: "6px 10px", paddingRight: 0 }
                                    }}
                                />
                            </Box>
                            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                            <Button type="submit" variant="main" disabled={isLoading} sx={{ width: "200px" }}>
                                登入
                            </Button>
                        </Stack>
                    </Stack>
            </Container>
        </Window>
    )
}