import { configureStore } from '@reduxjs/toolkit';
import { User } from 'classes/user';
import { useDispatch, useSelector } from 'react-redux';
import { enhancedApi as loginApi } from 'services/loginApi';
import { enhancedApi as userApi } from 'services/userApi';
import { enhancedApi as fileApi } from 'services/uploadApi';
import { enhancedApi as eventApi } from 'services/eventApi';
import { enhancedApi as exhibitApi } from 'services/exhibitApi';
import { enhancedApi as projectApi } from 'services/projectApi';
import { enhancedApi as tidbitApi } from 'services/tidbitApi';
import userReducer from './userSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        [loginApi.reducerPath]: loginApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [fileApi.reducerPath]: fileApi.reducer,
        [eventApi.reducerPath]: eventApi.reducer,
        [exhibitApi.reducerPath]: exhibitApi.reducer,
        [projectApi.reducerPath]: projectApi.reducer,
        [tidbitApi.reducerPath]: tidbitApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(loginApi.middleware as any)
        .concat(loginApi.middleware as any)
        .concat(userApi.middleware as any)
        .concat(fileApi.middleware as any)
        .concat(eventApi.middleware as any)
        .concat(exhibitApi.middleware as any)
        .concat(projectApi.middleware as any)
        .concat(tidbitApi.middleware as any)
});

export type RootState = ReturnType<typeof store.getState>
export type DispatchType = typeof store.dispatch

//常用hook
export const useUser = () => useSelector((state: RootState) => state.user as User);

export const useDispatchWithType = () => useDispatch<DispatchType>();