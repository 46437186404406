import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiTidbit: build.query<GetApiTidbitApiResponse, GetApiTidbitApiArg>({
      query: () => ({ url: `/api/Tidbit` }),
    }),
    postApiTidbit: build.mutation<
      PostApiTidbitApiResponse,
      PostApiTidbitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Tidbit`,
        method: "POST",
        body: queryArg.updateTidbitModel,
      }),
    }),
    patchApiTidbit: build.mutation<
      PatchApiTidbitApiResponse,
      PatchApiTidbitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Tidbit`,
        method: "PATCH",
        body: queryArg.updateTidbitModel,
      }),
    }),
    deleteApiTidbit: build.mutation<
      DeleteApiTidbitApiResponse,
      DeleteApiTidbitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Tidbit`,
        method: "DELETE",
        body: queryArg.updateTidbitModel,
      }),
    }),
    postApiTidbitRestoreByDataId: build.mutation<
      PostApiTidbitRestoreByDataIdApiResponse,
      PostApiTidbitRestoreByDataIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Tidbit/restore/${queryArg.dataId}`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiTidbitApiResponse =
  /** status 200 Success */ OutputTidbitListResultModel;
export type GetApiTidbitApiArg = void;
export type PostApiTidbitApiResponse = /** status 200 Success */ ResultModel;
export type PostApiTidbitApiArg = {
  updateTidbitModel: UpdateTidbitModel;
};
export type PatchApiTidbitApiResponse = /** status 200 Success */ ResultModel;
export type PatchApiTidbitApiArg = {
  updateTidbitModel: UpdateTidbitModel;
};
export type DeleteApiTidbitApiResponse = /** status 200 Success */ ResultModel;
export type DeleteApiTidbitApiArg = {
  updateTidbitModel: UpdateTidbitModel;
};
export type PostApiTidbitRestoreByDataIdApiResponse =
  /** status 200 Success */ ResultModel;
export type PostApiTidbitRestoreByDataIdApiArg = {
  dataId: string;
};
export type OutputTidbit = {
  id?: string | null;
  title?: string | null;
  url?: string | null;
  imageUrl?: string | null;
  createTime?: string;
  updateTime?: string;
};
export type OutputTidbitListResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: OutputTidbit[] | null;
};
export type ResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: any | null;
};
export type UpdateTidbitModel = {
  id?: string | null;
  title?: string | null;
  url?: string | null;
  imageUrl?: string | null;
};
export const {
  useGetApiTidbitQuery,
  useLazyGetApiTidbitQuery,
  usePostApiTidbitMutation,
  usePatchApiTidbitMutation,
  useDeleteApiTidbitMutation,
  usePostApiTidbitRestoreByDataIdMutation,
} = injectedRtkApi;
