import { UserRole } from 'classes/user';
import { isNullOrUndefined } from 'components/Utils';
import ErrorPage from "pages/ErrorPage/404Page";
import { Navigate, Outlet, redirect, useLocation } from 'react-router-dom';
import { useUser } from 'store';

export interface AuthLayoutProps {
    allowAnonymous?: boolean; //預設為false
    adminOnly?: boolean; //預設為false，是否只准許管理員以上權限閱覽
    superuserOnly?: boolean; //預設為false，是否只准許超級使用者以上權限閱覽
}
export default function AuthLayout(props: AuthLayoutProps) {
    const { allowAnonymous, adminOnly, superuserOnly } = props;
    const user = useUser();
    const { pathname, search } = useLocation();
    function isPass() {
        if (allowAnonymous || user?.role > UserRole.anonymous) {
            if (!adminOnly || user?.role >= UserRole.administrator) {
                if (!superuserOnly || user?.role >= UserRole.superuser)
                    return true;
            }
        }
        return false;
    }

    if (isNullOrUndefined(user) || user.role == 0) {
        if (pathname.toLowerCase().startsWith('/login'))
            return <Navigate to={"/login" + (pathname ? "?redirect=" + search : "")} />
        else
            return <Navigate to={"/login" + (pathname ? "?redirect=" + pathname + search : "")} />
    }
    else if (!isPass()) return <ErrorPage />;

    else return <Outlet />;
}