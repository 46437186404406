import { Close } from "@mui/icons-material";
import { Box, Button, ButtonBase, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Menu, Paper, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { Exhibit, ExhibitType } from "classes/exhibit";
import { Element, Link, scroller, Link as ScrollLink, LinkProps as ScrollLinkProps } from 'react-scroll'
import ReactPlayer from "react-player";
import Linkify from 'react-linkify';
import { ExhibitsLabel } from "pages/Home/ExhibitsSection";
import { WindowButtonProps } from "./Home";
import { useEffect } from "react";

const BG = "./images/fin_Background.jpg";

interface ExhibitsDialogProps {
    type: ExhibitType;
    exhibits: Exhibit[];
    open: boolean;
    onClose: () => void;
}
export default function ExhibitsDialog(props: ExhibitsDialogProps) {
    const { type, exhibits, open, onClose } = props;
    let notNull = type !== null && !!exhibits;

    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    //手機版選單
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(menuAnchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const exhibitScrollerProps = {
        containerId: "exhibit-scroller",
        spy: true,
        hashSpy: true,
        smooth: true,
        duration: 500,
        offset: -10,
        activeClass: 'active',
    }

    return (<>
        <Dialog
            open={open && notNull}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            fullScreen={isMobile}
            className="nonecursor"
            PaperProps={{
                sx: {
                    borderRadius: "5px",
                    border: "4px solid #000",
                    background: "var(--primary-gradient-95, linear-gradient(180deg, #FFF8E3 0%, rgba(255, 255, 255, 0.00) 11.56%), rgba(253, 132, 137, 0.95))",
                    backgroundSize: "3200px auto", backgroundPosition: "center", minHeight: "calc(100vh - 64px)"
                }
            }}>
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" fontWeight="bold" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                        ✦ {ExhibitsLabel[type]}
                    </Typography>
                    <WindowButton onClose={onClose} />
                </Box>
                {isTablet &&
                    <MenuButton
                        aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        onClick={handleMenuClick}
                    >
                        {"專題選單 >>"}
                    </MenuButton>
                }
                <StyledMenu
                    anchorEl={menuAnchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 60,
                        horizontal: 'center'
                    }}
                >
                    <Stack spacing={2} >
                        {[...exhibits].map((exhibit) => (
                            <Box key={exhibit.id} sx={{
                                padding: '2px',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.selected,
                                    color: theme.palette.primary.main,
                                    cursor: 'pointer'
                                },
                                [`& a.active`]: {
                                    color: theme.palette.primary.main,

                                    [`& .MuiTypography-root`]: {
                                        //fontWeight: 'bold',
                                    },
                                }
                            }}>
                                <ScrollLink {...exhibitScrollerProps} to={exhibit.id} onClick={handleMenuClose}>
                                    <Typography variant='subtitle1' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        {exhibit.workTitle != '' ? exhibit.workTitle : exhibit.workEnTitle}
                                    </Typography>
                                </ScrollLink>
                            </Box>
                        ))}
                    </Stack>
                </StyledMenu>
            </DialogTitle>
            <DialogContent sx={{ display: "flex" }}>
                {!isTablet &&
                    <ScrollerArea>
                        <Box gap={2} width={"100%"}>
                            {[...exhibits].map((exhibit) => (
                                <Box key={exhibit.id} sx={{
                                    //padding: '2px',
                                    [`& a`]: {
                                        display: "block",
                                        p: "10px 20px"
                                    },

                                    '&:hover': {
                                        backgroundColor: theme.palette.action.selected,
                                        color: theme.palette.primary.main,
                                        cursor: 'pointer'
                                    },
                                    [`& .arrow`]: {
                                        display: "none"
                                    },
                                    [`& a.active`]: {
                                        color: theme.palette.primary.main,
                                        bgcolor: "black",
                                        display: "block",
                                        [`& .MuiTypography-root`]: {
                                            //fontWeight: 'bold',
                                        },
                                        [`& .arrow`]: {
                                            display: "inline"
                                        },
                                    }
                                }}>
                                    <ScrollLink {...exhibitScrollerProps} to={exhibit.id}>
                                        <Typography variant='subtitle1' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                            <img src="images/Union.svg" className="arrow" />   {exhibit.workTitle != '' ? exhibit.workTitle : exhibit.workEnTitle}
                                        </Typography>
                                    </ScrollLink>
                                </Box>
                            ))}
                        </Box>
                    </ScrollerArea>
                }
                <Box id='exhibit-scroller' overflow="auto" gap={2} display={"grid"} padding='0px 12px' width={"100%"}>
                    {[...exhibits].map((exhibit) => (
                        <Element name={exhibit.id} key={exhibit.id}>
                            <ExhibitCard variant="outlined">
                                <CardContent sx={{ padding: { xs: '12px 15px', lg: '24px 32px' } }}>
                                    {exhibit.videoUrl &&
                                        <Box position="relative" pt="56.25%" width='100%' sx={{ marginBottom: '12px' }}>
                                            <Box position="absolute" bgcolor="background.default" style={{ inset: 0 }}>
                                                <ReactPlayer url={exhibit.videoUrl ?? 'https://www.youtube.com/embed/kmdtwgsc-YY'} width="100%" height="100%" />
                                            </Box>
                                        </Box>
                                    }

                                    {(exhibit.imageUrl && !exhibit.videoUrl) &&
                                        <img src={exhibit.imageUrl} style={{ maxHeight: "80vh", maxWidth: "100%", marginBottom: '12px' }} />
                                    }

                                    <Box sx={{
                                        //color: theme.palette.primary.contrastText,
                                        //  backgroundColor: theme.palette.primary.dark,
                                        borderRadius: '6px',
                                        padding: { xs: '6px', lg: '6px 24px' }
                                    }}>
                                        <Typography variant={'h6'}>
                                            {exhibit.workTitle != '' ? exhibit.workTitle : exhibit.workEnTitle}
                                        </Typography>
                                    </Box>

                                    <Box padding={{ xs: '12px 0px', lg: '6px 24px' }}>
                                        {(exhibit.workTitle && exhibit.workEnTitle) &&
                                            <Typography variant="h6" gutterBottom>
                                                {exhibit.workEnTitle}
                                            </Typography>
                                        }
                                        {exhibit.courseTitle &&
                                            <Typography variant="subtitle2" gutterBottom>
                                                課程名稱：{exhibit.courseTitle}
                                            </Typography>
                                        }
                                        {exhibit.serialNum &&
                                            <Typography variant="subtitle2" gutterBottom>
                                                作品編號：{exhibit.serialNum}
                                            </Typography>
                                        }
                                        {exhibit.domain &&
                                            <Typography variant="subtitle2" gutterBottom>
                                                領域：{exhibit.domain}
                                            </Typography>
                                        }
                                        {exhibit.teachers &&
                                            <Typography variant="subtitle2" gutterBottom>
                                                師資：{exhibit.teachers}
                                            </Typography>
                                        }
                                        {exhibit.authors &&
                                            <Linkify>
                                                <Box display='flex'>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        作者：
                                                    </Typography>
                                                    <Typography variant="subtitle2" gutterBottom whiteSpace='break-spaces'>
                                                        {exhibit.authors}
                                                    </Typography>
                                                </Box>
                                            </Linkify>
                                        }
                                        {exhibit.workIntro &&
                                            <Linkify>
                                                <Typography variant="subtitle2" gutterBottom sx={{ whiteSpace: 'break-spaces' }}>
                                                    {exhibit.workIntro}
                                                </Typography>
                                            </Linkify>
                                        }
                                    </Box>
                                    {/*
                                    <Box position="absolute" bottom="4px" right="16px" display="inline-flex">
                                        <Typography color="grey.400">{t("exhibits.other.createTime") + format(new Date(exhibit.createTime), "yyyy/MM/dd")}</Typography>
                                    </Box>
                                    */}
                                </CardContent>
                            </ExhibitCard>
                        </Element>
                    ))}
                    <Box minHeight={"300px"}></Box>
                </Box>
            </DialogContent>
        </Dialog >
    </>)
}

const ExhibitCard = styled(Card)(({ theme, variant }) => ({
    "&.MuiCard-root": variant === "outlined" && {
        height: "100%",
        border: "3px solid " + theme.palette.grey[800],
        borderRadius: "12px",
    },
    overflow: "unset",
    position: "relative"
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
    maxHeight: '80vh',
    '& .MuiPaper-root': {
        padding: "12px",
        border: "3px solid " + theme.palette.grey[800],
        borderRadius: "12px",
        boxShadow: "-3px 3px " + theme.palette.grey[800],
    },
}));

const MenuButton = styled(ButtonBase)(({ theme }) => ({
    width: '100%',
    color: theme.palette.grey[800],
    textDecoration: "none",
    padding: "6px",
    border: "3px solid " + theme.palette.grey[800],
    borderRadius: "12px",
    boxShadow: "-3px 3px " + theme.palette.grey[800],
    background: theme.palette.background.paper,
}));

const ScrollerArea = styled(Paper)(({ theme }) => ({
    // padding: '24px',
    maxWidth: 254,
    width: 200,
    overflow: 'auto',
    marginRight: '24px',
    background: "var(--Radial-BG, radial-gradient(59.41% 66.67% at 53.37% 49.77%, #FFF 6%, #F8F8F8 14%, #E6E6E6 26%, #C7C7C7 40%, #9D9D9D 56%, #676767 73%, #262626 91%, #000 100%), #7FB7E2)",
    backgroundBlendMode: "screen, normal",
    textDecoration: "none",
    //padding: "13px 55px",
    border: "3px solid " + theme.palette.grey[800],
    borderRadius: "12px",
    //boxShadow: "-3px 3px " + theme.palette.grey[800],
}));

export function WindowButton(props: WindowButtonProps) {
    const { onMinimize, onFullscreen, onClose } = props;
    const buttonStyle = {
        //minWidth: "0",
        cursor: "none",
        p: "5px",
        alignItems: "flex-end"
    }
    return (
        <Box display={"flex"} gap={1} position={"absolute"} top={"1rem"} right={"1rem"}>
            <Button sx={{ ...buttonStyle, opacity: onMinimize == null ? ".3" : "1" }} onClick={() => { if (onMinimize) onMinimize() }} disabled={onMinimize == null} >
                <img src="./images/Minimize.svg" />
            </Button>
            <Button sx={{ ...buttonStyle, opacity: onFullscreen == null ? ".3" : "1" }} onClick={() => { if (onFullscreen) onFullscreen() }} disabled={onFullscreen == null}>
                <img src="./images/Fullscreen.svg" />
            </Button>
            <Button sx={{ ...buttonStyle, opacity: onClose == null ? ".3" : "1" }} onClick={() => { if (onClose) onClose() }} disabled={onClose == null}>
                <img src="./images/Close.svg" />
            </Button>
        </Box>
    )
}