import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiLoginLogin: build.mutation<
      PostApiLoginLoginApiResponse,
      PostApiLoginLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Login/login`,
        method: "POST",
        body: queryArg.loginModel,
      }),
    }),
    getApiLoginLogout: build.query<
      GetApiLoginLogoutApiResponse,
      GetApiLoginLogoutApiArg
    >({
      query: () => ({ url: `/api/Login/logout` }),
    }),
    getApiLoginState: build.query<
      GetApiLoginStateApiResponse,
      GetApiLoginStateApiArg
    >({
      query: () => ({ url: `/api/Login/state` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiLoginLoginApiResponse =
  /** status 200 Success */ OutputUserResultModel;
export type PostApiLoginLoginApiArg = {
  loginModel: LoginModel;
};
export type GetApiLoginLogoutApiResponse =
  /** status 200 Success */ ResultModel;
export type GetApiLoginLogoutApiArg = void;
export type GetApiLoginStateApiResponse =
  /** status 200 Success */ OutputUserResultModel;
export type GetApiLoginStateApiArg = void;
export type UserRole = 0 | 1 | 2 | 3;
export type OutputUser = {
  userId?: string | null;
  role?: UserRole;
  account?: string | null;
  createTime?: string;
  updateTime?: string;
};
export type OutputUserResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: OutputUser;
};
export type LoginModel = {
  account?: string | null;
  password?: string | null;
  recaptchaToken?: string | null;
};
export type ResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: any | null;
};
export const {
  usePostApiLoginLoginMutation,
  useGetApiLoginLogoutQuery,
  useLazyGetApiLoginLogoutQuery,
  useGetApiLoginStateQuery,
  useLazyGetApiLoginStateQuery,
} = injectedRtkApi;
