import * as React from "react";
import { Backdrop, Box, CircularProgress, Dialog as HintDialog, Typography } from '@mui/material';
import Dropzone, { DropzoneRef, FileRejection } from 'react-dropzone';

interface UploadProps {
    value?: string;
    uploadPath?: string;
    onChange: (value: File) => void;
    dropProps?: object;
    children: React.ReactNode;
    dropzoneRef?: React.Ref<DropzoneRef>;
    containerStyle?: React.CSSProperties;
    imgStyle?: React.CSSProperties;
    clearImage?: string[];
    width?: string | number;
    height?: string | number;
    textCenter?: boolean;
}

export function Upload(props: UploadProps) {
    const { value, uploadPath, onChange, dropProps, children,
        dropzoneRef, containerStyle, imgStyle, clearImage, width, height, textCenter } = props;
    const [file, setFile] = React.useState<File & { preview: string } | null>(null);
    const [errorMessages, setErrorMessage] = React.useState<string[]>([]);
    const [waiting, setWaiting] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");

    const onDropAccepted = <T extends File>(acceptedFiles: T[]) => {
        let upload = Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0])
        })
        setErrorMessage([]);
        onChange(upload);
        setFile(upload);
    }

    const onDropRejected = (rejectedFile: FileRejection[]) => {
        let errorMessages: string[] = [];
        rejectedFile.forEach(file => file.errors.forEach(error => errorMessages.push(error.message)))
        setErrorMessage(errorMessages);
        setDialogMessage("上傳失敗");
        setOpenDialog(true);
    }

    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
            <p style={{ marginBottom: 0 }}>
                {errorMessages.map(message => (
                    <Typography color="error.main">{message}</Typography>
                ))}
            </p>
        </div>
    );

    React.useEffect(() => {
        if (file) {
            setFile(null);
        }
    }, [clearImage])

    return (<Box width={width }>
        <Dropzone
            ref={dropzoneRef}
            maxFiles={1}
            multiple={false}
            maxSize={2000000} //2MB
            accept={{
                'image/jpeg': ['.jpeg', '.png']
            }}
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
        >
            {({ getRootProps, getInputProps, acceptedFiles }) => (
                <Box className="w-100 h-100" {...getRootProps()}>
                    {/* @ts-ignore */}
                    <input {...getInputProps()} />

                    {file ?
                        <div style={containerStyle}>
                            <img src={file.preview}
                                width={width ?? `477px`}
                                height={height ?? `159px`}
                                style={{ objectFit: "contain", ...imgStyle }}
                            />
                        </div>
                        : value && value != "" ?
                            <div style={containerStyle}>
                                <img src={value}
                                    width={width ?? `477px`}
                                    height={height ?? `159px`}
                                    style={{ objectFit: "contain", ...imgStyle }}
                                />
                            </div>
                            : children
                    }
                </Box>
            )}
        </Dropzone>
        <span style={textCenter ? { textAlign: "center" } : undefined}>大小限制: 2MB</span>
        {errorMessages.map(message => (
            <Typography color="error.main">{message}</Typography>
        ))}
        <Backdrop open={waiting}><CircularProgress /></Backdrop>
        <HintDialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</HintDialog>
    </Box>)
}