import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiProject: build.query<GetApiProjectApiResponse, GetApiProjectApiArg>({
      query: () => ({ url: `/api/Project` }),
    }),
    postApiProject: build.mutation<
      PostApiProjectApiResponse,
      PostApiProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Project`,
        method: "POST",
        body: queryArg.updateProjectModel,
      }),
    }),
    patchApiProject: build.mutation<
      PatchApiProjectApiResponse,
      PatchApiProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Project`,
        method: "PATCH",
        body: queryArg.updateProjectModel,
      }),
    }),
    deleteApiProject: build.mutation<
      DeleteApiProjectApiResponse,
      DeleteApiProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Project`,
        method: "DELETE",
        body: queryArg.updateProjectModel,
      }),
    }),
    postApiProjectRestoreByProjectId: build.mutation<
      PostApiProjectRestoreByProjectIdApiResponse,
      PostApiProjectRestoreByProjectIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Project/restore/${queryArg.projectId}`,
        method: "POST",
      }),
    }),
    postApiProjectAddlist: build.mutation<
      PostApiProjectAddlistApiResponse,
      PostApiProjectAddlistApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Project/addlist`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiProjectApiResponse =
  /** status 200 Success */ OutputProjectListResultModel;
export type GetApiProjectApiArg = void;
export type PostApiProjectApiResponse = /** status 200 Success */ ResultModel;
export type PostApiProjectApiArg = {
  updateProjectModel: UpdateProjectModel;
};
export type PatchApiProjectApiResponse = /** status 200 Success */ ResultModel;
export type PatchApiProjectApiArg = {
  updateProjectModel: UpdateProjectModel;
};
export type DeleteApiProjectApiResponse = /** status 200 Success */ ResultModel;
export type DeleteApiProjectApiArg = {
  updateProjectModel: UpdateProjectModel;
};
export type PostApiProjectRestoreByProjectIdApiResponse =
  /** status 200 Success */ ResultModel;
export type PostApiProjectRestoreByProjectIdApiArg = {
  projectId: string;
};
export type PostApiProjectAddlistApiResponse =
  /** status 200 Success */ ResultModel;
export type PostApiProjectAddlistApiArg = {
  body: UpdateProjectModel[];
};
export type OutputProject = {
  id?: string | null;
  serialNum?: string | null;
  domain?: string | null;
  courseTitle?: string | null;
  teachers?: string | null;
  workTitle?: string | null;
  workEnTitle?: string | null;
  workIntro?: string | null;
  authors?: string | null;
  imageUrl?: string | null;
  videoUrl?: string | null;
  createTime?: string;
  updateTime?: string;
};
export type OutputProjectListResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: OutputProject[] | null;
};
export type ResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: any | null;
};
export type UpdateProjectModel = {
  id?: string | null;
  serialNum?: string | null;
  courseTitle?: string | null;
  teachers?: string | null;
  workTitle?: string | null;
  workEnTitle?: string | null;
  workIntro?: string | null;
  authors?: string | null;
  imageUrl?: string | null;
  videoUrl?: string | null;
};
export const {
  useGetApiProjectQuery,
  useLazyGetApiProjectQuery,
  usePostApiProjectMutation,
  usePatchApiProjectMutation,
  useDeleteApiProjectMutation,
  usePostApiProjectRestoreByProjectIdMutation,
  usePostApiProjectAddlistMutation,
} = injectedRtkApi;
