import * as React from 'react';

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
    return typeof obj === "undefined" || obj === null;
}

//確認是否為行動裝置
export function CheckIsMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent);
}

//倒數計時器
export function CountDownTimer(second: number, callback: () => void) {
    // 產生 Timer
    const countDownSecond = second;
    const startTime = Date.now();
    const countDownTimer = setInterval(() => {
        // 計算剩餘秒數
        const pastSeconds = (Date.now() - startTime) / 1000;
        const remain = (countDownSecond - pastSeconds)
        // 檢查是否結束
        if (remain <= 0) {
            clearInterval(countDownTimer)
            callback();
        }
    }, 1000)
    return () => {
        // 清除 Timer
        clearInterval(countDownTimer)
    }
}

//視窗控制
export function useWindowSize() {
    const [size, setSize] = React.useState([0, 0]);
    React.useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

//轉型工具
export function toRecord<
    T extends { [K in keyof T]: string | number | symbol }, // added constraint
    K extends keyof T
>(array: T[], selector: K): Record<T[K], T> {
    return array.reduce((acc, item) => (acc[item[selector]] = item, acc), {} as Record<T[K], T>)
}

//可以看成做過Debounce處理的setTimeout的hook
export const useDebounceCallback = (delay = 100, cleaning = true) => { // or: delayed debounce callback
    const ref = React.useRef<NodeJS.Timeout>();
    React.useEffect(() => {
        if (cleaning) {
            // cleaning uncalled delayed callback with component destroying
            return () => {
                if (ref.current) clearTimeout(ref.current);
            };
        }
    }, []);
    return (callback: any) => {
        if (ref.current) clearTimeout(ref.current);
        ref.current = setTimeout(callback, delay);
    };
};

//setInterval的hook
//(P.S.當delay為null或0，代表停止執行)
export function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = React.useRef<() => void>(() => { });

    React.useEffect(() => {
        savedCallback.current = callback;
    }, [])

    // Set up the interval.
    React.useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return
        }

        const id = setInterval(() => savedCallback.current(), delay)

        return () => clearInterval(id)
    }, [delay])
}

//是否為日期格式
export function dateIsValid(date: Date) {
    return date instanceof Date && !isNaN(date.getTime());
}

//useDebounce
export function useDebounce(value: any, delay: number) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}

//轉換型別變為不可null
export type NonNullableKeys<T> = {
    [K in keyof T]-?: NonNullable<T[K]>;
  };
  
export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = React.useState(0);

    React.useEffect(() => {
        const updatePosition = () => {
            setScrollPosition(window.pageYOffset);
        }
        window.addEventListener("scroll", updatePosition);
        updatePosition();
        return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    return scrollPosition;
};