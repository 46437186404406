import { Box, Button, CircularProgress } from "@mui/material";
import TextEditor from "components/Tools/Editor/CKEditor"
import { useEffect, useState } from "react"
import { OutputTextEditor, useGetApiTextEditorByNameQuery, usePostApiTextEditorMutation } from "services/textEditorApi"


export function TextEditorSection(props: { name: string }) {
    const { name } = props;
    const [data, setData] = useState<OutputTextEditor | null>(null)
    const { data: TextEditorData, isLoading: isGetLoading } = useGetApiTextEditorByNameQuery({ name }, { refetchOnMountOrArgChange: true })
    const [post, { isLoading }] = usePostApiTextEditorMutation();

    useEffect(() => {
        //     if (TextEditorData?.data) setData(TextEditorData?.data);
        console.log(data)
        setData(null);
        setTimeout(() => {
            if (TextEditorData?.data) setData(TextEditorData?.data);
        }, 50)
    }, [TextEditorData])

    function handleSubmit() {
        if (data) post({ outputTextEditor: data })
    }

    return <>{
        isGetLoading ? <CircularProgress /> : (data && <Box>
            <TextEditor data={data.content ?? ""} onChange={(value: string) => {
                setData({ ...data, content: value })
            }} />
            <Button disabled={isLoading} variant="contained" onClick={() => {
                handleSubmit()
            }}>送出</Button>
        </Box>)
    }
    </>
}