import { styled } from '@mui/material';
import React from 'react';

export default function Loader() {
    return (
        <LoaderContainer>
            <BubbleFadeInOut/>
        </LoaderContainer>
    );
}

const LoaderContainer = styled("div")(({ theme }) => ({
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    inset: 0,
    background: theme.palette.background.default
}));

const BubbleFadeInOut = styled("span")(({ theme }) => ({
    color: theme.palette.primary.main,
    position: "relative",
    "&, &:before, &:after": {
        width: theme.spacing(1),
        height: theme.spacing(1),
        borderRadius: "50%",
        
        animation: "bblFadInOut 1.8s -0.16s infinite",
    },
    "&:before, &:after": {
        content: "''",
        position: "absolute",
        top: 0
    },
    "&:before": {
        left: "-" + theme.spacing(3),
        animationDelay: "-0.32s"
    },
    "&:after": {
        left: theme.spacing(3),
        animationDelay: "0s"
    },
    "@keyframes bblFadInOut": {
        "0%, 80%, 100%": { boxShadow: "0 0.75em 0 -0.35em" },
        "40%": { boxShadow: "0 0.75em 0 0.25em" },
    }
}));