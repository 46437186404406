import { Backdrop, Box, BoxProps, Button, Card, CircularProgress, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Link, Pagination, Paper, Stack, styled, SvgIcon, TextField, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useInView } from "react-intersection-observer";
import subDays from "date-fns/subDays";
import isAfter from "date-fns/isAfter";
import EventsDialog from "../../components/EventsDialog";
import { OLEvent, EventType } from "classes/event";
import { OutputEvent, useLazyGetApiEventQuery } from "services/eventApi";
import { HideableSideTitle } from "components/HideableSideTitle";
import { WindowButton } from "components/ExhibitsDialog";
import { EditorSection } from "components/Home/EditorSection";

const openLabsPicture = "./images/開箱實驗室.jpg";
const itdTalksPicture = "./images/跨域分享會.jpg";
const seminarsPicture = "./images/導覽說明會.jpg";

export default function EventsSection() {
    //側邊Title文字控制
    const { ref, inView } = useInView({
        threshold: 0.5
    });

    //初始化
    const groupMapping: Record<EventType, EventGroup> = {
        0: { type: EventType.openlabs, img: openLabsPicture, events: [] },
        // 1: { type: EventType.talk, img: itdTalksPicture, events: [] },
        1: { type: EventType.seminar, img: seminarsPicture, events: [] }
    };
    const [eventGroups, setEventGroups] = React.useState<Record<EventType, EventGroup>>(groupMapping);
    const [getEvents] = useLazyGetApiEventQuery();
    React.useLayoutEffect(() => { init(); }, []);
    async function init() {
        try {
            Object.keys(groupMapping).forEach(async (type) => {
                let result = await getEvents({ type: Number(type) }).unwrap();
                if (result.isSuccess) {
                    setEventGroups((groups) => {
                        switch (Number(type)) {
                            case EventType.openlabs:
                                groups[EventType.openlabs].events = result.data ?? [];
                                break;
                            /* case EventType.talk:
                                 groups[EventType.talk].events = result.data ?? [];
                                 break;*/
                            case EventType.seminar:
                                groups[EventType.seminar].events = result.data ?? [];
                                break;
                            default:
                                break;
                        }

                        return groups;
                    })
                }
            });
        }
        catch {
            console.error("發生問題");
        }
    }

    //打開面板
    const [open, setOpen] = React.useState<boolean>(false);
    const [dialogProps, setDialogProps] = React.useState<{ type: EventType; events: OutputEvent[]; }>({ type: EventType.openlabs, events: [] });
    function handleClick(type: EventType, events: OutputEvent[]) {
        setDialogProps({ type, events })
        setOpen(true);
    }

    return (<>
        <Box sx={{
            "borderRadius": "5px",
            "background": "var(--85-white, rgba(255, 255, 255, 0.85))",
            "boxShadow": "0px 0px 18px 16px rgba(255, 255, 255, 0.85)",
            textAlign: "center",
        }}
            py={7.5}
            px={2}
            mb={9}
        >
            <Box fontSize={0}>
                <img src="./images/網頁區塊標題-活動介紹.svg" />
            </Box>
            {/* <EditorSection name="EventIntro" /> */}
            <Box id="events" ref={ref} minHeight="50vh" /*py={{ xs: 2, sm: 4 }}*/>
                <Stack /*bgcolor="background.default"*/ height="100%" py={{ xs: 7, sm: 9 }}>
                    <Box px={{ xs: 4, sm: 10, md: "18vw", lg: "13vw" }} mt={2}>
                        <Box display={{ xs: "grid", md: "flex", lg: "grid" }} gridTemplateColumns={{ xs: "1fr", lg: "repeat(2, 1fr)" }} flexWrap="wrap" justifyContent="center" gap={5}>
                            {Object.keys(eventGroups).map((type) => (
                                <EventTypeButton key={type} {...eventGroups[type as unknown as EventType]} onClick={() => handleClick(Number(type), eventGroups[type as unknown as EventType].events)} />
                            ))}
                        </Box>
                    </Box>
                    {/*  <Box display="flex" justifyContent="center" mt={7}>
                    <SignUpButton href="http://140.113.159.211/ict/course/" target="_blank">
                        <Typography variant="h4" color="grey.800">我要報名</Typography>
                    </SignUpButton>
                </Box>
                */}
                </Stack>
            </Box>
        </Box>
        <EventsDialog type={dialogProps.type} events={dialogProps.events as OLEvent[]} open={open} onClose={() => setOpen(false)} />
        {/*
        <HideableSideTitle display={{ xs: "none", md: "block" }} show={inView} text="EVENTS" />
        */}
    </>)
}
interface EventGroup {
    type: EventType;
    img: string;
    events: OutputEvent[];
}
interface EventTypeButtonProps extends EventGroup {
    onClick: () => void;
}

enum EventsDescription {
    "開箱實驗室以資源全校共享為目標，帶大家走入校級實驗室，享受自主學習與實踐之樂趣，揚起全校積極參與跨域實作風氣。陸續已開箱木工作坊、數位製造實驗室、設計工坊、共創基地、陽明創客空間、鐘樓多媒體創作暨練習室等，歡迎跟著創創開箱的腳步，開拓個人更多不同型態的實作技能，挖掘更多校園神秘空間！" = 0,
    "以邀請7大專業領域的學長姐分享技術於業界應用及趨勢現況，使領域理論和實作課程與現實生活結合，未來不局限於想像；快速切題的陳述，可在短時間內接觸大量多元的思維，讓學生有機會與學長進行深度QA。並擬邀請名人分享跨域的緣起、過程、面臨的困境與樂趣，由自身經驗分享如何開始準備自己的side project。" = 1,
    "2023 Open LABs 規劃九場導覽、三個主展區，分別為生命永續、文化永續、科技永續，由七大領域小組之機器人、Drone無人機、物聯網、數位製造、VR/AR、生醫與健康、新媒體展出精采作品，期待您透過作品來認識每個小組的核心技術、最新應用及設計秘辛，從創作發想到動手實作，都有滿滿的故事要向您述說。此外於每學期初辦理學生課程說明會、教師說明會，領域小組召集人將為您說明「跨域實作學習」如何跨得專業跨得有趣！" = 2
}
export enum EventsLabel {
    "開箱實驗室" = 0,
    // "跨域分享會" = 1,
    "專場導覽" = 1
}

const EventTypeButton = styled(({ type, img, events, onClick, ...otherProps }: EventTypeButtonProps) => (
    <Stack alignItems="center" spacing={{ xs: 3, sm: 4 }} {...otherProps}>
        <Button className="eventButton image" onClick={onClick} disableRipple disableTouchRipple>
            <Typography color="grey.700" textTransform="none">{EventsDescription[type]}</Typography>
        </Button>
        <Button className="eventButton label" onClick={onClick} disableRipple disableTouchRipple>
            {hasNew(events) && <NewTag />}
            <Typography variant="h4" color="grey.800">{EventsLabel[type]}</Typography>
        </Button>
    </Stack>
))(({ img, theme }) => ({
    "& > .eventButton.image": {
        width: "100%",
        minWidth: "280px",
        aspectRatio: "1",
        "@supports not (aspect-ratio: 1)": {
            minHeight: "280px"
        },
        position: "relative",
        borderRadius: "12%",
        transition: theme.transitions.create(["transform", "box-shadow", "border-color"]),
        transform: "translate(-10px, -13px)",
        boxShadow: "10px 13px " + theme.palette.grey[800],
        [theme.breakpoints.between("md", "lg")]: {
            maxWidth: "300px",
        },
        [theme.breakpoints.down("sm")]: {
            transform: "translate(-9px, -11px)",
            boxShadow: "9px 11px " + theme.palette.grey[800],
        },
        "&:hover": {
            transform: "translate(-4px, -6px)",
            boxShadow: "4px 6px " + theme.palette.grey[800],
            [theme.breakpoints.down("sm")]: {
                transform: "translate(-3px, -5px)",
                boxShadow: "3px 5px " + theme.palette.grey[800],
            },
        },
        "&:hover::after": {
            opacity: 0,
        },
        "&::before": {
            content: "''",
            position: "absolute",
            inset: 0,
            backgroundSize: "100%",
            background: `center / cover url('${img}')`,
            opacity: 0.15,
            border: "inherit",
            borderRadius: "inherit"
        },
        "&::after": {
            content: "''",
            position: "absolute",
            inset: 0,
            backgroundSize: "100%",
            background: `center / cover url('${img}')`,
            opacity: 1,
            transition: theme.transitions.create(["opacity"]),
            border: "inherit",
            borderRadius: "inherit"
        }
    },
    "& > .eventButton.label": {
        justifyContent: "flex-start",
        width: "80%",
        aspectRatio: "360 / 100",
        "@supports not (aspect-ratio: 16 / 9)": {
            minHeight: "70px"
        },
        position: "relative",
        padding: 0,
        border: "3px solid " + theme.palette.grey[800],
        borderRadius: 0,
        transition: theme.transitions.create(["transform", "box-shadow"]),
        transform: "translate(-8px, -9px)",
        boxShadow: "8px 9px " + theme.palette.grey[800],
        background: "#FFF8E3",
        "& > h4, & > h5, & > h6, & > p": {
            flex: "1 1"
        },
        "&:hover": {
            transform: "translate(-6px, -6px)",
            boxShadow: "6px 6px " + theme.palette.grey[800],
            background: "#FFF1DE"
        },
        "&::before": {
            content: "''",
            width: "13.5%",
            height: "100%",
            minHeight: "70px",
            background: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
        },
        "&:hover::before": {
            background: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
        },
    }
}));
function hasNew(events: OutputEvent[]) {
    //計算場次新增時間是否在一周內
    return events.length > 0 && isAfter(new Date(events[0]?.createTime ?? ''), subDays(new Date(), 7));
}
const NewTag = () => (
    <Box position="absolute" top="-10px" left="-20px" bgcolor="#FFAA98" borderRadius={1.5} px={2} py={0.25}>
        <Typography variant="h6" color="grey.800">NEW</Typography>
    </Box>
);
const SignUpButton = styled(Link)(({ theme }) => ({
    textDecoration: "none",
    padding: "13px 55px",
    border: "3px solid " + theme.palette.grey[800],
    borderRadius: "50px",
    transition: theme.transitions.create(["transform", "box-shadow"]),
    transform: "translate(0px, -6px)",
    boxShadow: "0px 6px " + theme.palette.grey[800],
    background: "#F4BD8F",
    "&:hover": {
        transform: "translate(0px, -6px)",
        boxShadow: "0px 6px " + theme.palette.grey[800],
        background: "#FFDFB5"
    },
}));