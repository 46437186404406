import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Link, Paper, styled, Typography, TypographyProps, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";


const BG = "./images/fin_Background.jpg";
const EventsImg = "./images/成果展系列活動.png";

export function PointRules(props: PointRulesProps) {
    const { open, onClose } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth fullScreen={isMobile}
            PaperProps={{
                sx: {
                    background: `linear-gradient(180deg, rgb(250 239 226 / 50%) 10%, transparent 60%), url('${BG}')`,
                    backgroundSize: "3200px auto", backgroundPosition: "center", minHeight: "calc(100vh - 64px)"
                }
            }}>
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" fontWeight="bold" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                        {`#集點抽獎活動說明`}
                    </Typography>
                    <IconButton onClick={onClose} sx={{ mr: -1 }}><Close /></IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ display: "flex" }}>
                <Paper sx={{ padding: 2, height: 'fit-content' }}>
                    <Typography gutterBottom>
                        <BoldText>活動時間：</BoldText>1月11日(三)~1月13日(五) 10: 00-17: 00
                    </Typography>
                    <Typography gutterBottom>
                        <BoldText>活動地點：</BoldText>(交大校區)浩然圖書館六樓
                    </Typography>
                    <Typography gutterBottom>
                        <BoldText>領獎資格：</BoldText>國立陽明交通大學在校生(111年上學期註冊完成)
                    </Typography>
                    <Typography gutterBottom>
                        <BoldText>主辦單位：</BoldText>陽明交通大學創創工坊
                    </Typography>
                    <Typography gutterBottom>
                        <BoldText>協辦單位：</BoldText>教學發展中心、說故事與多媒體跨域學程、跨領域設計與創新科技跨域學程、藝術與音樂跨域學程
                    </Typography>
                    <Typography gutterBottom>
                        <BoldText>指導單位：</BoldText>教育部高等教育深計辦公室
                    </Typography>
                    <br />


                    <TitleText>【系列活動】</TitleText>
                    <Link rel="noopener" href={EventsImg} target='_blank'>
                        <img src={EventsImg} style={{ maxWidth: '100%', marginBottom: '12px' }} />
                    </Link>
                    <Box>
                        <Typography gutterBottom>
                            ★共計3場抽獎活動，以現場抽獎或FB粉專公告線上抽獎結果
                        </Typography>
                        <TextIndent>1、1/11(三) 開幕儀式抽獎：依粉專公告進行報名，錄取後準時領取抽獎券且完整參與開幕儀式</TextIndent>
                        <TextIndent>2、1/12(四) 跨域分享抽獎：線上/現場報名跨域分享會短講，填寫問卷參加抽獎</TextIndent>
                        <TextIndent>3、1/13(五) 導覽集點/閉幕抽獎：參加每場導覽掃QR Code 累積點數兌獎，另獲線上抽獎券</TextIndent>
                    </Box>
                    <br />
                    <Divider />
                    <br />


                    <TitleText>【🎁問卷好禮🎁】</TitleText>
                    <Typography gutterBottom>
                        1/11~1/13展覽期間，輕鬆完成下列步驟，即可兌換創創工坊紀念小禮，每人限領一份
                    </Typography>
                    <TextIndent>(1) <Link rel="noopener" href='https://www.facebook.com/nycuict/' target='_blank'>創創工坊粉專</Link>按讚並追蹤</TextIndent>
                    <TextIndent>(2) 於活動網頁 「文字雲」區發問或寫下感想，並完成填寫「Open LABs問卷回饋表」</TextIndent>
                    <TextIndent>(3) 至服務台出示問卷填寫完畢畫面</TextIndent>
                    <br />
                    <Divider />
                    <br />


                    <TitleText>【🎁開幕好禮抽獎】</TitleText>
                    <Typography gutterBottom>
                        <BoldText>開幕時間：</BoldText>1/11(三)09:00-10:00
                    </Typography>
                    <Box>
                        <Typography gutterBottom>
                            <BoldText>參加辦法：</BoldText>
                        </Typography>
                        <TextIndent>(1) 需先報名並獲錄取通知(部分開放現場名額)</TextIndent>
                        <TextIndent>(2) 當日08:40前報到並領取抽獎券，完整參加開幕儀式並投入服務台抽獎箱等待抽獎</TextIndent>
                        <TextIndent>(3) <Emphasize>10:30</Emphasize>於服務台抽出得獎名單，若唱名3次，得獎者無回應或不在現場，視同放棄且喪失得獎資格</TextIndent>
                    </Box>
                    <Typography gutterBottom>
                        <BoldText>報名網址：</BoldText><Link rel="noopener" href='http://140.113.159.211/ict/course/' target='_blank'>http://140.113.159.211/ict/course/</Link>
                    </Typography>
                    <Typography gutterBottom>
                        <BoldText>基本好禮：</BoldText>7-11兩百元商品卡(30份)、開幕抽獎券1張<Emphasize>(150張)</Emphasize> 
                    </Typography>
                    <Typography gutterBottom>
                        <BoldText>超值好禮：</BoldText>抽市值破萬豪華Marshall Acton II Bluetooth 藍牙喇叭乙台
                    </Typography>
                    <br />
                    <Divider />
                    <br />


                    <TitleText>【🎁🎁展場導覽集點抽獎】</TitleText>
                    <Typography gutterBottom>
                        凡參加續。旭。不休Open LABs成果展導覽活動，即可掃QR Code，同時參加集點兌獎與參與線上抽獎活動
                    </Typography>

                    <Box>
                        <Typography gutterBottom>
                            <BoldText>導覽時間：</BoldText>★共計9場導覽集點/抽獎活動
                        </Typography>
                        <TextIndent>(1) 1/11(三)10:00-12:00 [導覽]：凡於期間自由參觀會場，不限時間長短，均可獲得導覽集點1場次+導覽線上抽獎券1張</TextIndent>
                        <TextIndent>(2) 1/11(三)13:20-16:20 [深度導覽] 雷射光製造之理論與實作Final demo</TextIndent>
                        <TextIndent>(3) 1/11(三)15:30-15:55 [導覽] 生醫工程與健康科學+專案企畫(脊椎光學系統)</TextIndent>
                        <TextIndent>(4) 1/11(三)15:55-16:20 [導覽] VR/AR</TextIndent>
                        <TextIndent>(5) 1/11(三)16:20-16:45 [導覽] Drone</TextIndent>
                        <TextIndent>(6) 1/12(四)13:20-13:45 [導覽] 物聯網</TextIndent>
                        <TextIndent>(7) 1/12(四)13:45-14:10 [導覽] 數位製造</TextIndent>
                        <TextIndent>(8) 1/13(五)11:20-11:45 [導覽] 機器人</TextIndent>
                        <TextIndent>(9) 1/13(五)11:45-12:10 [導覽] 新媒體設計與敘事+專案企畫(實戰創業平台+鏡境)</TextIndent>
                    </Box>
                    <Box>
                        <Typography gutterBottom>
                            <BoldText>參加辦法：</BoldText>
                        </Typography>
                        <TextIndent>(1) 需先報名，當日至展覽服務台簽到並領取展覽摺頁</TextIndent>
                        <TextIndent>(2) 導覽結束後請依工作人員指示掃描該場次QR Code，即自動完成集點與獲得線上抽獎 (★本項次活動以手機連線網路操作，不接受補登點數或紙本集點兌換)</TextIndent>
                        <TextIndent>(3) <BoldText>[導覽集點]</BoldText> 兌獎登記與領取時間為1/12(四)14:00~1/17(二)15:00，1/13前請至展覽服務台登記辦理；1/16後請至創創工坊(科一121)上班期間辦理；如非交大校區學生請另來信或私訊粉專</TextIndent>
                        <TextIndent>(4) <BoldText>[導覽抽獎]</BoldText> 1/13(五)14:00抽出並於粉專公告，領獎期限至1/17(二)15:00止，請本人於上班期間親洽創創工坊領取；如非交大校區學生請另來信或私訊粉專</TextIndent>
                    </Box>
                    <Box>
                        <Typography gutterBottom>
                            <BoldText>報名網址：</BoldText><Link rel="noopener" href='http://140.113.159.211/ict/course/' target='_blank'>http://140.113.159.211/ict/course/</Link>
                        </Typography>

                        <Typography gutterBottom>
                            <BoldText>集點獎項：</BoldText>
                        </Typography>
                        <TextIndent>集滿7點：7-11商品卡300元，共16份</TextIndent>
                        <TextIndent>集滿5點：星巴克飲料券($150元)，共16份</TextIndent>

                        <Typography gutterBottom>
                            <BoldText>抽獎獎項：</BoldText>
                        </Typography>
                        <TextIndent>頭獎：Google Pixel 7 Pro (12G/128G)</TextIndent>
                        <TextIndent>貳獎：Cleer ARC 開放式真無線藍牙耳機</TextIndent>
                        <TextIndent>參獎：adidas rifta迷你側背包、羅技 pebble m350 鵝卵石無線滑鼠</TextIndent>
                    </Box>
                    <br />
                    <Divider />
                    <br />


                    <TitleText>【🎁🎁🎁跨域分享會抽獎】</TitleText>
                    <Box>
                        <Typography gutterBottom>
                            <BoldText>短講時間：</BoldText>1/12(四) 12:20-13:10
                        </Typography>
                        <Typography gutterBottom>
                            <BoldText>短講地點：</BoldText>浩然圖書館六樓
                        </Typography>
                        <Typography gutterBottom>
                            <BoldText>參加方式：</BoldText>
                        </Typography>
                        <TextIndent>(1) 創創工坊選課系統完成選課</TextIndent>
                        <TextIndent>(2) 短講當日須完成簽到並領取抽獎券</TextIndent>
                        <TextIndent>(3) 短講結束填寫問卷並將抽獎券投入抽獎箱，即可參與跨域分享會抽獎</TextIndent>
                        <TextIndent>(4) 短講結束後直接於現場服務台公告抽獎結果，若唱名3次，得獎者無回應或不在現場，視同放棄且喪失得獎資格</TextIndent>

                        <Typography gutterBottom>
                            <BoldText>報名網址：</BoldText><Link rel="noopener" href='http://140.113.159.211/ict/course/' target='_blank'>http://140.113.159.211/ict/course/</Link>
                        </Typography>
                        <Typography gutterBottom>
                            <BoldText>抽獎獎項：</BoldText>Muji無印良品懶骨頭沙發
                        </Typography>
                    </Box>
                    <br />
                    <Divider />
                    <br />

                    <Typography gutterBottom textAlign='center'>
                        <BoldText>OpenLABs與創創工坊保有活動變更權利，更多詳情請持續關注FB粉專動態</BoldText>
                    </Typography>
                </Paper>
            </DialogContent>
        </Dialog>
    )
}
interface PointRulesProps {
    open: boolean;
    onClose: () => void;
}

const BoldText = styled((props: TypographyProps) =>
    <Typography component='span' fontWeight='bold' {...props} />
)({});
const TitleText = styled((props: TypographyProps) =>
    <Box sx={{ backgroundColor: theme => theme.palette.grey[300], width: 'fit-content', marginBottom: '24px' }}><Typography fontWeight='bold' variant='h6' {...props} /></Box>
)({});
const TextIndent = ({ children }: { children: React.ReactNode }) => <p style={{ paddingLeft: '2rem' }}>{children}</p>
const Emphasize = ({ children }: { children: React.ReactNode }) => <span style={{ color: 'red' }}>{children}</span>