import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiTextEditorByName: build.query<
      GetApiTextEditorByNameApiResponse,
      GetApiTextEditorByNameApiArg
    >({
      query: (queryArg) => ({ url: `/api/TextEditor/${queryArg.name}` }),
    }),
    postApiTextEditor: build.mutation<
      PostApiTextEditorApiResponse,
      PostApiTextEditorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TextEditor`,
        method: "POST",
        body: queryArg.outputTextEditor,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiTextEditorByNameApiResponse =
  /** status 200 Success */ OutputTextEditorResultModel;
export type GetApiTextEditorByNameApiArg = {
  name: string;
};
export type PostApiTextEditorApiResponse =
  /** status 200 Success */ OutputTextEditorResultModel;
export type PostApiTextEditorApiArg = {
  outputTextEditor: OutputTextEditor;
};
export type OutputTextEditor = {
  name?: string | null;
  content?: string | null;
};
export type OutputTextEditorResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: OutputTextEditor;
};
export const {
  useGetApiTextEditorByNameQuery,
  useLazyGetApiTextEditorByNameQuery,
  usePostApiTextEditorMutation,
} = injectedRtkApi;
