
import { Box, Stack, keyframes, styled, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useInView } from "react-intersection-observer";
import { useScrollPosition, useWindowSize } from "../../components/Utils";
import LogoText from "components/Home/LogoText";

const Title = "./images/主視覺.png";
const Title_mobile = "./images/直式圖層1.png";
const logo1 = "./images/單位logo1.png";
const logo2 = "./images/單位logo2.png";
const logo3 = "./images/單位logo3.png";

export default function TitleSection() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { ref, inView } = useInView({
        threshold: 0.5
    });
    const size = useWindowSize();
    const scrollTop = useScrollPosition();

    //打開展區地圖
    const [open, setOpen] = React.useState<boolean>(false);
    function handleOpenDialog(toggle: boolean) {
        setOpen(toggle);
    }


    return (
        <Box
            id="top"
            ref={ref}
            bgcolor="transparent"
            height="auto"
            // pb={"120px"}
            position={"relative"}
            maxWidth={"1920px"}
            m={"0 auto"}
            overflow={"hidden"}
            sx={{
                aspectRatio: { xs: "576/1382", sm: "1280/894" },
                '@supports not (aspect-ratio: 1/1)': {
                    paddingTop: { xs: "calc(1382 / 576 * 100%)", sm: "calc(894 / 1280 * 100%)" },
                },
                backgroundImage: { xs: `url(${Title_mobile})`, sm: `url(${Title})` },
                backgroundPositionX: "50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: { xs: "100%", sm: "125vw", lg: "100%", },
                transition: (theme) => theme.transitions.create(["opacity"]), opacity: inView ? 1 : (scrollTop / size[1] < 0.8) ? 0.8 : 0,
                "& .fadein": {
                    transition: "1.5s cubic-bezier(0.35, 0.64, 0.48, 1.24)",
                    //transitionDelay: ".5s",
                    left: { xs: "14%", md: "26%" }

                }
            }}
        >
            <AnimationContainer
                className={inView ? "fadein" : ""}
                position={"absolute"}
                top={isMobile ? "10.5%" : "18.5%"}
                //  left={"-100%"}
                width={isMobile ? "64%" : "29.5%"}
                fontSize={"0"}
            >
                <LogoText />
            </AnimationContainer>
            <Stack
                position={"absolute"}
                top={{ xs: "6%", sm: "7%", md: "2%" }}
                right={".5%"}
                alignItems={"center"}
            >
                <img src={logo2} style={{ width: "50px" }} />
                <img src={logo3} style={{ width: "40px" }} />
            </Stack>
            <Box position={"absolute"} top={{ xs: "95%", sm: "85%", md: "72%" }} left={"2%"}>
                <img src={logo1} style={{ width: "150px" }} />
            </Box>
            {/* <ResponsiveTitle /> */}
        </Box>
    )
}
const ResponsiveTitle = styled((props: React.ImgHTMLAttributes<HTMLImageElement>) => <img src={Title} {...props} />)(({ theme }) => ({
    //   width: "calc(100vw - 64px)",
    // height: "calc(calc(100vw - 32px) * 0.32)",
    //marginTop: "24vh",
    //marginInline: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
        //   width: "40vw",
        // height: "12.8125vw",
        // marginTop: "30vh",
        //marginInline: "calc(50% - 20vw)",
    }
}));

const twinkle = keyframes
    `   0%,
    100% {
    opacity:.0
    }
    50% {
        opacity:1
    }`;


const AnimationContainer = styled(Box)({
    "& .twinkle": {
        animation: `${twinkle} 2s infinite alternate`,
    }
})