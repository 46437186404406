import { Backdrop, Box, BoxProps, Button, Card, CircularProgress, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Link, Pagination, Paper, Stack, styled, SvgIcon, TextField, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useInView } from "react-intersection-observer";
import ProjectsDialog from "../../components/ProjectsDialog";
import ExhibitsDialog from "../../components/ExhibitsDialog";
import { HideableSideTitle } from "components/HideableSideTitle";
import { Project } from "classes/project";
import { PageTitleBarWithoutSticky } from "components/Layout/PageTitleBar";
import { useLazyGetApiProjectQuery } from "services/projectApi";
import { OutputExhibit, useLazyGetApiExhibitQuery } from "services/exhibitApi";
import { Exhibit, ExhibitType } from "classes/exhibit";
import { EventType } from "classes/event";

const exhibitsPicture = "./images/主題導覽.png";
const exhibitCulturePicture = "";
const exhibitHealthPicture = "";
const exhibitTechnologyPicture = "";
const projectsPicture = "./images/專案企畫總圖.jpg";
const projectBIMPicture = "./images/專案企畫-bim.jpg";
const projectGlassPicture = "./images/專案企畫-鏡境.jpg";
const projectRocketPicture = "./images/專案企畫-火箭.jpg";
const projectCoachBoxPicture = "./images/專案企畫-羽球.png";
const projectScoliosisPicture = "./images/專案企畫-脊椎側彎.jpg";
const projectPlatformPicture = "./images/專案企畫-實戰平台.JPG";

export default function ExhibitsSection() {
    //側邊Title文字控制
    const { ref, inView } = useInView({
        threshold: 0.5
    });

    return (<>
        <Box id="exhibits" ref={ref} pt={9}>
            <ExhibitSection />

            {/* <ProjectSection />*/}
        </Box>
        { /*<HideableSideTitle display={{ xs: "none", md: "block" }} show={inView} text="EXHIBITS" />*/}
    </>)
}
function ExhibitSection() {
    //初始化
    const groupMapping: Record<ExhibitType, ExhibitGroup> = {
        0: { type: ExhibitType.technology, img: exhibitTechnologyPicture, exhibits: [] },
        1: { type: ExhibitType.culture, img: exhibitCulturePicture, exhibits: [] },
        2: { type: ExhibitType.health, img: exhibitHealthPicture, exhibits: [] }
    };
    const [exhibitGroups, setExhibitGroups] = React.useState<Record<ExhibitType, ExhibitGroup>>(groupMapping);
    const [getExhibits] = useLazyGetApiExhibitQuery();
    React.useLayoutEffect(() => { init(); }, []);
    async function init() {
        try {
            Object.keys(groupMapping).forEach(async (type) => {
                let result = await getExhibits({ type: Number(type) }).unwrap();
                if (result.isSuccess) {
                    setExhibitGroups((groups) => {
                        groups[type as unknown as EventType].exhibits = result.data ?? [];
                        return groups;
                    })
                }
            });
        }
        catch {
            console.error("發生問題");
        }
    }

    //打開面板
    const [open, setOpen] = React.useState<boolean>(false);
    const [dialogProps, setDialogProps] = React.useState<{ type: ExhibitType; exhibits: OutputExhibit[]; }>({ type: ExhibitType.culture, exhibits: [] });
    function handleClick(type: ExhibitType, exhibits: OutputExhibit[]) {
        setDialogProps({ type, exhibits })
        setOpen(true);
    }

    return (<>
        <Box /*minHeight="100vh"*/ /*py={{ xs: 2, sm: 4 }}*/ mb={9}>
            <Stack height="100%" spacing={8} /*py={{ xs: 7, sm: 9 }}*/>
                <Box pl={{ xs: 4, sm: 10, md: "18vw", lg: "13vw" }} pr={{ xs: 2, sm: 10, md: "18vw", lg: "13vw" }} mt={2}>
                    <Box display={"grid"} gridTemplateColumns={{ xs: "1fr", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }} flexWrap="wrap" justifyContent="center" gap={5}>
                        {Object.keys(exhibitGroups).map((type) => (
                            <ExhibitTypeButton key={type} {...exhibitGroups[type as unknown as EventType]}
                                onClick={() => handleClick(Number(type), exhibitGroups[type as unknown as EventType].exhibits)} />
                        ))}
                    </Box>
                </Box>
            </Stack >
        </Box >
        <ExhibitsDialog type={dialogProps.type} exhibits={dialogProps.exhibits as Exhibit[]} open={open} onClose={() => setOpen(false)} />
    </>)
}
interface ExhibitGroup {
    type: ExhibitType;
    img: string;
    exhibits: OutputExhibit[];
}
interface ExhibitTypeButtonProps extends ExhibitGroup {
    onClick: () => void;
}

export enum ExhibitsLabel {
    "未來肉身" = 0,
    "智慧生活" = 1,
    "明日視界" = 2
}


const ExhibitTypeButton = styled(({ type, img, exhibits, onClick, ...otherProps }: ExhibitTypeButtonProps) => (
    <Stack alignItems="center" spacing={{ xs: 3, sm: 4 }} {...otherProps}>
        <Button
            className="eventButton image"
            onClick={onClick}
            disableRipple
            disableTouchRipple
            sx={{
                aspectRatio: "1",
                "@supports not (aspect-ratio: 1.5)": {
                    paddingTop: "100%"
                },
                overflow: "hidden",
                // background: `center / cover url('./images/ExhibitTypeButton.png')`,
                background: `center / cover url('./images/${ExhibitsLabel[type]}.png')`,
                color: "white",
                fontSize: "0",
                "& .trans": {
                    fill: "red!impoertant"
                },
                "& .hover": { opacity: 0, position: "absolute", right: "0", bottom: "0" },
                "&:hover": {
                    color: "red",
                    "& .trans": {
                        fill: "red!impoertant"
                    },
                    "& .hover": { opacity: 1, transition: ".5s" },
                    "& .default": { opacity: 0, transition: ".5s" },
                }
            }}>
            {/* <img src="./images/image 9.png" style={{
                position: "absolute",
                top: "25%",
                left: "5%",
                width: "79%"
            }} /> */}
            {/* <Box sx={{ transition: ".5s", }} position={"absolute"} right={"24px"} bottom={"16px"}>
                <img src={`./images/主題展覽標題-${ExhibitsLabel[type]}.svg`} className="default" />
                <img src={`./images/主題展覽標題-${ExhibitsLabel[type]}-hover.svg`} className="hover" />
            </Box> */}
        </Button>
    </Stack>
))(({ img, theme }) => ({
    "& > .eventButton.image": {
        width: "100%",
        minWidth: "240px",
        backgroundSize: "100%",
        position: "relative",
        transition: theme.transitions.create(["transform", "box-shadow", "border-color"]),
        transform: "translate(-7px, -9px)",
        boxShadow: "-5px 5px 0px 0px rgba(0, 0, 0, 0.85)",
        borderRadius: "10px",
        border: "3px solid #000",
        [theme.breakpoints.between("md", "lg")]: {
            maxWidth: "300px",
        },
        [theme.breakpoints.down("sm")]: {
            transform: "translate(-4px, -6px)",
            boxShadow: "-5px 5px 0px 0px " + "rgba(0, 0, 0, 0.85)",
        },
        "&:hover": {
            borderColor: "#7FB7E2",
            transform: "translate(-3px, -5px)",
            boxShadow: "-5px 5px 0px 0px " + "#7FB7E2",
            [theme.breakpoints.down("sm")]: {
                transform: "translate(-2px, -4px)",
                boxShadow: "-5px 5px 0px 0px " + "#7FB7E2",
            },
        },
    }
}));

function ProjectSection() {
    //初始化
    const groupMapping: ProjectGroup[] = [
        { displayTitle: '福狐1號火箭', courseTitle: 'NYCU Formosan Fox Rocketry 火箭團隊', img: projectRocketPicture, projects: [] },
        { displayTitle: '鏡境', courseTitle: '《鏡境》「2022年台中屯區科技跨域表演」', img: projectGlassPicture, projects: [] },
        { displayTitle: '羽球CoachBox', courseTitle: 'CoachBox：羽球擊球動作評測系統', img: projectCoachBoxPicture, projects: [] },
        { displayTitle: '智慧脊椎診斷', courseTitle: '智慧型高精度青少年原發性脊椎側彎數位光學診斷系統', img: projectScoliosisPicture, projects: [] },
        { displayTitle: '模擬創業平台', courseTitle: '跨領域新興科技創業實作專案：全國性實戰平台模擬創業實戰', img: projectPlatformPicture, projects: [] },
        { displayTitle: 'BIM綠建築', courseTitle: '整合節能與創新科技設計之綠建築-BIM 軟體應用競賽', img: projectBIMPicture, projects: [] },
    ];
    const [projectGroups, setProjectGroups] = React.useState<ProjectGroup[]>(groupMapping);
    const [getProjects] = useLazyGetApiProjectQuery();
    React.useLayoutEffect(() => { init(); }, []);
    async function init() {
        try {
            let result = await getProjects().unwrap();
            if (result.isSuccess) {

                setProjectGroups((groups) => {
                    for (let index = 0; index < groups.length; index++)
                        groups[index].projects = (result.data?.filter(x => x.courseTitle === groups[index].courseTitle) ?? []) as Project[];
                    return groups;
                })
            }
        }
        catch {
            console.error("發生問題");
        }
    }

    //打開面板
    const [open, setOpen] = React.useState<boolean>(false);
    const [dialogProps, setDialogProps] = React.useState<{ courseTitle: string, projects: Project[]; }>({ courseTitle: '', projects: [] });
    function handleClick(courseTitle: string, projects: Project[]) {
        setDialogProps({ courseTitle, projects })
        setOpen(true);
    }

    return (<>
        <Box minHeight="100vh" py={{ xs: 2, sm: 4 }}>
            <PageTitleBarWithoutSticky titleTransKey='專案企畫' offsetX={200} />
            <Stack bgcolor="background.default" height="100%" spacing={8} py={{ xs: 7, sm: 9 }}>
                <Box px={{ xs: 4, sm: 10, md: "18vw", lg: "13vw" }} mt={2}>
                    <Box display="grid" gridTemplateColumns={"1fr"} columnGap={5} rowGap={2} sx={{ maxWidth: '800px', margin: 'auto' }}>
                        <Box>
                            <Box position="relative" pt="56.25%" sx={{ background: `center / cover url('${projectsPicture}')` }} />
                        </Box>
                        <Container>
                            <Typography variant="subtitle1" whiteSpace="pre-wrap" mt={1}>
                                創創工坊致力推動本校跨領域學習，以實作為核心，強調創意、創新、創造之學習能量，鼓勵學生積極參與新興科技與最新趨勢議題，因此推出「專案企畫」補助本校教師訓練跨領域學生團隊，參與國際/全國性質展演、競賽或多元大型專案等，以期培育學生具備多元實作硬實力，歷年來均有亮眼成果。<br />
                                今年專案企畫團隊陣容，包含赴美升空的火箭、獲得全國BIM競賽大獎的綠建築 、即時演算和AI生成互動呈現的音樂科技互動實驗劇場、跑遍全台國小做程式教育的AI 羽球、預防青少年原發性脊椎側彎的智慧檢測系統和環扣時事議題的創業實戰平台。<br />
                                <br />
                                NYCU Formosan Fox Rocketry 曾分享 Walt Disney 的名言：<br />
                                All of our dreams can come true if we have the courage to pursue them.<br />
                                <br />
                                初心是理想的終點，創創工坊的專案企畫可以是實現初衷的夢工廠！<br />
                            </Typography>
                        </Container>
                    </Box>
                </Box>

                <Box px={{ xs: 4, sm: 10, md: "18vw", lg: "13vw" }} mt={2}>
                    <Box display={{ xs: "grid", md: "flex", lg: "grid" }} gridTemplateColumns={{ xs: "1fr", lg: "repeat(3, 1fr)" }} flexWrap="wrap" justifyContent="center" gap={5}>
                        {projectGroups.map((course) => (
                            <ProjectTypeButton key={course.courseTitle} {...course} onClick={() => handleClick(course.courseTitle, course.projects)} />
                        ))}
                    </Box>
                </Box>
            </Stack>
        </Box>
        <ProjectsDialog courseTitle={dialogProps.courseTitle} projects={dialogProps.projects} open={open} onClose={() => setOpen(false)} />
    </>)
}
interface ProjectGroup {
    displayTitle: string;
    courseTitle: string;
    img: string;
    projects: Project[];
}
interface ProjectTypeButtonProps extends ProjectGroup {
    onClick: () => void;
}
const ProjectTypeButton = styled(({ img, displayTitle, courseTitle, projects, onClick, ...otherProps }: ProjectTypeButtonProps) => (
    <Stack alignItems="center" spacing={{ xs: 3, sm: 4 }} {...otherProps}>
        <Button className="eventButton image" onClick={onClick} disableRipple disableTouchRipple>
            <Typography color="white" textTransform="none" variant='h4' sx={{ margin: 'auto 24px 12px auto', background: '#b4b4b4cc', boxShadow: '0 0 6px 6px #b4b4b4cc' }}>#{displayTitle}</Typography>
        </Button>
    </Stack>
))(({ img, theme }) => ({
    "& > .eventButton.image": {
        width: "100%",
        minWidth: "240px",
        backgroundSize: "100%",
        background: `center / cover url('${img}')`,
        aspectRatio: "1.5",
        "@supports not (aspect-ratio: 1.5)": {
            minHeight: "160px"
        },
        position: "relative",
        border: "solid 4px" + theme.palette.primary.dark,
        borderRadius: "12px",
        transition: theme.transitions.create(["transform", "box-shadow", "border-color"]),
        transform: "translate(-7px, -9px)",
        boxShadow: "7px 9px " + theme.palette.grey[500],
        [theme.breakpoints.between("md", "lg")]: {
            maxWidth: "300px",
        },
        [theme.breakpoints.down("sm")]: {
            transform: "translate(-4px, -6px)",
            boxShadow: "4px 6px " + theme.palette.grey[500],
        },
        "&:hover": {
            transform: "translate(-3px, -5px)",
            boxShadow: "3px 5px " + theme.palette.grey[500],
            [theme.breakpoints.down("sm")]: {
                transform: "translate(-2px, -4px)",
                boxShadow: "2px 4px " + theme.palette.grey[500],
            },
        },
    }
}));