import { Box, Card, CardContent, Dialog, DialogContent, DialogTitle, Link, Paper, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import format from "date-fns/format";
import * as React from "react";
import { OLEvent, EventType } from "classes/event";
import { EventsLabel } from "pages/Home/EventsSection";
import { WindowButton } from "./ExhibitsDialog";
import { Element, scroller, Link as ScrollLink, LinkProps as ScrollLinkProps } from 'react-scroll'

const BG = "./images/fin_Background.jpg";

interface EventsDialogProps {
    type: EventType;
    events: OLEvent[];
    open: boolean;
    onClose: () => void;
}
export default function EventsDialog(props: EventsDialogProps) {
    const { type, events, open, onClose } = props;
    let notNull = type !== null && !!events;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    //是否從新到舊排序
    const [isDescending, setIsDescending] = React.useState<boolean>(true);
    function handleSelectSort(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.value === "true";
        setIsDescending(value);
    }

    //排序方法
    function compare(a: OLEvent, b: OLEvent) {
        let c = new Date(a.createTime).getTime();
        let d = new Date(b.createTime).getTime();
        if (isDescending) return d - c;
        else return c - d;
    }

    const eventsScrollerProps = {
        containerId: "events-scroller",
        spy: true,
        hashSpy: true,
        smooth: true,
        duration: 500,
        offset: -10,
        activeClass: 'active',
    }

    return (<>
        <Dialog open={open && notNull} onClose={onClose} maxWidth="lg" fullWidth fullScreen={isMobile}
            PaperProps={{
                sx: {
                    // background: `linear-gradient(180deg, rgb(250 239 226 / 50%) 10%, transparent 60%), url('${BG}')`, 
                    backgroundSize: "3200px auto",
                    backgroundPosition: "center",
                    minHeight: "calc(100vh - 64px)",
                    borderRadius: "5px",
                    border: "4px solid #000",
                    background: "var(--primary-gradient-95, linear-gradient(180deg, #FFF8E3 0%, rgba(255, 255, 255, 0.00) 11.56%), rgba(253, 132, 137, 0.95))"
                },
            }}>
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" fontWeight="bold" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                        {EventsLabel[type]}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ display: "flex" }}>
                {!isTablet &&
                    <ScrollerArea>
                        <Box gap={2} width={"100%"}>
                            {[...events].map((events) => (
                                <Box key={events.id} sx={{
                                    //padding: '2px',
                                    [`& a`]: {
                                        display: "block",
                                        p: "10px 20px"
                                    },

                                    '&:hover': {
                                        backgroundColor: theme.palette.action.selected,
                                        color: theme.palette.primary.main,
                                        cursor: 'pointer'
                                    },
                                    [`& .arrow`]: {
                                        display: "none"
                                    },
                                    [`& a.active`]: {
                                        color: theme.palette.primary.main,
                                        bgcolor: "black",
                                        display: "block",
                                        [`& .MuiTypography-root`]: {
                                            //fontWeight: 'bold',
                                        },
                                        [`& .arrow`]: {
                                            display: "inline"
                                        },
                                    }
                                }}>
                                    <ScrollLink {...eventsScrollerProps} to={events.id}>
                                        <Typography variant='subtitle1' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                            <img src="images/Union.svg" className="arrow" />   {events.title}
                                        </Typography>
                                    </ScrollLink>
                                </Box>
                            ))}
                        </Box>
                    </ScrollerArea>
                }
                <Stack flex="1 1" overflow="auto" spacing={2} mb={1.5}>
                    <Box id='events-scroller' overflow="auto" gap={2} display={"grid"} padding='0px 12px' width={"100%"}>
                        {[...events].sort(compare).map((event) => (
                            <Element name={event.id} key={event.id}>
                                <EventCard variant="outlined" key={event.id}>
                                    <CardContent sx={{ display: "grid", gridTemplateColumns: "1fr", gap: 2 }}>
                                        <Box position={"relative"} pt={"calc(4 / 5 * 100%)"} maxHeight="280px" overflow={"hidden"} >
                                            <img src={event.imageUrl} style={{ maxWidth: "100%", objectFit: "cover", position: "absolute", top: "0" }} />
                                        </Box>
                                        <Box>
                                            <Typography variant="h4" gutterBottom>
                                                {event.title}
                                            </Typography>
                                            <div className="ck-content" dangerouslySetInnerHTML={{ __html: event.content }} />
                                        </Box>
                                        <Box position="absolute" bottom="4px" right="16px" display="inline-flex">
                                            <Typography color="grey.400">{"新增日期：" + format(new Date(event.createTime), "yyyy/MM/dd")}</Typography>
                                        </Box>
                                    </CardContent>
                                </EventCard>
                            </Element>
                        ))}
                    </Box>
                </Stack>
                <Box position="relative" textAlign={"center"} mt={"-4.5%"} >
                    {/*  <TextField
                            select
                            hiddenLabel
                            value={String(isDescending)}
                            onChange={handleSelectSort}
                            sx={{
                                background: (theme) => theme.palette.background.paper, maxWidth: "calc(100% - 230px)",
                                [`& .${selectClasses.select}`]: { padding: "4px 32px 4px 10px !important", fontSize: "0.875rem" }, "& .MuiMenuItem-root": { fontSize: "0.875rem" }
                            }}
                        >
                            <MenuItem value="true">新增時間｜新→舊</MenuItem>
                            <MenuItem value="false">新增時間｜舊→新</MenuItem>
                        </TextField>*/}
                    {/* <Box  display = "inline-flex" >
                            <SignUpButton href="http://140.113.159.211/ict/course/" target="_blank">
                                <Typography variant="h4" color="grey.800">我要報名</Typography>
                            </SignUpButton>
                        </Box> */}
                </Box>
                <WindowButton onClose={onClose} />
            </DialogContent>
        </Dialog >
    </>)
}

const EventCard = styled(Card)(({ theme, variant }) => ({
    "&.MuiCard-root": variant === "outlined" && {
        border: "3px solid " + theme.palette.grey[800],
        borderRadius: "12px",
    },
    overflow: "unset",
    position: "relative"
}));

const SignUpButton = styled(Link)(({ theme }) => ({
    textDecoration: "none",
    padding: "13px 55px",
    [theme.breakpoints.down("sm")]: {
        padding: "6px 25px",
    },
    border: "3px solid " + theme.palette.grey[800],
    borderRadius: "50px",
    transition: theme.transitions.create(["transform", "box-shadow"]),
    transform: "translate(0px, -6px)",
    boxShadow: "-5px 5px 0px 0px rgba(0, 0, 0, 0.85)",
    background: "var(--Radial-BG, radial-gradient(59.41% 66.67% at 53.37% 49.77%, #FFF 6%, #F8F8F8 14%, #E6E6E6 26%, #C7C7C7 40%, #9D9D9D 56%, #676767 73%, #262626 91%, #000 100%), #7FB7E2)",
    backgroundBlendMode: "screen, normal",
    /* "&:hover": {
         transform: "translate(0px, -6px)",
         boxShadow: "0px 6px " + theme.palette.grey[800],
         background: "#FFCC8B"
     },*/
}));

const ScrollerArea = styled(Paper)(({ theme }) => ({
    // padding: '24px',
    maxWidth: 254,
    width: 200,
    overflow: 'auto',
    marginRight: '24px',
    background: "var(--Radial-BG, radial-gradient(59.41% 66.67% at 53.37% 49.77%, #FFF 6%, #F8F8F8 14%, #E6E6E6 26%, #C7C7C7 40%, #9D9D9D 56%, #676767 73%, #262626 91%, #000 100%), #7FB7E2)",
    backgroundBlendMode: "screen, normal",
    textDecoration: "none",
    //padding: "13px 55px",
    border: "3px solid " + theme.palette.grey[800],
    borderRadius: "12px",
    //boxShadow: "-3px 3px " + theme.palette.grey[800],
}));
