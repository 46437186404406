import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiEvent: build.query<GetApiEventApiResponse, GetApiEventApiArg>({
      query: (queryArg) => ({
        url: `/api/Event`,
        params: { type: queryArg["type"] },
      }),
    }),
    postApiEvent: build.mutation<PostApiEventApiResponse, PostApiEventApiArg>({
      query: (queryArg) => ({
        url: `/api/Event`,
        method: "POST",
        body: queryArg.updateEventModel,
        params: { type: queryArg["type"] },
      }),
    }),
    patchApiEvent: build.mutation<
      PatchApiEventApiResponse,
      PatchApiEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Event`,
        method: "PATCH",
        body: queryArg.updateEventModel,
        params: { type: queryArg["type"] },
      }),
    }),
    deleteApiEvent: build.mutation<
      DeleteApiEventApiResponse,
      DeleteApiEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Event`,
        method: "DELETE",
        body: queryArg.updateEventModel,
        params: { type: queryArg["type"] },
      }),
    }),
    postApiEventRestoreByEventId: build.mutation<
      PostApiEventRestoreByEventIdApiResponse,
      PostApiEventRestoreByEventIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Event/restore/${queryArg.eventId}`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiEventApiResponse =
  /** status 200 Success */ OutputEventListResultModel;
export type GetApiEventApiArg = {
  type?: number;
};
export type PostApiEventApiResponse = /** status 200 Success */ ResultModel;
export type PostApiEventApiArg = {
  type?: number;
  updateEventModel: UpdateEventModel;
};
export type PatchApiEventApiResponse = /** status 200 Success */ ResultModel;
export type PatchApiEventApiArg = {
  type?: number;
  updateEventModel: UpdateEventModel;
};
export type DeleteApiEventApiResponse = /** status 200 Success */ ResultModel;
export type DeleteApiEventApiArg = {
  type?: number;
  updateEventModel: UpdateEventModel;
};
export type PostApiEventRestoreByEventIdApiResponse =
  /** status 200 Success */ ResultModel;
export type PostApiEventRestoreByEventIdApiArg = {
  eventId: string;
};
export type OutputEvent = {
  id?: string | null;
  title?: string | null;
  content?: string | null;
  imageUrl?: string | null;
  createTime?: string;
  updateTime?: string;
};
export type OutputEventListResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: OutputEvent[] | null;
};
export type ResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: any | null;
};
export type UpdateEventModel = {
  id?: string | null;
  title?: string | null;
  content?: string | null;
  imageUrl?: string | null;
};
export const {
  useGetApiEventQuery,
  useLazyGetApiEventQuery,
  usePostApiEventMutation,
  usePatchApiEventMutation,
  useDeleteApiEventMutation,
  usePostApiEventRestoreByEventIdMutation,
} = injectedRtkApi;
