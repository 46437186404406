import { styled, TextField, TextFieldProps } from "@mui/material";

export const NoLegendTextField = styled(TextField)<TextFieldProps>(() => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            top: 0,
        },
        '& fieldset legend': {
            display: 'none',
        },
    },
}));