import { Box, Button, Link as MuiLink, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, SvgIcon, Typography } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useUser } from "store";

export default function AdminLayout() {
    const user = useUser();
    let username = user.name || user.userId || "--";

    const data = [
        {
            label: "活動", items: [
                { label: "開箱實驗室", to: "/manage/events/openLabs" },
                //{ label: "跨域分享會", to: "/manage/events/interdisciplinaryTalk" },
                { label: "專場導覽", to: "/manage/events/seminars" },
            ]
        },
        {
            label: "展覽作品", items: [
                { label: "未來肉身", to: "/manage/exhibits/technology" },
                { label: "智慧生活", to: "/manage/exhibits/culture" },
                { label: "明日視界", to: "/manage/exhibits/health" },
                { label: "匯入資料", to: "/manage/exhibits/import" },
            ]
        },
        {
            label: "首頁介紹", items: [
                { label: "展覽介紹", to: "/manage/exhibits/introEditor" },
                { label: "小組介紹", to: "/manage/team/introEditor" },
                { label: "活動介紹", to: "/manage/events/introEditor" },
            ]
        },
        /*  {
              label: "專案企畫", items: [
                  { label: "資料管理", to: "/manage/projects" },
                  { label: "匯入資料", to: "/manage/projects/import" },
              ]
          },*/
        {
            label: "花絮", items: [
                { label: "花絮管理", to: "/manage/tidbits" },
            ]
        },
        {
            label: "登入紀錄", items: [
                { label: "登入紀錄", to: "/manage/signInLog" },
            ]
        },
    ];

    const { pathname } = useLocation();
    return (<>
        <SnackbarProvider>
            <Box display="flex" justifyContent="space-between"
                alignItems="center" color="text.secondary"
                px={6} pt="2.5px" sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.text.lightgrey}`
                }}>
                <Typography variant="body2">2024 OpenLabs 後台管理</Typography>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="body2">登入身分：{username}</Typography>
                    <Button component={Link} to="/logout" sx={{ minWidth: 0, px: 0 }}>登出</Button>
                </Stack>
            </Box>
            <Box display="flex" minHeight="calc(100vh - 80px)" overflow="auto">
                <Paper elevation={0} sx={{ minWidth: 180, maxWidth: 256 }}>
                    <List dense sx={{ my: 3, py: 0, backgroundColor: "#f5f5f5" }}>
                        {data.map((element, index) => (<div key={index.toString()}>
                            <ListItem sx={{ backgroundColor: (theme) => theme.palette.background.secondary }}>
                                <ListItemText
                                    primary={element.label}
                                    primaryTypographyProps={{
                                        fontWeight: 'medium',
                                        variant: 'body2',
                                    }}
                                    sx={{ my: 0.25 }}
                                />
                            </ListItem>
                            {element.items.map((item, index) => (
                                <ListItem key={index.toString()} button component={Link}
                                    to={item.to} className={pathname === item.to ? "active" : ""}
                                    sx={{
                                        color: (theme) => theme.palette.text.secondary,
                                        "&:hover": {
                                            color: "#23527C",
                                            backgroundColor: "#EAF1f7"
                                        },
                                        "&.active": {
                                            color: "#D96200",
                                            backgroundColor: "#FFF7E9"
                                        }
                                    }}
                                >
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <SvgIcon>
                                            <circle cx="12" cy="12" r="2" />
                                        </SvgIcon>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.label}
                                        primaryTypographyProps={{
                                            color: "inherit",
                                            fontWeight: 'normal',
                                            variant: 'body2',
                                        }}
                                    />
                                </ListItem>
                            ))}
                            <div style={{ height: "4px" }} />
                        </div>))}
                    </List>
                </Paper>
                <Box flex="1 0 1200px" pt={3} pr={9} pb={5} pl={5}>
                    <Outlet />
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between"
                alignItems="center" color="text.secondary" px={6} py={1}
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.text.lightgrey}`
                }}
            >
                <MuiLink variant="body2" color="inherit" href="https://ict.nycu.edu.tw/" target="_blank">2024 OpenLabs</MuiLink>
                <Typography variant="body2">
                    Powered by <MuiLink variant="body2" color="inherit" href="https://tempestdigi.com/" target="_blank">Tempest Digital</MuiLink>
                </Typography>
            </Box>
        </SnackbarProvider>
    </>)
}