import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiUser: build.query<GetApiUserApiResponse, GetApiUserApiArg>({
      query: (queryArg) => ({
        url: `/api/User`,
        params: {
          FilterType: queryArg.filterType,
          FilterText: queryArg.filterText,
          Page: queryArg.page,
          PageSize: queryArg.pageSize,
        },
      }),
    }),
    postApiUser: build.mutation<PostApiUserApiResponse, PostApiUserApiArg>({
      query: (queryArg) => ({
        url: `/api/User`,
        method: "POST",
        body: queryArg.newUserModel,
      }),
    }),
    getApiUserByUserId: build.query<
      GetApiUserByUserIdApiResponse,
      GetApiUserByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/User/${queryArg.userId}` }),
    }),
    deleteApiUserByTargetUserId: build.mutation<
      DeleteApiUserByTargetUserIdApiResponse,
      DeleteApiUserByTargetUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/${queryArg.targetUserId}`,
        method: "DELETE",
      }),
    }),
    deleteApiUserBulk: build.mutation<
      DeleteApiUserBulkApiResponse,
      DeleteApiUserBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/bulk`,
        method: "DELETE",
        body: queryArg.body,
      }),
    }),
    getApiUserHistory: build.query<
      GetApiUserHistoryApiResponse,
      GetApiUserHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/history`,
        params: { StartTime: queryArg.startTime, EndTime: queryArg.endTime },
      }),
    }),
    getApiUserInitAdmin: build.query<
      GetApiUserInitAdminApiResponse,
      GetApiUserInitAdminApiArg
    >({
      query: () => ({ url: `/api/User/initAdmin` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiUserApiResponse =
  /** status 200 Success */ OutputUserListResultModel;
export type GetApiUserApiArg = {
  filterType?: number;
  filterText?: string;
  page?: number;
  pageSize?: number;
};
export type PostApiUserApiResponse =
  /** status 200 Success */ OutputUserResultModel;
export type PostApiUserApiArg = {
  newUserModel: NewUserModel;
};
export type GetApiUserByUserIdApiResponse =
  /** status 200 Success */ OutputUserResultModel;
export type GetApiUserByUserIdApiArg = {
  userId: string;
};
export type DeleteApiUserByTargetUserIdApiResponse =
  /** status 200 Success */ ResultModel;
export type DeleteApiUserByTargetUserIdApiArg = {
  targetUserId: string;
};
export type DeleteApiUserBulkApiResponse =
  /** status 200 Success */ ResultModel;
export type DeleteApiUserBulkApiArg = {
  body: string[];
};
export type GetApiUserHistoryApiResponse =
  /** status 200 Success */ SignInListResultModel;
export type GetApiUserHistoryApiArg = {
  startTime?: string;
  endTime?: string;
};
export type GetApiUserInitAdminApiResponse =
  /** status 200 Success */ OutputUserResultModel;
export type GetApiUserInitAdminApiArg = void;
export type UserRole = 0 | 1 | 2 | 3;
export type OutputUser = {
  userId?: string | null;
  role?: UserRole;
  account?: string | null;
  createTime?: string;
  updateTime?: string;
};
export type OutputUserListResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: OutputUser[] | null;
};
export type OutputUserResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: OutputUser;
};
export type NewUserModel = {
  role?: UserRole;
  account?: string | null;
  password?: string | null;
};
export type ResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: any | null;
};
export type SignIn = {
  id?: string;
  addedAtUtc?: string;
  version?: number;
  ip?: string | null;
  account?: string | null;
  userId?: string | null;
};
export type SignInListResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: SignIn[] | null;
};
export const {
  useGetApiUserQuery,
  useLazyGetApiUserQuery,
  usePostApiUserMutation,
  useGetApiUserByUserIdQuery,
  useLazyGetApiUserByUserIdQuery,
  useDeleteApiUserByTargetUserIdMutation,
  useDeleteApiUserBulkMutation,
  useGetApiUserHistoryQuery,
  useLazyGetApiUserHistoryQuery,
  useGetApiUserInitAdminQuery,
  useLazyGetApiUserInitAdminQuery,
} = injectedRtkApi;
