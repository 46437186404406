import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiExhibit: build.query<GetApiExhibitApiResponse, GetApiExhibitApiArg>({
      query: (queryArg) => ({
        url: `/api/Exhibit`,
        params: { type: queryArg["type"] },
      }),
    }),
    postApiExhibit: build.mutation<
      PostApiExhibitApiResponse,
      PostApiExhibitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exhibit`,
        method: "POST",
        body: queryArg.updateExhibitModel,
        params: { type: queryArg["type"] },
      }),
    }),
    patchApiExhibit: build.mutation<
      PatchApiExhibitApiResponse,
      PatchApiExhibitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exhibit`,
        method: "PATCH",
        body: queryArg.updateExhibitModel,
        params: { type: queryArg["type"] },
      }),
    }),
    deleteApiExhibit: build.mutation<
      DeleteApiExhibitApiResponse,
      DeleteApiExhibitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exhibit`,
        method: "DELETE",
        body: queryArg.updateExhibitModel,
        params: { type: queryArg["type"] },
      }),
    }),
    postApiExhibitRestoreByExhibitId: build.mutation<
      PostApiExhibitRestoreByExhibitIdApiResponse,
      PostApiExhibitRestoreByExhibitIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exhibit/restore/${queryArg.exhibitId}`,
        method: "POST",
      }),
    }),
    postApiExhibitAddlist: build.mutation<
      PostApiExhibitAddlistApiResponse,
      PostApiExhibitAddlistApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exhibit/addlist`,
        method: "POST",
        body: queryArg.body,
        params: { type: queryArg["type"] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiExhibitApiResponse =
  /** status 200 Success */ OutputExhibitListResultModel;
export type GetApiExhibitApiArg = {
  type?: number;
};
export type PostApiExhibitApiResponse = /** status 200 Success */ ResultModel;
export type PostApiExhibitApiArg = {
  type?: number;
  updateExhibitModel: UpdateExhibitModel;
};
export type PatchApiExhibitApiResponse = /** status 200 Success */ ResultModel;
export type PatchApiExhibitApiArg = {
  type?: number;
  updateExhibitModel: UpdateExhibitModel;
};
export type DeleteApiExhibitApiResponse = /** status 200 Success */ ResultModel;
export type DeleteApiExhibitApiArg = {
  type?: number;
  updateExhibitModel: UpdateExhibitModel;
};
export type PostApiExhibitRestoreByExhibitIdApiResponse =
  /** status 200 Success */ ResultModel;
export type PostApiExhibitRestoreByExhibitIdApiArg = {
  exhibitId: string;
};
export type PostApiExhibitAddlistApiResponse =
  /** status 200 Success */ ResultModel;
export type PostApiExhibitAddlistApiArg = {
  type?: number;
  body: UpdateExhibitModel[];
};
export type OutputExhibit = {
  id?: string | null;
  serialNum?: string | null;
  domain?: string | null;
  courseTitle?: string | null;
  teachers?: string | null;
  workTitle?: string | null;
  workEnTitle?: string | null;
  workIntro?: string | null;
  authors?: string | null;
  imageUrl?: string | null;
  videoUrl?: string | null;
  createTime?: string;
  updateTime?: string;
};
export type OutputExhibitListResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: OutputExhibit[] | null;
};
export type ResultModel = {
  isSuccess?: boolean;
  message?: string | null;
  data?: any | null;
};
export type UpdateExhibitModel = {
  id?: string | null;
  serialNum?: string | null;
  domain?: string | null;
  courseTitle?: string | null;
  teachers?: string | null;
  workTitle?: string | null;
  workEnTitle?: string | null;
  workIntro?: string | null;
  authors?: string | null;
  imageUrl?: string | null;
  videoUrl?: string | null;
};
export const {
  useGetApiExhibitQuery,
  useLazyGetApiExhibitQuery,
  usePostApiExhibitMutation,
  usePatchApiExhibitMutation,
  useDeleteApiExhibitMutation,
  usePostApiExhibitRestoreByExhibitIdMutation,
  usePostApiExhibitAddlistMutation,
} = injectedRtkApi;
