import { grey } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
    interface TypeBackground {
        secondary: string;
        black: string;
    }
    interface TypeText {
        white: string;
        dark: string;
        main: string;
        secondary: string;
        lightgrey: string;
        bluegrey: string;
        warning: string;
    }
}
declare module '@mui/material/styles' {
    interface Palette {
        appBar: Palette['primary'];
    }
    interface PaletteOptions {
        appBar: PaletteOptions['primary'];
    }
}
declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        main: true;
        light: true;
        danger: true;
        dark: true;
        reverse: true;
    }
}
let mainTheme = createTheme({
    palette: {
        primary: {
            light: '#64CFC4',
            main: '#FD8489',
            dark: '#4F959A',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#F4BD8F',
            main: '#9ac06e',
            dark: '#F0995A',
            contrastText: '#ffffff',
        },
        background: {
            paper: '#ffffff',
            default: '#ffffff',
            secondary: grey[200],
            black: '#101010'
        },
        appBar: {
            light: '#5b636a',
            main: "#343a40",
            contrastText: "#f5ffff"
        },
        text: {
            dark: "#c16b32",
            main: "#303840",
            secondary: "#757575",
            primary: '#232323',
            lightgrey: '#A1A1A1',
            bluegrey: '#A6B4BD',
            white: '#ffffff',
            warning: '#FE7301',
        }
    },
    typography: {
        fontFamily: ["Roboto", "Helvetica", "Noto Sans TC", "Arial", "sans-serif"].join(','),
    },
    components: {
        MuiTablePagination: {
            styleOverrides: {
                displayedRows: {
                    margin: 'auto'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    wordBreak: 'break-word',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                asterisk: {
                    color: 'red'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    minHeight: "48px",
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                }
            }
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1280,
            xl: 1920,
        },
    },
});

mainTheme = responsiveFontSizes(mainTheme);

export default mainTheme;
