import { Dialog, DialogActions, DialogContent, DialogTitle, styled } from "@mui/material";
import { DialogActionsProps, DialogContentProps, DialogProps, DialogTitleProps } from "@mui/material";
import "./StyleSheet.css";

export const SimpleDialog = styled(Dialog)({
    "&.MuiDialog-paper": {
        borderRadius: '12px'
    }
});

export const SimpleDialogTitle  = styled(DialogTitle)({

});

export const SimpleDialogContent  = styled(DialogContent)({
    fontSize: "1rem",
    color: "#707070",
    lineHeight: "1.875"
});

export const SimpleDialogActions  = styled(DialogActions)({
    justifyContent: 'center',
    padding: '8px 24px 20px 24px'
});