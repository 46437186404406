import { KeyboardReturnOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { EventType } from "classes/event";
import { Exhibit } from "classes/exhibit";
import ExhibitsDialog from "components/ExhibitsDialog";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { OutputEvent } from "services/eventApi";
import { useGetApiExhibitQuery } from "services/exhibitApi";



const openLabsPicture = "./images/開箱實驗室.jpg";
const seminarsPicture = "./images/導覽說明會.jpg";

interface EventGroup {
    type: EventType;
    img: string;
    events: OutputEvent[];
}

export default function ExhibitsPage() {
    const { search } = useLocation();
    const navigate = useNavigate();
    let query = new URLSearchParams(search);
    let type = Number(query.get('type'));


    const { data, isLoading } = useGetApiExhibitQuery({ type: Number(type) });
    return <>
        {type != undefined ?
            <>
                {!isLoading && <ExhibitsDialog type={Number(type)} exhibits={data?.data as Exhibit[]} open={true} onClose={() => { navigate("/#exhibits") }} />}
            </> :
            <Box>QR code有誤，請重新掃描。</Box>
        }
    </>
}
