import { NonNullableKeys } from "components/Utils";
import { SignIn } from "services/userApi";

export enum UserRole {
    anonymous = 0,
    normal,
    administrator,
    superuser,
}

export const userRoleList: string[] = [
    '', '一般使用者', '管理員', '超級管理員'
];

export interface User {
    userId: string;
    name: string;
    role: UserRole;
    studentId: string;
    email: string;
}

export type LoginHistory = NonNullableKeys<SignIn>;